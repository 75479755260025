<div>
  <div>
    <div class="container form-container">
      <h2 class="mat-h2 title">Bienvenue, veuillez vous connecter</h2>
      <form [formGroup]="loginForm" (ngSubmit)="loginForm.valid && onSubmit()">
        <div class="col-12">
          <div class="form-group">
            <mat-form-field class="form-field">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Mon adresse email..."
                     type="text"
                     id="email"
                     formControlName="email"
                     [ngClass]="{
                       'is-invalid': loginForm.controls.email.errors
                     }"
              />
              <mat-hint>Entrez ici votre adresse email</mat-hint>
              <mat-error *ngIf="loginForm.controls.email.errors && loginForm.controls.email.errors.required">
                L'adresse email est requise
              </mat-error>
              <mat-error *ngIf="loginForm.controls.email.errors && loginForm.controls.email.errors.pattern">
                L'adresse email indiqué n'est pas valide
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <mat-form-field class="form-field">
              <mat-label>Mot de passe</mat-label>
              <input matInput placeholder="Mon mot de passe"
                     [type]="hide ? 'password' : 'text'"
                     id="password"
                     formControlName="password"
                     [ngClass]="{
                       'is-invalid': loginForm.controls.password.errors
                     }"
              />
              <mat-hint>Entrez ici votre mot de passe</mat-hint>
              <button mat-icon-button matSuffix type="button" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="loginForm.controls.password.errors && loginForm.controls.password.errors.required">
                Le mot de passe est requis
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="col-12">
          <div class="forget-password-container">
            <a mat-button href="/forgetPassword">Mot de passe oublié ?</a>
          </div>
        </div>
        <div class="col-12">
          <div class="button-container">
            <button mat-raised-button type="submit" [disabled]="!loginForm.valid" color="primary">CONNEXION</button>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>
