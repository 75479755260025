<form [formGroup]="createContactFormGroup" (ngSubmit)="createContactFormGroup.valid && onSubmit()">
  <div class="d-flex justify-content-between">
    <h1 class="mat-h1" mat-dialog-title>Créer un contact</h1>
    <button mat-icon-button type="button" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
  </div>
  <mat-dialog-content>
    <div *ngIf="isSubmitting" style="height: 125px">
      <mat-spinner class="ma-auto"></mat-spinner>
    </div>
    <div *ngIf="!isSubmitting">
      <div class="row">
        <div class="col-6">
          <mat-form-field class="full-width">
            <mat-label>Nom</mat-label>
            <input matInput placeholder="Nom du contact"
                   type="text"
                   id="name"
                   formControlName="name"
                   required
            />
            <mat-hint>Le nom du contact</mat-hint>
            <mat-error *ngIf="createContactFormGroup.controls.name.errors && createContactFormGroup.controls.name.errors.required">
              Le nom est requis
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="full-width">
            <mat-label>Fonction</mat-label>
            <input matInput placeholder="Fonction du contact"
                   type="text"
                   id="office"
                   formControlName="office"
            />
            <mat-hint>La fonction du contact</mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="horizontal-spacer"></div>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="full-width">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email du contact"
                   type="text"
                   id="email"
                   formControlName="email"
                   required
            />
            <mat-hint>L'adresse email du contact</mat-hint>
            <mat-error *ngIf="createContactFormGroup.controls.email.errors && createContactFormGroup.controls.email.errors.required">
              L'adresse email est requise
            </mat-error>
            <mat-error *ngIf="createContactFormGroup.controls.email.errors && createContactFormGroup.controls.email.errors.pattern">
              L'adresse email indiqué n'est pas valide
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="full-width">
            <mat-label>Téléphone</mat-label>
            <input matInput placeholder="+33 123456789"
                   type="text"
                   id="phone"
                   formControlName="phone"
            />
            <mat-hint>Le numéro de téléphone du contact</mat-hint>
            <mat-error *ngIf="createContactFormGroup.controls.phone.errors && createContactFormGroup.controls.phone.errors.phone">
              Le numéro de téléphone n'est pas valide (avez vous bien indiqué le numéro international ?)
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="horizontal-spacer"></div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button type="submit" [disabled]="!createContactFormGroup.valid || isSubmitting" color="primary">Créer</button>
  </mat-dialog-actions>
</form>
