import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {ApiService} from '../../../../core/services/api/api.service';
import {AuthenticationService} from '../../../../core/services/authentication/authentication.service';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {LoginPayload} from '../../../../shared/models/api/auth/login-payload';
import {AlertService} from '../../../../core/services/alert/alert.service';

@Component({
  selector: 'app-first-login',
  templateUrl: './first-login.component.html',
  styleUrls: ['./first-login.component.scss']
})
export class FirstLoginComponent implements OnInit {
  private user: string = null;
  private token: string = null;
  passwordForm: FormGroup;
  hide = true;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private authService: AuthenticationService,
    private alertService: AlertService,
  ) {
    this.passwordForm = fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      passwordValidation: ['', [Validators.required, this.passwordMatch('password')]],
    });
    this.passwordForm.controls.password.valueChanges.subscribe(() => {
      setTimeout(() => {
        this.passwordForm.controls.passwordValidation.updateValueAndValidity();
      });
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.user = params.user;
      this.token = params.token;

      this.authService.signOut();
      const payload: LoginPayload = {
        email: this.user,
        password: this.token
      };
      this.apiService.authLogin(payload).subscribe(
        (response) => {
          this.authService.signIn(response.access_token, response.refresh_token);
        }, (error) => {
          this.router.navigate(['/login']);
          this.alertService.error('Utilisateur non trouvé, si vous avez copié collé le lien vérifiez qu\'il soit bien entier');
        }
      );
    });
  }

  onSubmit(): void {
    this.submitted = true;

    this.apiService.getUserMe().subscribe((user) => {
      this.apiService.patchUser(user.id, {password: this.passwordForm.get('password').value}).subscribe((updatedUser) => {
        this.router.navigate(['/']);
        this.alertService.success('Le mot de passe à bien été mis à jour!');
      }, (updateError) => {
        this.alertService.error(`${updateError.name}: ${updateError.message}`);
      });
    });
  }

  passwordMatch(fieldName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      const passwordMatch = control.root.value[fieldName] !== input;
      return passwordMatch ? {passwordMatch: {passwordMatch}} : null;
    };
  }
}
