import { Injectable } from '@angular/core';
import { SessionService } from '../session/session.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {

    constructor(
        private session: SessionService
    ) {}

    public isAuth(): boolean {
        return !!this.session.getAccessToken();
    }

    public signIn(accessToken: string, refreshToken: string): void {
        this.setAccessToken(accessToken, refreshToken);
    }

    public setAccessToken(accessToken: string, refreshToken: string): void {
        this.session.setAccessToken(accessToken);
        this.session.setRefreshToken(refreshToken);
    }

    public getAccessToken(): string {
        return this.session.getAccessToken();
    }

    public getRefreshToken(): string {
        return this.session.getRefreshToken();
    }

    public signOut(): void {
        this.session.destroy();
    }

}
