import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UpdateKeyStatesDialogData} from '../../../../shared/models/properties/update-key-states-dialog-data';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {GenericHistoryEntry} from '../../../../shared/models/api/history/generic-history-entry';
import {ApiService} from '../../../../core/services/api/api.service';
import {GenericContact} from '../../../../shared/models/api/contacts/generic-contact';
import {AlertService} from '../../../../core/services/alert/alert.service';
import {CreateContactDialogComponent} from '../../../../shared/components/create-contact-dialog/create-contact-dialog.component';
import {CreateContactDialogData} from '../../../../shared/models/contacts/create-contact-dialog-data';
import {PostHistoryPayload} from '../../../../shared/models/api/history/post-history-payload';

@Component({
  selector: 'app-update-key-states-dialog',
  templateUrl: './update-key-states-dialog.component.html',
  styleUrls: ['./update-key-states-dialog.component.scss']
})
export class UpdateKeyStatesDialogComponent implements OnInit {
  minDate = new Date();
  updateElementStateFormGroup: FormGroup;
  disabledState: 'away' | 'returned' | 'none';
  isContactRequired = false;

  contacts: GenericContact[] = [];

  get formArray(): AbstractControl | null { return this.updateElementStateFormGroup.get('formArray'); }

  constructor(
    private alertService: AlertService,
    private apiService: ApiService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UpdateKeyStatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpdateKeyStatesDialogData,
    public createContactDialog: MatDialog
  ) {
    this.updateElementStateFormGroup = fb.group({
      formArray: fb.array([
        fb.group({
          element: ['', Validators.required]
        }),
        fb.group({
          state: ['', [Validators.required, this.isNotDisabledStateValidatorFn()]],
          contact: [{value: '', disabled: true}],
          reminder: [{value: null, disabled: true}]
        }),
      ]),
    });
  }

  ngOnInit(): void {
    this.refreshContacts();
  }

  refreshContacts(): void {
    this.apiService.getContacts().subscribe(
      (contacts) => {
        this.contacts = contacts.filter((contact) => {
          return contact.type === 'service_provider' && contact.agency.id === this.data.property.agency.id;
        }).sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
      }, () => {
        this.alertService.warn('Une erreur est survenue lors de la récupération des contacts');
      }
    );
  }

  getLastState(type: 'key' | 'keyring', id: number): GenericHistoryEntry | undefined {
    const validEntries = this.data.property.history.filter((entry) => {
      return entry.type === type && entry.relatedId === id;
    });
    validEntries.sort((a, b) => {
      return new Date(b.time).getTime() - new Date(a.time).getTime();
    });
    if (validEntries && validEntries.length) {
      return validEntries[0];
    }
    return undefined;
  }

  computeDisabledState(): void {
    setTimeout(() => {
      const selectedElement = this.formArray.get([0]).get('element').value;
      const lastState = this.getLastState(selectedElement.type, selectedElement.relatedId);
      if (!lastState || lastState.state === 'returned') {
        this.disabledState = 'returned';
      } else if (lastState && lastState.state === 'away') {
        this.disabledState = 'away';
      } else {
        this.disabledState = 'none';
      }
      this.formArray.get([1]).get('state').updateValueAndValidity();
    });
  }

  computeIsContactRequired(): void {
    setTimeout(() => {
      this.isContactRequired = this.formArray.get([1]).get('state').value === 'away';
      if (this.isContactRequired) {
        this.formArray.get([1]).get('contact').enable();
        this.formArray.get([1]).get('reminder').enable();
      } else {
        this.formArray.get([1]).get('contact').disable();
        this.formArray.get([1]).get('reminder').disable();
      }
    });
  }

  computeStateDisplay(state: 'away' | 'returned' | 'lost'): string {
    switch (state) {
      case 'returned':
        return 'Rentré';
      case 'lost':
        return 'Perdu';
      case 'away':
        return 'Sorti';
      default:
        return 'Aucune';
    }
  }

  isNotDisabledStateValidatorFn(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      if (!input) {
        return null;
      }
      return input === this.disabledState ? {isNotDisabledStateValidatorFn: {isNotDisabledStateValidatorFn: false}} : null;
    };
  }

  newContact(): void {
    const data: CreateContactDialogData = {
      type: 'service_provider'
    };
    const dialogRef = this.createContactDialog.open(CreateContactDialogComponent, {
      width: '80%',
      data
    });
    dialogRef.afterClosed().subscribe(() => {
      this.refreshContacts();
    });
  }

  onSubmit(): void {
    this.updateElementStateFormGroup.updateValueAndValidity();
    if (this.updateElementStateFormGroup.valid) {
      const contactId = this.isContactRequired ? this.formArray.get([1]).get('contact').value.id : undefined;
      const reminderValue = this.formArray.get([1]).get('reminder').value;
      const reminder = reminderValue ? new Date(reminderValue.toISOString()) : undefined;
      const payload: PostHistoryPayload = {
        type: this.formArray.get([0]).get('element').value.type,
        relatedId: this.formArray.get([0]).get('element').value.relatedId,
        state: this.formArray.get([1]).get('state').value,
        contactId,
        reminder,
        propertyId: this.data.property.id
      };
      this.apiService.postHistory(payload).subscribe(
        () => {
          this.dialogRef.close();
          this.alertService.success('Le changement a bien été effectué');
        }, () => {
          this.alertService.error('Une erreur est survenue lors du changement d\'état, veuillez réessayer');
        }
      );
    }
  }
}
