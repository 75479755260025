import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../core/services/api/api.service';
import {AlertService} from '../../../../core/services/alert/alert.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GenericAgency} from '../../../../shared/models/api/agencies/generic-agency';

export interface EditAgencyDialogData {
  agency: GenericAgency;
}

@Component({
  selector: 'app-edit-agency-dialog',
  templateUrl: './edit-agency-dialog.component.html',
  styleUrls: ['./edit-agency-dialog.component.scss']
})
export class EditAgencyDialogComponent implements OnInit {
  formGroup: FormGroup;
  fileToUpload: File = null;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<EditAgencyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditAgencyDialogData,
  ) {
    this.formGroup = fb.group({
      image: [''],
      name: [data.agency.name],
      description: [data.agency.description],
    });
  }

  ngOnInit(): void {
  }

  async onSubmit(): Promise<void> {
    this.formGroup.updateValueAndValidity();
    if (!this.formGroup.valid) {
      return;
    }
    let agencyImage;
    let oldImageId;
    if (this.fileToUpload) {
      try {
        agencyImage = await this.apiService.uploadImage(this.fileToUpload).toPromise();
      } catch {
        this.alertService.error(`Une erreur est survenue lors de l'envoi de l'image`);
        this.dialogRef.close();
        return;
      }
      if (this.data.agency.agencyImage) {
        oldImageId = this.data.agency.agencyImage.id;
      }
    }
    try {
      const agency = await this.apiService.patchAgency(this.data.agency.id, {
        name: this.formGroup.get('name').value || undefined,
        description: this.formGroup.get('description').value || undefined,
        agencyImageId: agencyImage ? agencyImage.id : undefined
      }).toPromise();
      this.alertService.success(`L'agence à bien été mise à jour`);
      if (oldImageId) {
        try {
          await this.apiService.deleteImage(oldImageId).toPromise();
        } catch {
          this.alertService.error(`Une erreur est survenue lors de la suppression de l'ancienne image`);
        }
      }
      this.dialogRef.close(agency);
    } catch {
      this.alertService.error(`Une erreur est survenue lors de la mise à jour de l'agence`);
      this.dialogRef.close();
    }
  }

  onFileChange(files: FileList): void {
    this.fileToUpload = files.item(0);
  }

}
