import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-property-page',
  templateUrl: './create-property-page.component.html',
  styleUrls: ['./create-property-page.component.scss']
})
export class CreatePropertyPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
