import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './pages/admin/admin.component';
import {MatDividerModule} from '@angular/material/divider';
import { UsersListComponent } from './components/users-list/users-list.component';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSortModule} from '@angular/material/sort';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { CreateUserDialogComponent } from './components/create-user-dialog/create-user-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { AgencyInfosComponent } from './components/agency-infos/agency-infos.component';
import {SharedModule} from '../../shared/shared.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AgenciesListComponent } from './components/agencies-list/agencies-list.component';
import {MatIconModule} from '@angular/material/icon';
import { CreateAgencyDialogComponent } from './components/create-agency-dialog/create-agency-dialog.component';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import { EditAgencyDialogComponent } from './components/edit-agency-dialog/edit-agency-dialog.component';
import {MatSelectModule} from '@angular/material/select';


@NgModule({
  declarations: [AdminComponent, UsersListComponent, CreateUserDialogComponent, AgencyInfosComponent, AgenciesListComponent, CreateAgencyDialogComponent, EditAgencyDialogComponent],
  exports: [
    AgencyInfosComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AdminRoutingModule,
    MatDividerModule,
    MatCardModule,
    MatButtonModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatTableModule,
    FormsModule,
    MatInputModule,
    MatSortModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatDialogModule,
    ReactiveFormsModule,
    SharedModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MaterialFileInputModule,
    MatSelectModule
  ]
})
export class AdminModule { }
