import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../core/services/api/api.service';
import {AlertService} from '../../../../core/services/alert/alert.service';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../../core/services/authentication/authentication.service';
import {ResetPasswordAskPayload} from '../../../../shared/models/api/auth/reset-password-ask-payload';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  hide = true;
  forgotPasswordForm: FormGroup;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private alertService: AlertService,
    private authService: AuthenticationService
  ) {
    this.forgotPasswordForm = fb.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])]
    });
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.submitted = true;
    const payload: ResetPasswordAskPayload = {
      email: this.forgotPasswordForm.get('email').value,
    };

    this.apiService.authResetPasswordAsk(payload).subscribe(
      () => {
        this.alertService.success('Si l\'adresse email est valide vous recevrez un lien dans quelques instants');
      }
    );
  }
}
