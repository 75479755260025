import { Component, OnInit } from '@angular/core';
import { Alert } from '../../models/alert';
import { AlertService } from 'src/app/core/services/alert/alert.service';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
    alerts: Alert[];

    constructor(private alertService: AlertService) {
        this.alerts = [];
    }

    ngOnInit(): void {
        this.alertService.getAlert().subscribe((alert: Alert) => {
            if (!alert) {
                this.alerts = [];
                return;
            }
            this.alerts.push(alert);
            setTimeout(() => {
              this.removeAlert(alert);
            }, 3000);
        });
    }

    removeAlert(alert: Alert): void {
        this.alerts = this.alerts.filter((x) => x !== alert);
    }
}
