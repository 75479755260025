<div class="d-flex justify-content-between">
  <h1 class="mat-h1" mat-dialog-title>Changer l'état d'un élément</h1>
  <button mat-icon-button type="button" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
</div>
<mat-dialog-content>
  <form [formGroup]="updateElementStateFormGroup" (ngSubmit)="onSubmit()">
    <mat-horizontal-stepper formArrayName="formArray" [linear]="true" #stepper>

      <mat-step formGroupName="0" [stepControl]="formArray.get([0])">
          <ng-template matStepLabel>Sélectionnez un élément à mettre à jour</ng-template>
          <mat-form-field class="full-width">
            <mat-label>Élément</mat-label>
            <mat-select formControlName="element" (valueChange)="computeDisabledState()" required #element>
              <mat-option>-- Aucun --</mat-option>
              <mat-optgroup label="Trousseaux">
                <mat-option *ngFor="let keyring of data.keyrings" [value]="{type: 'keyring', relatedId: keyring.id, name: keyring.name}">
                  {{keyring.name}}
                </mat-option>
              </mat-optgroup>
              <mat-optgroup label="Clés">
                <mat-option *ngFor="let key of data.keys" [value]="{type: 'key', relatedId: key.id, name: key.name}">
                  {{key.name}}
                </mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
          <div>
            <button mat-button matStepperNext type="button">Suivant</button>
          </div>
      </mat-step>

      <mat-step formGroupName="1" [stepControl]="formArray.get([1])">
        <ng-template matStepLabel>Sélectionnez le nouvel état</ng-template>
        <div class="row">
          <div class="col-4">
            <mat-form-field class="full-width">
              <mat-label>Élément</mat-label>
              <mat-select formControlName="state" (valueChange)="computeIsContactRequired()" required #state>
                <mat-option>-- Aucun --</mat-option>
                <mat-option value="returned" [disabled]="disabledState === 'returned'">
                  Rentré
                </mat-option>
                <mat-option value="away" [disabled]="disabledState === 'away'">
                  Sorti
                </mat-option>
                <mat-option value="lost">
                  Perdu
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="full-width">
              <mat-label>Contact</mat-label>
              <mat-select formControlName="contact" [required]="isContactRequired" #contact>
                <mat-option>-- Aucun --</mat-option>
                <mat-option (click)="newContact()">-- Nouveau --</mat-option>
                <mat-option *ngFor="let contact of contacts" [value]="contact">
                  {{contact.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="full-width">
              <mat-label>Rappel</mat-label>
              <input matInput [min]="minDate" formControlName="reminder" [matDatepicker]="picker" #datePicker>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div>
          <button mat-button matStepperNext type="button">Suivant</button>
        </div>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Vérification</ng-template>
        <div class="row">
          <div class="col-12">Élément: {{element.value.name}}</div>
          <div class="col-12 d-flex">Nouvel état: <div [class]="state.value+'-state'">{{computeStateDisplay(state.value).toLowerCase()}}</div></div>
          <div class="col-12" *ngIf="contact.value">Contact: {{contact.value.name}} ({{contact.value.email}}{{contact.value.phone ? ', ' : ''}}{{contact.value.phone}})</div>
          <div class="col-12" *ngIf="contact.value && contact.value.address">Adresse: {{contact.value.address.streetNumber}} {{contact.value.address.street}}, {{contact.value.address.zipcode}} {{contact.value.address.city}}</div>
          <div class="col-12" *ngIf="datePicker.value">Rappel automatique programmé le: {{datePicker.value}}</div>
        </div>
        <div class="horizontal-spacer"></div>
        <div>
          <button mat-raised-button type="submit" color="primary">Valider</button>
        </div>
      </mat-step>

    </mat-horizontal-stepper>
  </form>
</mat-dialog-content>
