import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {LoginPayload} from '../../../shared/models/api/auth/login-payload';
import {LoginResponse} from '../../../shared/models/api/auth/login-response';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {RefreshPayload} from '../../../shared/models/api/auth/refresh-payload';
import {RefreshResponse} from '../../../shared/models/api/auth/refresh-response';
import {RevokePayload} from '../../../shared/models/api/auth/revoke-payload';
import {ResetPasswordAskPayload} from '../../../shared/models/api/auth/reset-password-ask-payload';
import {ResetPasswordResetPayload} from '../../../shared/models/api/auth/reset-password-reset-payload';
import {GetUserMeResponse} from '../../../shared/models/api/users/get-user-me-response';
import {PatchUserPayload} from '../../../shared/models/api/users/patch-user-payload';
import {PatchUserResponse} from '../../../shared/models/api/users/patch-user-response';
import {GetPropertiesResponse} from '../../../shared/models/api/properties/get-properties-response';
import {GetPropertyResponse} from '../../../shared/models/api/properties/get-property-response';
import {PostHistoryPayload} from '../../../shared/models/api/history/post-history-payload';
import {PostHistoryResponse} from '../../../shared/models/api/history/post-history-response';
import {GetContactsResponse} from '../../../shared/models/api/contacts/get-contacts-response';
import {PostContactsPayload} from '../../../shared/models/api/contacts/post-contacts-payload';
import {PostContactsResponse} from '../../../shared/models/api/contacts/post-contacts-response';
import {GetUsersResponse} from '../../../shared/models/api/users/get-users-response';
import {UploadImageResponse} from '../../../shared/models/api/images/upload-image-response';
import {PostPropertyPayload} from '../../../shared/models/api/properties/post-property-payload';
import {PostPropertyResponse} from '../../../shared/models/api/properties/post-property-response';
import {PatchPropertyPayload} from '../../../shared/models/api/properties/patch-property-payload';
import {PatchPropertyResponse} from '../../../shared/models/api/properties/patch-property-response';
import {GetKeysResponse} from '../../../shared/models/api/keys/get-keys-response';
import {PostKeyResponse} from '../../../shared/models/api/keys/post-key-response';
import {PostKeyPayload} from '../../../shared/models/api/keys/post-key-payload';
import {PatchKeyPayload} from '../../../shared/models/api/keys/patch-key-payload';
import {PatchKeyResponse} from '../../../shared/models/api/keys/patch-key-response';
import {GetKeyringsResponse} from '../../../shared/models/api/keyrings/get-keyrings-response';
import {PostKeyringPayload} from '../../../shared/models/api/keyrings/post-keyring-payload';
import {PatchKeyringPayload} from '../../../shared/models/api/keyrings/patch-keyring-payload';
import {PostKeyringResponse} from '../../../shared/models/api/keyrings/post-keyring-response';
import {PatchKeyringResponse} from '../../../shared/models/api/keyrings/patch-keyring-response';
import {PostUserPayload} from '../../../shared/models/api/users/post-user-payload';
import {PostUserResponse} from '../../../shared/models/api/users/post-user-response';
import {GetAgenciesResponse} from '../../../shared/models/api/agencies/get-agencies-response';
import {PostAgencyPayload} from '../../../shared/models/api/agencies/post-agency-payload';
import {PostAgencyResponse} from '../../../shared/models/api/agencies/post-agency-response';
import {GetAgencyResponse} from '../../../shared/models/api/agencies/get-agency-response';
import {PatchAgencyPayload} from '../../../shared/models/api/agencies/patch-agency-payload';
import {PatchAgencyResponse} from '../../../shared/models/api/agencies/patch-agency-response';
import {PatchContactsResponse} from '../../../shared/models/api/contacts/patch-contacts-response';
import {PatchContactsPayload} from '../../../shared/models/api/contacts/patch-contacts-payload';
import {PostTenantPayload} from '../../../shared/models/api/tenants/post-tenant-payload';
import {PostTenantResponse} from '../../../shared/models/api/tenants/post-tenant-response';
import {PatchTenantPayload} from '../../../shared/models/api/tenants/patch-tenant-payload';
import {PatchTenantResponse} from '../../../shared/models/api/tenants/patch-tenant-response';
import {GetTenantsResponse} from '../../../shared/models/api/tenants/get-tenants-response';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private static handleError(error: Response | any): Observable<never> {
    console.error('ApiService::handleError', error);
    return throwError(error);
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
    });
  }

  constructor(private http: HttpClient) { }

  /* Without tokens routes */
  public authLogin(payload: LoginPayload): Observable<LoginResponse> {
    return this.http
      .post(`${API_URL}/auth/login`, payload, { headers: this.getHeaders() })
      .pipe(map((res) => res as LoginResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public authRefresh(payload: RefreshPayload): Observable<RefreshResponse> {
    return this.http
      .post(`${API_URL}/auth/refresh`, payload, { headers: this.getHeaders() })
      .pipe(map((res) => res as RefreshResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public authRevoke(payload: RevokePayload): Observable<void> {
    return this.http
      .post(`${API_URL}/auth/revoke`, payload, { headers: this.getHeaders() })
      .pipe(map((res) => {}))
      .pipe(catchError(ApiService.handleError));
  }

  public authResetPasswordAsk(payload: ResetPasswordAskPayload): Observable<void> {
    return this.http
      .post(`${API_URL}/auth/resetPassword/ask`, payload, { headers: this.getHeaders() })
      .pipe(map((res) => {}))
      .pipe(catchError(ApiService.handleError));
  }

  public authResetPasswordReset(payload: ResetPasswordResetPayload): Observable<RefreshResponse> {
    return this.http
      .post(`${API_URL}/auth/resetPassword/reset`, payload, { headers: this.getHeaders() })
      .pipe(map((res) => res as RefreshResponse))
      .pipe(catchError(ApiService.handleError));
  }

  /* With token routes */
  public getUsers(): Observable<GetUsersResponse> {
    return this.http
      .get(`${API_URL}/users`, { headers: this.getHeaders() })
      .pipe(map((res) => res as GetUsersResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public getUserMe(): Observable<GetUserMeResponse> {
    return this.http
      .get(`${API_URL}/users/me`, { headers: this.getHeaders() })
      .pipe(map((res) => res as GetUserMeResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public postUser(payload: PostUserPayload): Observable<PostUserResponse> {
    return this.http
      .post(`${API_URL}/users`, payload, {headers: this.getHeaders()})
      .pipe(map((res) => res as PostUserResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public patchUser(id: number, payload: PatchUserPayload): Observable<PatchUserResponse> {
    return this.http
      .patch(`${API_URL}/users/${id}`, payload, { headers: this.getHeaders() })
      .pipe(map((res) => res as PatchUserResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public getProperties(): Observable<GetPropertiesResponse> {
    return this.http
      .get(`${API_URL}/properties`, { headers: this.getHeaders() })
      .pipe(map((res) => res as GetPropertiesResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public getProperty(id: number): Observable<GetPropertyResponse> {
    return this.http
      .get(`${API_URL}/properties/${id}`, { headers: this.getHeaders() })
      .pipe(map((res) => res as GetPropertyResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public postProperties(payload: PostPropertyPayload): Observable<PostPropertyResponse> {
    return this.http
      .post(`${API_URL}/properties`, payload, { headers: this.getHeaders() })
      .pipe(map((res) => res as PostPropertyResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public patchProperty(id: number, payload: PatchPropertyPayload): Observable<PatchPropertyResponse> {
    return this.http
      .patch(`${API_URL}/properties/${id}`, payload, { headers: this.getHeaders() })
      .pipe(map((res) => res as PatchPropertyResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public deleteProperty(id: number): Observable<boolean> {
    return this.http
      .delete(`${API_URL}/properties/${id}`, {headers: this.getHeaders()})
      .pipe(map(() => true))
      .pipe(catchError(ApiService.handleError));
  }

  public getContacts(): Observable<GetContactsResponse> {
    return this.http
      .get(`${API_URL}/contacts`, { headers: this.getHeaders() })
      .pipe(map((res) => res as GetContactsResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public postContacts(payload: PostContactsPayload): Observable<PostContactsResponse> {
    return this.http
      .post(`${API_URL}/contacts`, payload, { headers: this.getHeaders() })
      .pipe(map((res) => res as PostContactsResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public patchContact(id: number, payload: PatchContactsPayload): Observable<PatchContactsResponse> {
    return this.http
      .patch(`${API_URL}/contacts/${id}`, payload, { headers: this.getHeaders() })
      .pipe(map((res) => res as PatchContactsResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public deleteContact(id: number): Observable<boolean> {
    return this.http
      .delete(`${API_URL}/contacts/${id}`, {headers: this.getHeaders()})
      .pipe(map(() => true))
      .pipe(catchError(ApiService.handleError));
  }

  public postHistory(payload: PostHistoryPayload): Observable<PostHistoryResponse> {
    return this.http
      .post(`${API_URL}/history`, payload, { headers: this.getHeaders() })
      .pipe(map((res) => res as PostHistoryResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public downloadImage(id: number): Observable<any> {
    return this.http
      .get(`${API_URL}/images/${id}/download`, { headers: this.getHeaders(), responseType: 'blob'})
      .pipe(catchError(ApiService.handleError));
  }

  public uploadImage(file: File): Observable<UploadImageResponse> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http
      .post(`${API_URL}/images`, formData, { headers: this.getHeaders() })
      .pipe(map((res) => res as UploadImageResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public deleteImage(id: number): Observable<boolean> {
    return this.http
      .delete(`${API_URL}/images/${id}`, {headers: this.getHeaders()})
      .pipe(map(() => true))
      .pipe(catchError(ApiService.handleError));
  }

  public getKeys(propertyId: number): Observable<GetKeysResponse> {
    return this.http
      .get(`${API_URL}/keys?propertyId=${propertyId}`, {headers: this.getHeaders()})
      .pipe(map((res) => res as GetKeysResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public postKey(payload: PostKeyPayload): Observable<PostKeyResponse> {
    return this.http
      .post(`${API_URL}/keys`, payload, {headers: this.getHeaders()})
      .pipe(map((res) => res as PostKeyResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public patchKey(id: number, payload: PatchKeyPayload): Observable<PatchKeyResponse> {
    return this.http
      .patch(`${API_URL}/keys/${id}`, payload, {headers: this.getHeaders()})
      .pipe(map((res) => res as PatchKeyResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public deleteKey(id: number): Observable<boolean> {
    return this.http
      .delete(`${API_URL}/keys/${id}`, {headers: this.getHeaders()})
      .pipe(map(() => true))
      .pipe(catchError(ApiService.handleError));
  }

  public getKeyrings(propertyId: number): Observable<GetKeyringsResponse> {
    return this.http
      .get(`${API_URL}/keyrings?propertyId=${propertyId}`, {headers: this.getHeaders()})
      .pipe(map((res) => res as GetKeyringsResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public postKeyring(payload: PostKeyringPayload): Observable<PostKeyringResponse> {
    return this.http
      .post(`${API_URL}/keyrings`, payload, {headers: this.getHeaders()})
      .pipe(map((res) => res as PostKeyringResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public patchKeyring(id: number, payload: PatchKeyringPayload): Observable<PatchKeyringResponse> {
    return this.http
      .patch(`${API_URL}/keyrings/${id}`, payload, {headers: this.getHeaders()})
      .pipe(map((res) => res as PatchKeyringResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public deleteKeyring(id: number): Observable<boolean> {
    return this.http
      .delete(`${API_URL}/keyrings/${id}`, {headers: this.getHeaders()})
      .pipe(map(() => true))
      .pipe(catchError(ApiService.handleError));
  }

  public getAgencies(): Observable<GetAgenciesResponse> {
    return this.http
      .get(`${API_URL}/agencies`, {headers: this.getHeaders()})
      .pipe(map((res) => res as GetAgenciesResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public postAgency(payload: PostAgencyPayload): Observable<PostAgencyResponse> {
    return this.http
      .post(`${API_URL}/agencies`, payload, {headers: this.getHeaders()})
      .pipe(map((res) => res as PostAgencyResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public getAgency(id: number): Observable<GetAgencyResponse> {
    return this.http
      .get(`${API_URL}/agencies/${id}`, {headers: this.getHeaders()})
      .pipe(map((res) => res as GetAgencyResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public patchAgency(id: number, payload: PatchAgencyPayload): Observable<PatchAgencyResponse> {
    return this.http
      .patch(`${API_URL}/agencies/${id}`, payload, {headers: this.getHeaders()})
      .pipe(map((res) => res as PatchAgencyResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public deleteAgency(id: number): Observable<boolean> {
    return this.http
      .delete(`${API_URL}/agencies/${id}`, {headers: this.getHeaders()})
      .pipe(map(() => true))
      .pipe(catchError(ApiService.handleError));
  }

  public getTenants(propertyId: number): Observable<GetTenantsResponse> {
    return this.http
      .get(`${API_URL}/tenants?propertyId=${propertyId}`, {headers: this.getHeaders()})
      .pipe(map((res) => res as GetTenantsResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public postTenant(payload: PostTenantPayload): Observable<PostTenantResponse> {
    return this.http
      .post(`${API_URL}/tenants`, payload, {headers: this.getHeaders()})
      .pipe(map((res) => res as PostTenantResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public patchTenant(id: number, payload: PatchTenantPayload): Observable<PatchTenantResponse> {
    return this.http
      .patch(`${API_URL}/tenants/${id}`, payload, {headers: this.getHeaders()})
      .pipe(map((res) => res as PatchTenantResponse))
      .pipe(catchError(ApiService.handleError));
  }

  public deleteTenant(id: number): Observable<boolean> {
    return this.http
      .delete(`${API_URL}/tenants/${id}`, {headers: this.getHeaders()})
      .pipe(map(() => true))
      .pipe(catchError(ApiService.handleError));
  }
}
