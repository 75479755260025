import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './pages/profile/profile.component';
import { ProfileInfosComponent } from './components/profile-infos/profile-infos.component';
import { ProfileUpdatePasswordComponent } from './components/profile-update-password/profile-update-password.component';
import {MatCardModule} from '@angular/material/card';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {AdminModule} from '../admin/admin.module';


@NgModule({
  declarations: [ProfileComponent, ProfileInfosComponent, ProfileUpdatePasswordComponent],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    MatCardModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    AdminModule
  ]
})
export class ProfileModule { }
