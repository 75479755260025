<mat-expansion-panel (opened)="onOpen()" (closed)="onClose()">
  <mat-expansion-panel-header class="panel-header">
    <mat-panel-title class="col-4 col-md-6"><h5 class="mat-h5">{{key.name}}</h5></mat-panel-title>
    <mat-panel-description class="col-8 col-md-6" *ngIf="!isOpen">
      <div class="d-flex justify-content-end panel-description-state">
        <div class="col-4 d-flex ma-auto justify-content-around">
          <div class="d-none d-md-block">Rentré</div>
          <mat-icon *ngIf="state.state === 'returned'" class="returned-state">radio_button_checked</mat-icon>
          <mat-icon *ngIf="state.state !== 'returned'">radio_button_unchecked</mat-icon>
        </div>
        <div class="col-4 d-flex ma-auto justify-content-around">
          <div class="d-none d-md-block">Sorti</div>
          <mat-icon *ngIf="state.state === 'away'" class="away-state">radio_button_checked</mat-icon>
          <mat-icon *ngIf="state.state !== 'away'">radio_button_unchecked</mat-icon>
        </div>
        <div class="col-4 d-flex ma-auto justify-content-around">
          <div class="d-none d-md-block">Perdu</div>
          <mat-icon *ngIf="state.state === 'lost'" class="lost-state">radio_button_checked</mat-icon>
          <mat-icon *ngIf="state.state !== 'lost'">radio_button_unchecked</mat-icon>
        </div>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="row">
    <div class="col-12 col-md-5">
      <h6>Informations</h6>
      <div class="row">
        <div class="col-4 col-md-3 bold-text">Repère</div>
        <div class="col-8 col-md-4 bold-text">Désignation</div>
        <div class="col-12 col-md-5 bold-text">Description</div>
      </div>
      <div class="row">
        <div class="col-4 col-md-3 keyring-key-tag">{{key.tag}}</div>
        <div class="col-8 col-md-4 keyring-key-name">{{key.name}}</div>
        <div *ngIf="key.description && key.description.length" class="col-12 col-md-5 keyring-key-description">{{key.description}}</div>
      </div>
    </div>
    <mat-divider vertical></mat-divider>
    <div class="col-12 col-md-3">
      <h6>Prestataire</h6>
      <div *ngIf="state.contact">
        <div>Nom: {{state.contact.name}}<br></div>
        <div *ngIf="state.contact.office">Fonction: {{state.contact.office}}<br></div>
        <div>email: {{state.contact.email}}<br></div>
        <div *ngIf="state.contact.phone">téléphone: {{state.contact.phone}}<br></div>
      </div>
      <div *ngIf="state.reminder">
        <div>Rappel le {{getHumanReadableDate(state.reminder)}}</div>
      </div>
    </div>
    <mat-divider vertical></mat-divider>
    <div class="col-12 col-md-3">
      <h6>État</h6>
      <div class="d-flex">
        <div class="col-4 d-flex flex-column">
          <div class="ma-auto">Rentré</div>
          <button mat-icon-button disabled *ngIf="state.state === 'returned'" class="ma-auto"><mat-icon class="returned-state">radio_button_checked</mat-icon></button>
          <button mat-icon-button *ngIf="state.state !== 'returned'" class="ma-auto" (click)="onReturnedButtonClick()"><mat-icon>radio_button_unchecked</mat-icon></button>
        </div>
        <div class="col-4 d-flex flex-column">
          <div class="ma-auto">Sorti</div>
          <button mat-icon-button disabled *ngIf="state.state === 'away'" class="ma-auto"><mat-icon class="away-state">radio_button_checked</mat-icon></button>
          <button mat-icon-button *ngIf="state.state !== 'away'" class="ma-auto" (click)="onAwayButtonClick()"><mat-icon>radio_button_unchecked</mat-icon></button>
        </div>
        <div class="col-4 d-flex flex-column">
          <div class="ma-auto">Perdu</div>
          <button mat-icon-button disabled *ngIf="state.state === 'lost'" class="ma-auto"><mat-icon class="lost-state">radio_button_checked</mat-icon></button>
          <button mat-icon-button *ngIf="state.state !== 'lost'" class="ma-auto" (click)="onLostButtonClick()"><mat-icon>radio_button_unchecked</mat-icon></button>
        </div>
      </div>
    </div>
  </div>
</mat-expansion-panel>
