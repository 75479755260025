import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DisplayHistoryDialogData} from '../../../../shared/models/properties/display-history-dialog-data';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {CustomPaginator} from '../../../admin/components/users-list/users-list.component';

interface TableRow {
  type: string;
  name: string;
  date: number;
  state: string;
  contact: string;
  origin: string;
}

@Component({
  selector: 'app-display-history-dialog',
  templateUrl: './display-history-dialog.component.html',
  styleUrls: ['./display-history-dialog.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('Taille de l\'historique: ')}
  ]
})
export class DisplayHistoryDialogComponent implements OnInit {
  displayedColumns: string[] = ['type', 'name', 'date', 'state', 'contact', 'origin'];
  dataSource: MatTableDataSource<TableRow>;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    public dialogRef: MatDialogRef<DisplayHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DisplayHistoryDialogData
  ) { }

  ngOnInit(): void {
    this.refreshDataSource();

    const sortState: Sort = {active: 'date', direction: 'desc'};
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  refreshDataSource(): void {
    this.dataSource = new MatTableDataSource(this.computeHistoryToTableRow());
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  computeHistoryToTableRow(): TableRow[] {
    return this.data.property.history.map((entry) => {
      if (entry.type === 'key') {
        const relatedKey = this.data.property.keys.find((key) => {
          return key.id === entry.relatedId;
        });
        return {
          type: 'Clé',
          name: `${relatedKey.name} (${relatedKey.tag})`,
          date: new Date(entry.time).getTime(),
          state: this.computeStateDisplay(entry.state),
          contact: entry.contact ? `${entry.contact.name}${entry.contact.office ? ', ' + entry.contact.office + ' ' : ''}(${entry.contact.email}${entry.contact.phone ? ', ' + entry.contact.phone : ''})` : '',
          origin: `${entry.origin.firstname} ${entry.origin.lastname} (${entry.origin.email})`
        };
      } else {
        const relatedKeyring = this.data.property.keyrings.find((keyring) => {
          return keyring.id === entry.relatedId;
        });
        return {
          type: 'Trousseau',
          name: `${relatedKeyring.name}`,
          date: new Date(entry.time).getTime(),
          state: this.computeStateDisplay(entry.state),
          contact: entry.contact ? `${entry.contact.name}${entry.contact.office ? ', ' + entry.contact.office + ' ' : ''}(${entry.contact.email}${entry.contact.phone ? ', ' + entry.contact.phone : ''})` : '',
          origin: `${entry.origin.firstname} ${entry.origin.lastname} (${entry.origin.email})`
        };
      }
    });
  }

  computeDateDisplay(date: number): string {
    return `${new Date(date).toLocaleDateString('fr-FR')} ${new Date(date).toLocaleTimeString('fr-FR')}`;
  }

  computeStateDisplay(state: 'away' | 'returned' | 'lost'): string {
    switch (state) {
      case 'away':
        return 'sorti';
      case 'lost':
        return 'perdu';
      case 'returned':
        return 'rentré';
    }
  }
}
