import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './components/alert/alert.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AuthImagePipe} from './pipes/auth-image.pipe';
import { AuthImageComponent } from './components/auth-image/auth-image.component';
import { CreateContactDialogComponent } from './components/create-contact-dialog/create-contact-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ValidationDialogComponent } from './components/validation-dialog/validation-dialog.component';
import { ContactsDisplayDialogComponent } from './components/contacts-display-dialog/contacts-display-dialog.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatIconModule} from '@angular/material/icon';
import {TenantsDisplayDialogComponent} from './components/tenants-display-dialog/tenants-display-dialog.component';
import {CreateTenantDialogComponent} from './components/create-tenant-dialog/create-tenant-dialog.component';

@NgModule({
    declarations: [
      AlertComponent,
      AuthImagePipe,
      AuthImageComponent,
      CreateContactDialogComponent,
      ValidationDialogComponent,
      ContactsDisplayDialogComponent,
      TenantsDisplayDialogComponent,
      CreateTenantDialogComponent
    ],
  exports: [
    AlertComponent, AuthImagePipe, AuthImageComponent
  ],
  imports: [
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    CommonModule,
    NgbModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
  ]
})
export class SharedModule { }
