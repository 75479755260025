import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../core/services/api/api.service';
import {AlertService} from '../../../../core/services/alert/alert.service';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../../core/services/authentication/authentication.service';
import {LoginPayload} from '../../../../shared/models/api/auth/login-payload';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  loginForm: FormGroup;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private alertService: AlertService,
    private router: Router,
    private authService: AuthenticationService
  ) {
    this.loginForm = fb.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      password: ['', Validators.required]
    });

  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.submitted = true;
    const payload: LoginPayload = {
      email: this.loginForm.get('email').value,
      password: this.loginForm.get('password').value
    };

    this.apiService.authLogin(payload).subscribe(
      (response) => {
        this.authService.signIn(response.access_token, response.refresh_token);
        this.router.navigate(['/properties']);
      }, (error) => {
        if (error.status === 401) {
          this.alertService.error('Adresse email ou mot de passe incorrect');
        } else {
          this.alertService.error(`${error.name}: ${error.message}`);
        }
      }
    );
  }
}
