import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {AlertService} from '../../../core/services/alert/alert.service';
import {ApiService} from '../../../core/services/api/api.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CreateContactDialogData} from '../../models/contacts/create-contact-dialog-data';
import {PostContactsPayload} from '../../models/api/contacts/post-contacts-payload';
import {parsePhoneNumberFromString} from 'libphonenumber-js';

@Component({
  selector: 'app-create-contact-dialog',
  templateUrl: './create-contact-dialog.component.html',
  styleUrls: ['./create-contact-dialog.component.scss']
})
export class CreateContactDialogComponent implements OnInit {
  createContactFormGroup: FormGroup;
  isSubmitting = false;

  constructor(
    private alertService: AlertService,
    private apiService: ApiService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateContactDialogData,
  ) {
    this.createContactFormGroup = fb.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      phone: ['', this.validatePhone()],
      office: ['']
    });
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.createContactFormGroup.updateValueAndValidity();
    if (!this.createContactFormGroup.valid) {
      return;
    }
    const payload: PostContactsPayload = {
      type: this.data.type,
      name: this.createContactFormGroup.get('name').value,
      email: this.createContactFormGroup.get('email').value,
      phone: this.createContactFormGroup.get('phone').value || undefined,
      office: this.createContactFormGroup.get('office').value || undefined,
      propertyId: this.data.propertyId
    };
    this.isSubmitting = true;
    this.apiService.postContacts(payload).subscribe(
      (contact) => {
        this.dialogRef.close(contact);
      }, () => {
        this.alertService.error('Le contact n\'a pas pu être créé veuillez vérifier vos informations');
        this.isSubmitting = false;
      }
    );
  }

  validatePhone(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      if (!input) {
        return null;
      }
      const phoneNumber = parsePhoneNumberFromString(input);
      return (!phoneNumber || !phoneNumber.isValid()) ? {phone: {phone: false}} : null;
    };
  }

  conditionalValidator(predicate): ValidatorFn {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    });
  }
}
