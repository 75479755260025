import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    constructor(private cookies: CookieService) {}

    public setAccessToken(token: string): void {
        this.cookies.set('accessToken', token, undefined, '/');
    }

    public setRefreshToken(token: string): void {
        this.cookies.set('refreshToken', token, undefined, '/');
    }

    public getAccessToken(): string | null {
        return this.cookies.get('accessToken');
    }

    public getRefreshToken(): string | null {
        return this.cookies.get('refreshToken');
    }

    public destroy(): void {
      this.cookies.delete('accessToken', '/');
      this.cookies.delete('refreshToken', '/');
      this.cookies.deleteAll();
    }
}
