<form [formGroup]="updatePasswordForm" (ngSubmit)="updatePasswordForm.valid && onSubmitUpdatePassword()">
  <mat-card>
    <mat-card-title>Changer de mot de passe</mat-card-title>
    <mat-card-content>
      <div class="row">
        <div class="col-12">
          <mat-form-field class="form-field">
            <mat-label>Mot de passe</mat-label>
            <input matInput placeholder="Mon mot de passe"
                   [type]="hide ? 'password' : 'text'"
                   id="password"
                   formControlName="password"
            />
            <mat-hint>Entrez ici votre nouveau mot de passe</mat-hint>
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="updatePasswordForm.controls.password.errors && updatePasswordForm.controls.password.errors.minlength">
              Le mot de passe doit faire au moins 8 caractères
            </mat-error>
            <mat-error *ngIf="updatePasswordForm.controls.password.errors && updatePasswordForm.controls.password.errors.required">
              Le mot de passe est requis
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="form-field">
            <mat-label>Mot de passe (validation)</mat-label>
            <input matInput placeholder="Mon mot de passe"
                   [type]="hide ? 'password' : 'text'"
                   id="passwordValidation"
                   formControlName="passwordValidation"
            />
            <mat-hint>Entrez de nouveau ici votre nouveau mot de passe</mat-hint>
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="updatePasswordForm.controls.passwordValidation.errors && updatePasswordForm.controls.passwordValidation.errors.passwordMatch">
              Les mots de passe ne sont pas identiques
            </mat-error>
            <mat-error *ngIf="updatePasswordForm.controls.passwordValidation.errors && updatePasswordForm.controls.passwordValidation.errors.required">
              Le mot de passe est requis
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions class="d-flex justify-content-end">
      <button mat-button type="submit" [disabled]="!updatePasswordForm.valid" color="primary">mettre à jour</button>
    </mat-card-actions>
  </mat-card>
</form>
