<div class="container pa-15">
  <div class="row">
    <div class="col-12">
      <h1 class="mat-h1">Mon profil</h1>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="horizontal-spacer"></div>
  <div class="row">
    <div class="col-12">
      <app-profile-infos></app-profile-infos>
    </div>
  </div>
  <div class="horizontal-spacer"></div>
  <div class="row">
    <div class="col-12">
      <app-profile-update-password></app-profile-update-password>
    </div>
  </div>
  <div class="horizontal-spacer"></div>
  <div class="row">
    <div class="col-12">
      <app-agency-infos></app-agency-infos>
    </div>
  </div>
</div>
