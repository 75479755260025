import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {AlertService} from '../../../core/services/alert/alert.service';
import {ApiService} from '../../../core/services/api/api.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import {PostTenantPayload} from '../../models/api/tenants/post-tenant-payload';
import {CreateTenantDialogData} from '../../models/tenants/create-tenant-dialog-data';

@Component({
  selector: 'app-create-tenant-dialog',
  templateUrl: './create-tenant-dialog.component.html',
  styleUrls: ['./create-tenant-dialog.component.scss']
})
export class CreateTenantDialogComponent implements OnInit {
  createTenantFormGroup: FormGroup;
  isSubmitting = false;

  constructor(
    private alertService: AlertService,
    private apiService: ApiService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateTenantDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateTenantDialogData,
  ) {
    this.createTenantFormGroup = fb.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      phone: ['', this.validatePhone()]
    });
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.createTenantFormGroup.updateValueAndValidity();
    if (!this.createTenantFormGroup.valid) {
      return;
    }
    const payload: PostTenantPayload = {
      name: this.createTenantFormGroup.get('name').value,
      email: this.createTenantFormGroup.get('email').value,
      phone: this.createTenantFormGroup.get('phone').value || undefined,
      propertyId: this.data.propertyId
    };
    this.isSubmitting = true;
    this.apiService.postTenant(payload).subscribe(
      (tenant) => {
        this.dialogRef.close(tenant);
      }, () => {
        this.alertService.error('Le locataire n\'a pas pu être créé veuillez vérifier vos informations');
        this.isSubmitting = false;
      }
    );
  }

  validatePhone(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      if (!input) {
        return null;
      }
      const phoneNumber = parsePhoneNumberFromString(input);
      return (!phoneNumber || !phoneNumber.isValid()) ? {phone: {phone: false}} : null;
    };
  }

  conditionalValidator(predicate): ValidatorFn {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    });
  }
}
