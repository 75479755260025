<div>
  <div>
    <div class="container form-container">
      <h2 class="mat-h2 title">Mot de passe oublié</h2>
      <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPasswordForm.valid && onSubmit()">
        <div class="col-12">
          <div class="form-group">
            <mat-form-field class="form-field">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Mon adresse email..."
                     type="text"
                     id="email"
                     formControlName="email"
                     [ngClass]="{
                       'is-invalid': forgotPasswordForm.controls.email.errors
                     }"
              />
              <mat-hint>Entrez ici votre adresse email</mat-hint>
              <mat-error *ngIf="forgotPasswordForm.controls.email.errors && forgotPasswordForm.controls.email.errors.required">
                L'adresse email est requise
              </mat-error>
              <mat-error *ngIf="forgotPasswordForm.controls.email.errors && forgotPasswordForm.controls.email.errors.pattern">
                L'adresse email indiqué n'est pas valide
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-12">
          <div class="button-container">
            <button mat-raised-button [disabled]="!forgotPasswordForm.valid" type="submit" color="primary">VALIDER</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
