import {Component, OnInit, EventEmitter, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ApiService} from '../../../../core/services/api/api.service';
import {AlertService} from '../../../../core/services/alert/alert.service';
import {GenericContact} from '../../../../shared/models/api/contacts/generic-contact';
import {CreateContactDialogData} from '../../../../shared/models/contacts/create-contact-dialog-data';
import {CreateContactDialogComponent} from '../../../../shared/components/create-contact-dialog/create-contact-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {GenericUser} from '../../../../shared/models/api/users/generic-user';
import {PostPropertyPayload} from '../../../../shared/models/api/properties/post-property-payload';
import {Router} from '@angular/router';
import {GenericProperty} from '../../../../shared/models/api/properties/generic-property';
import {UpdatePropertyDialogComponent} from '../update-property-dialog/update-property-dialog.component';
import {parsePhoneNumberFromString} from 'libphonenumber-js';

@Component({
  selector: 'app-create-property',
  templateUrl: './create-property.component.html',
  styleUrls: ['./create-property.component.scss']
})
export class CreatePropertyComponent implements OnInit {
  trusteeSelected = false;
  managementSelected = false;
  trusteeFormGroup: FormGroup;
  managementFormGroup: FormGroup;

  fileToUpload: File = null;
  contacts: GenericContact[];
  users: GenericUser[];

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onPropertyCreated = new EventEmitter<GenericProperty>();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private apiService: ApiService,
    private alertService: AlertService,
    public createContactDialog: MatDialog,
    public updatePropertyDialog: MatDialog
  ) {
    this.trusteeFormGroup = fb.group({
      image: ['', Validators.required],
      name: ['', Validators.required],
      internalCode: ['', Validators.required],
      building: [''],
      digitalCode: [''],
      streetNumber: [''],
      street: ['', Validators.required],
      zipcode: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]+$')])],
      city: ['', Validators.required],
      assistant: ['']
    });
    this.managementFormGroup = fb.group({
      internalCode: ['', Validators.required],
      digitalCode: [''],
      streetNumber: [''],
      street: ['', Validators.required],
      zipcode: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]+$')])],
      city: ['', Validators.required],
      assistant: [''],
      ownerName: ['', Validators.required],
      ownerEmail: ['', this.validateOptionalEmail()],
      ownerPhone: ['', this.validatePhone()],
      batchNumber: [''],
      store: [''],
      door: ['']
    });
  }

  ngOnInit(): void {
    this.refreshContacts();
    this.refreshUsers();
  }

  onSubmitTrusteeForm(): void {
    this.trusteeFormGroup.updateValueAndValidity();
    if (!this.trusteeFormGroup.valid) {
      this.alertService.warn('La fiche n\'est pas valide');
      return;
    }
    const assistantManagerId = this.trusteeFormGroup.get('assistant').value ? this.trusteeFormGroup.get('assistant').value.id : undefined;
    const payload: PostPropertyPayload = {
      name: this.trusteeFormGroup.get('name').value || undefined,
      type: 'trustee',
      internalCode: this.trusteeFormGroup.get('internalCode').value || undefined,
      building: this.trusteeFormGroup.get('building').value || undefined,
      digitalCode: this.trusteeFormGroup.get('digitalCode').value || undefined,
      streetNumber: this.trusteeFormGroup.get('streetNumber').value || undefined,
      street: this.trusteeFormGroup.get('street').value || undefined,
      zipcode: parseInt(this.trusteeFormGroup.get('zipcode').value, 10),
      city: this.trusteeFormGroup.get('city').value || undefined,
      assistantManagerId,
      propertyImageId: 0
    };
    this.apiService.uploadImage(this.fileToUpload).subscribe(
      (image) => {
        payload.propertyImageId = image.id;
        this.apiService.postProperties(payload).subscribe(
          (property) => {
            this.alertService.success('La fiche a bien été créée', true);
            this.onPropertyCreated.emit(property);
            const dialogRef = this.updatePropertyDialog.open(UpdatePropertyDialogComponent, {
              width: '90%',
              maxWidth: '90%',
              data: {property}
            });
            dialogRef.afterClosed().subscribe(() => {
              this.router.navigate(['/properties']);
            });
          }, () => {
            this.alertService.error('Une erreur est survenue lors de la création de la fiche');
          }
        );
      }, () => {
        this.alertService.error('Une erreur est survenue lors de l\'envoi de l\'image');
      }
    );
  }

  onSubmitManagementForm(): void {
    this.managementFormGroup.updateValueAndValidity();
    if (!this.managementFormGroup.valid) {
      this.alertService.warn('La fiche n\'est pas valide');
      return;
    }
    const assistantManagerId = this.managementFormGroup.get('assistant').value ? this.managementFormGroup.get('assistant').value.id : undefined;
    const payload: PostPropertyPayload = {
      type: 'management',
      internalCode: this.managementFormGroup.get('internalCode').value || undefined,
      ownerName: this.managementFormGroup.get('ownerName').value || undefined,
      ownerEmail: this.managementFormGroup.get('ownerEmail').value || undefined,
      ownerPhone: this.managementFormGroup.get('ownerPhone').value || undefined,
      digitalCode: this.managementFormGroup.get('digitalCode').value || undefined,
      streetNumber: this.managementFormGroup.get('streetNumber').value || undefined,
      street: this.managementFormGroup.get('street').value || undefined,
      zipcode: parseInt(this.managementFormGroup.get('zipcode').value, 10),
      city: this.managementFormGroup.get('city').value || undefined,
      batchNumber: this.managementFormGroup.get('batchNumber').value || undefined,
      store: this.managementFormGroup.get('store').value || undefined,
      door: this.managementFormGroup.get('door').value || undefined,
      assistantManagerId
    };
    this.apiService.postProperties(payload).subscribe(
      (property) => {
        this.alertService.success('La fiche a bien été créée', true);
        this.onPropertyCreated.emit(property);
        const dialogRef = this.updatePropertyDialog.open(UpdatePropertyDialogComponent, {
          width: '90%',
          maxWidth: '90%',
          data: {property}
        });
        dialogRef.afterClosed().subscribe(() => {
          this.router.navigate(['/properties']);
        });
      }, () => {
        this.alertService.error('Une erreur est survenue lors de la création de la fiche');
      }
    );
  }

  onFileChange(files: FileList): void {
    this.fileToUpload = files.item(0);
  }

  newContact(): void {
    const data: CreateContactDialogData = {
      type: 'syndicate_contact'
    };
    const dialogRef = this.createContactDialog.open(CreateContactDialogComponent, {
      width: '80%',
      data
    });
    dialogRef.afterClosed().subscribe(() => {
      this.refreshContacts();
    });
  }

  refreshContacts(): void {
    this.apiService.getContacts().subscribe(
      (contacts) => {
        this.contacts = contacts.filter((contact) => {
          return contact.type === 'syndicate_contact';
        }).sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
      }, () => {
        this.alertService.warn('Une erreur est survenue lors de la récupération des contacts');
      }
    );
  }

  refreshUsers(): void {
    this.apiService.getUserMe().subscribe(
      (me) => {
        this.apiService.getUsers().subscribe(
          (users) => {
            this.users = users.filter((user) => {
              return user.isActive && user.id !== me.id && user.managerType !== 'reception';
            }).sort((a, b) => {
              if (a.lastname < b.lastname) { return -1; }
              if (a.lastname > b.lastname) { return 1; }
              return 0;
            });
          }, () => {
            this.alertService.warn('Une erreur est survenue lors de la récupération des utilisateurs');
          }
        );
      }, () => {
        this.alertService.warn('Une erreur est survenue lors de la récupération de l\'utilisateur actuel');
      }
    );
  }

  validateOptionalEmail(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      if (!input) {
        return null;
      }
      return (Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'))(control);
    };
  }

  validatePhone(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      if (!input) {
        return null;
      }
      const phoneNumber = parsePhoneNumberFromString(input);
      return (!phoneNumber || !phoneNumber.isValid()) ? {phone: {phone: false}} : null;
    };
  }
}
