<div class="d-flex justify-content-between">
  <h1 class="mat-h1" mat-dialog-title>Changer {{data.type === 'key' ? 'la clé' : 'le trousseau'}} {{data.name}}</h1>
  <button mat-icon-button type="button" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
</div>
<div mat-dialog-content>
  <p>Veuillez sélectionner un contact et une date de rappel (optionnelle) pour indiquer que {{data.type === 'key' ? 'la clé' : 'le trousseau'}} {{data.name}} est {{data.type === 'key' ? 'sortie' : 'sorti'}}.</p>
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col-6">
        <mat-form-field class="full-width">
          <mat-label>Contact</mat-label>
          <mat-select formControlName="contact" [required]="true" #contact>
            <mat-option>-- Aucun --</mat-option>
            <mat-option (click)="newContact()">-- Nouveau --</mat-option>
            <mat-option *ngFor="let contact of contacts" [value]="contact">
              {{contact.name}}{{contact.office ? ' (' + contact.office + ')' : ''}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="full-width">
          <mat-label>Rappel</mat-label>
          <input matInput [min]="minDate" formControlName="reminder" [matDatepicker]="picker" #datePicker>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button color="warn" (click)="onDiscard()">Annuler</button>
  <button mat-button color="primary" [disabled]="!formGroup.valid" (click)="onValidate()" cdkFocusInitial>Valider</button>
</div>
