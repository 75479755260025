import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ValidationDialogData} from '../../models/validation-dialog-data';

@Component({
  selector: 'app-validation-dialog',
  templateUrl: './validation-dialog.component.html',
  styleUrls: ['./validation-dialog.component.scss']
})
export class ValidationDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ValidationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ValidationDialogData
  ) { }

  ngOnInit(): void {
  }

  onValidate(): void {
    this.data.onValidate();
    this.dialogRef.close();
  }

  onDiscard(): void {
    this.data.onDiscard();
    this.dialogRef.close();
  }
}
