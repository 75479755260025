import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-auth-image',
  templateUrl: './auth-image.component.html',
  styleUrls: ['./auth-image.component.scss']
})
export class AuthImageComponent implements OnInit {
  @Input() id: string;
  @Input() alt = '';

  constructor() { }

  ngOnInit(): void {
  }

}
