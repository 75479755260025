import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GenericHistoryEntry} from '../../../../shared/models/api/history/generic-history-entry';
import {GenericKey} from '../../../../shared/models/api/keys/generic-key';
import {GenericAgency} from '../../../../shared/models/api/agencies/generic-agency';
import {ValidationDialogData} from '../../../../shared/models/validation-dialog-data';
import {PostHistoryPayload} from '../../../../shared/models/api/history/post-history-payload';
import {ValidationDialogComponent} from '../../../../shared/components/validation-dialog/validation-dialog.component';
import {
  UpdateStateAwayDialogComponent,
  UpdateStateAwayDialogData
} from '../update-state-away-dialog/update-state-away-dialog.component';
import {ApiService} from '../../../../core/services/api/api.service';
import {AlertService} from '../../../../core/services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-key-infos',
  templateUrl: './key-infos.component.html',
  styleUrls: ['./key-infos.component.scss']
})
export class KeyInfosComponent implements OnInit {
  @Input() key: GenericKey;
  @Input() state: GenericHistoryEntry;
  @Input() agency: GenericAgency;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onKeyStateUpdated = new EventEmitter<GenericHistoryEntry>();

  isOpen = false;

  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    public validationDialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  getHumanReadableDate(reminder: string): string {
    const date = new Date(reminder);
    return date.toLocaleDateString('fr-FR');
  }

  onOpen(): void {
    this.isOpen = true;
  }

  onClose(): void {
    this.isOpen = false;
  }

  onReturnedButtonClick(): void {
    const data: ValidationDialogData = {
      title: `Changer la clé ${this.key.name}`,
      text: `Êtes vous sûr de vouloir indiquer que la clé ${this.key.name} est rentrée ?`,
      onValidate: () => {
        const payload: PostHistoryPayload = {
          type: 'key',
          relatedId: this.key.id,
          state: 'returned',
          propertyId: this.key.property.id
        };
        this.apiService.postHistory(payload).subscribe(
          (entry) => {
            this.onKeyStateUpdated.emit(entry);
            this.alertService.success('Le changement a bien été effectué');
          }, () => {
            this.alertService.error('Une erreur est survenue lors du changement d\'état, veuillez réessayer');
          }
        );
      },
      onDiscard: () => {}
    };
    this.validationDialog.open(ValidationDialogComponent, {
      data
    });
  }

  onAwayButtonClick(): void {
    const data: UpdateStateAwayDialogData = {
      type: 'key',
      propertyId: this.key.property.id,
      relatedId: this.key.id,
      name: this.key.name,
      agencyId: this.agency.id
    };
    const dialogRef = this.validationDialog.open(UpdateStateAwayDialogComponent, {
      data
    });
    dialogRef.afterClosed().subscribe((entry) => {
      if (entry) {
        this.onKeyStateUpdated.emit(entry);
      }
    });
  }

  onLostButtonClick(): void {
    const data: ValidationDialogData = {
      title: `Changer la clé ${this.key.name}`,
      text: `Êtes vous sûr de vouloir indiquer que la clé ${this.key.name} est perdue ?`,
      onValidate: () => {
        const payload: PostHistoryPayload = {
          type: 'key',
          relatedId: this.key.id,
          state: 'lost',
          propertyId: this.key.property.id
        };
        this.apiService.postHistory(payload).subscribe(
          (entry) => {
            this.onKeyStateUpdated.emit(entry);
            this.alertService.success('Le changement a bien été effectué');
          }, () => {
            this.alertService.error('Une erreur est survenue lors du changement d\'état, veuillez réessayer');
          }
        );
      },
      onDiscard: () => {}
    };
    this.validationDialog.open(ValidationDialogComponent, {
      data
    });
  }
}
