<div class="d-flex justify-content-between">
  <h1 class="mat-h1" mat-dialog-title>Historique de {{data.property.type === 'trustee' ? data.property.name : data.property.ownerName}} ({{data.property.internalCode}})</h1>
  <button mat-icon-button type="button" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
</div>
<mat-dialog-content>
  <table mat-table [dataSource]="dataSource" class="full-width" matSort>
    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
      <td mat-cell *matCellDef="let row">{{row.type}}</td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
      <td mat-cell *matCellDef="let row">{{row.name}}</td>
    </ng-container>
    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
      <td mat-cell *matCellDef="let row">{{computeDateDisplay(row.date)}}</td>
    </ng-container>
    <!-- State Column -->
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> État </th>
      <td mat-cell *matCellDef="let row">{{row.state}}</td>
    </ng-container>
    <!-- Contact Column -->
    <ng-container matColumnDef="contact">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Contact </th>
      <td mat-cell *matCellDef="let row">{{row.contact}}</td>
    </ng-container>
    <!-- Origin Column -->
    <ng-container matColumnDef="origin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Origine </th>
      <td mat-cell *matCellDef="let row">{{row.origin}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</mat-dialog-content>
