import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../../core/services/api/api.service';
import {AuthenticationService} from '../../../../core/services/authentication/authentication.service';
import {AlertService} from '../../../../core/services/alert/alert.service';
import {ResetPasswordResetPayload} from '../../../../shared/models/api/auth/reset-password-reset-payload';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  private user: string = null;
  private token: string = null;
  passwordForm: FormGroup;
  hide = true;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private authService: AuthenticationService,
    private alertService: AlertService,
  ) {
    this.passwordForm = fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      passwordValidation: ['', [Validators.required, this.passwordMatch('password')]],
    });
    this.passwordForm.controls.password.valueChanges.subscribe(() => {
      setTimeout(() => {
        this.passwordForm.controls.passwordValidation.updateValueAndValidity();
      });
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.user = params.user;
      this.token = params.token;
    });
  }

  onSubmit(): void {
    this.submitted = true;

    this.authService.signOut();
    const payload: ResetPasswordResetPayload = {
      email: this.user,
      token: this.token
    };
    this.apiService.authResetPasswordReset(payload).subscribe(
      (response) => {
        this.authService.signIn(response.access_token, null);
        this.apiService.getUserMe().subscribe((user) => {
          this.apiService.patchUser(user.id, {password: this.passwordForm.get('password').value}).subscribe((updatedUser) => {
            this.authService.signOut();
            this.router.navigate(['/login']);
            this.alertService.success('Le mot de passe à bien été mis à jour!');
          }, (updateError) => {
            this.alertService.error(`${updateError.name}: ${updateError.message}`);
          });
        });
      }, (error) => {
        this.router.navigate(['/forgetPassword']);
        this.alertService.error('Token invalide, le lien à peut être expiré veuillez en générer un autre');
      }
    );
  }

  passwordMatch(fieldName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      const passwordMatch = control.root.value[fieldName] !== input;
      return passwordMatch ? {passwordMatch: {passwordMatch}} : null;
    };
  }

}
