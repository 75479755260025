import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import {PostUserPayload} from '../../../../shared/models/api/users/post-user-payload';
import {ApiService} from '../../../../core/services/api/api.service';
import {AlertService} from '../../../../core/services/alert/alert.service';

@Component({
  selector: 'app-create-user-dialog',
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.scss']
})
export class CreateUserDialogComponent implements OnInit {
  formGroup: FormGroup;

  constructor(
    private alertService: AlertService,
    private apiService: ApiService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {type: 'admin' | 'manager'}
  ) {
    this.formGroup = fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      phone: ['', this.validatePhone()],
      managerType: ['manager']
    });
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.formGroup.updateValueAndValidity();
    if (!this.formGroup.valid) {
      return;
    }
    const payload: PostUserPayload = {
      email: this.formGroup.get('email').value,
      firstname: this.formGroup.get('firstname').value,
      lastname: this.formGroup.get('lastname').value,
      phone: this.formGroup.get('phone').value || null,
      managerType: this.formGroup.get('managerType').value || null
    };
    this.apiService.postUser(payload).subscribe(
      (user) => {
        this.alertService.success('Utilisateur créé');
        this.dialogRef.close(user);
      }, () => {
        this.alertService.error('Une erreur est survenue lors de la création de l\'utilisateur');
        this.dialogRef.close(null);
      }
    );
  }

  validatePhone(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      if (!input) {
        return null;
      }
      const phoneNumber = parsePhoneNumberFromString(input);
      return (!phoneNumber || !phoneNumber.isValid()) ? {phone: {phone: false}} : null;
    };
  }
}
