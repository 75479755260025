<div class="d-flex justify-content-between">
  <h1 class="mat-h1" mat-dialog-title>Modifier {{data.property.type === 'trustee' ? data.property.name : data.property.ownerName}} ({{data.property.internalCode}})</h1>
  <button mat-icon-button type="button" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
</div>
<div>
  <mat-tab-group>
    <mat-tab label="Fiche">
      <mat-dialog-content style="margin: 0">
        <form [formGroup]="propertyFormGroup" (ngSubmit)="onPropertyFormGroupSubmit()">
          <!--
          <div class="row">
            <div class="col-12">
              <h5 class="mat-h5">Fiche</h5>
            </div>
          </div>
          -->
          <div class="row" *ngIf="data.property.type === 'management'">
            <div class="col-6">
              <mat-form-field class="form-field full-width">
                <mat-label>Nom de la gérance</mat-label>
                <input matInput placeholder="GÉRANCE NOM PRÉNOM ou SCI, INDIV..."
                       type="text"
                       id="ownerName"
                       formControlName="ownerName"
                       required
                />
                <mat-hint>Le nom du propriétaire du bien</mat-hint>
                <mat-error *ngIf="propertyFormGroup.controls.ownerName.errors && propertyFormGroup.controls.ownerName.errors.required">
                  Le nom du propriétaire est requis
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="form-field full-width">
                <mat-label>Code interne</mat-label>
                <input matInput placeholder="Un code interne"
                       type="text"
                       id="internalCode"
                       formControlName="internalCode"
                       required
                />
                <mat-hint>Le code du bien</mat-hint>
                <mat-error *ngIf="propertyFormGroup.controls.internalCode.errors && propertyFormGroup.controls.internalCode.errors.required">
                  Le code interne est requis
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="form-field full-width">
                <mat-label>Email propriétaire</mat-label>
                <input matInput placeholder="mail@exemple.fr"
                       type="text"
                       id="ownerEmail"
                       formControlName="ownerEmail"
                />
                <mat-hint>L'email du propriétaire du bien</mat-hint>
                <mat-error *ngIf="propertyFormGroup.controls.ownerEmail.errors && propertyFormGroup.controls.ownerEmail.errors.pattern">
                  L'adresse email indiqué n'est pas valide
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="form-field full-width">
                <mat-label>Téléphone propriétaire</mat-label>
                <input matInput placeholder="+33 123456789"
                       type="text"
                       id="ownerPhone"
                       formControlName="ownerPhone"
                />
                <mat-hint>Le téléphone du propriétaire du bien (avec numéro international)</mat-hint>
                <mat-error *ngIf="propertyFormGroup.controls.ownerPhone.errors && propertyFormGroup.controls.ownerPhone.errors.phone">
                  Le numéro de téléphone n'est pas valide (avez vous bien indiqué le numéro international ?)
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="data.property.type === 'trustee'">
            <div class="col-4">
              <mat-form-field class="form-field full-width">
                <mat-label>Image</mat-label>
                <ngx-mat-file-input #removableInput id="image" formControlName="image" placeholder="Image du bien" [accept]="'.png,.jpg,.jpeg'" (change)="onFileChange($event.target.files)"></ngx-mat-file-input>
                <button mat-icon-button matSuffix *ngIf="!removableInput.empty" (click)="removableInput.clear($event)">
                  <mat-icon>clear</mat-icon>
                </button>
                <mat-hint>Une image du bien (laisser vide pour ne pas changer)</mat-hint>
                <mat-icon matSuffix>folder</mat-icon>
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-form-field class="form-field full-width">
                <mat-label>Nom</mat-label>
                <input matInput placeholder="Nom de la résidence"
                       type="text"
                       id="name"
                       formControlName="name"
                       required
                />
                <mat-hint>Le nom de la résidence</mat-hint>
                <mat-error *ngIf="propertyFormGroup.controls.name.errors && propertyFormGroup.controls.name.errors.required">
                  Le nom de la résidence est requis
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-form-field class="form-field full-width">
                <mat-label>Code interne</mat-label>
                <input matInput placeholder="Un code interne"
                       type="text"
                       id="internalCode"
                       formControlName="internalCode"
                       required
                />
                <mat-hint>Le code du bien</mat-hint>
                <mat-error *ngIf="propertyFormGroup.controls.internalCode.errors && propertyFormGroup.controls.internalCode.errors.required">
                  Le code interne est requis
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <!--
          <div class="row">
            <div class="col-12">
              <h5 class="mat-h5">Adresse</h5>
            </div>
          </div>
          -->
          <div class="horizontal-spacer"></div>
          <div class="horizontal-spacer"></div>
          <div class="row">
            <div class="col-4">
              <mat-form-field class="form-field full-width">
                <mat-label>Numéro</mat-label>
                <input matInput placeholder="17 bis"
                       type="text"
                       id="streetNumber"
                       formControlName="streetNumber"
                />
              </mat-form-field>
            </div>
            <div class="col-8">
              <mat-form-field class="form-field full-width">
                <mat-label>Rue</mat-label>
                <input matInput placeholder="rue de l'exemple"
                       type="text"
                       id="street"
                       formControlName="street"
                       [required]="true"
                />
                <mat-error *ngIf="propertyFormGroup.controls.street.errors && propertyFormGroup.controls.street.errors.required">
                  La rue est requise
                </mat-error>
              </mat-form-field>
            </div>

            <div [class]="data.property.type === 'trustee' ? 'col-3' : 'col-4'">
              <mat-form-field class="form-field full-width">
                <mat-label>Code postal</mat-label>
                <input matInput placeholder="37000"
                       type="text"
                       id="zipcode"
                       formControlName="zipcode"
                       [required]="true"
                />
                <mat-error *ngIf="propertyFormGroup.controls.zipcode.errors && propertyFormGroup.controls.zipcode.errors.required">
                  Le code postal est requis
                </mat-error>
                <mat-error *ngIf="propertyFormGroup.controls.zipcode.errors && propertyFormGroup.controls.zipcode.errors.pattern">
                  Le code postal indiqué n'est pas valide
                </mat-error>
              </mat-form-field>
            </div>
            <div [class]="data.property.type === 'trustee' ? 'col-3' : 'col-4'">
              <mat-form-field class="form-field full-width">
                <mat-label>Ville</mat-label>
                <input matInput placeholder="Tours"
                       type="text"
                       id="city"
                       formControlName="city"
                       [required]="true"
                />
                <mat-error *ngIf="propertyFormGroup.controls.city.errors && propertyFormGroup.controls.city.errors.required">
                  La ville est requise
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3" *ngIf="data.property.type === 'trustee'">
              <mat-form-field class="form-field full-width">
                <mat-label>Bâtiment</mat-label>
                <input matInput
                       type="text"
                       id="building"
                       formControlName="building"
                />
              </mat-form-field>
            </div>
            <div [class]="data.property.type === 'trustee' ? 'col-3' : 'col-4'">
              <mat-form-field class="form-field full-width">
                <mat-label>Digicode</mat-label>
                <input matInput
                       type="text"
                       id="digitalCode"
                       formControlName="digitalCode"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="horizontal-spacer" *ngIf="data.property.type === 'management'"></div>
          <div class="row" *ngIf="data.property.type === 'management'">
            <div class="col-4">
              <mat-form-field class="form-field full-width">
                <mat-label>Lot N°</mat-label>
                <input matInput
                       type="text"
                       id="batchNumber"
                       formControlName="batchNumber"
                />
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-form-field class="form-field full-width">
                <mat-label>Étage</mat-label>
                <input matInput
                       type="text"
                       id="store"
                       formControlName="store"
                />
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-form-field class="form-field full-width">
                <mat-label>Porte</mat-label>
                <input matInput
                       type="text"
                       id="door"
                       formControlName="door"
                />
              </mat-form-field>
            </div>
          </div>
          <!--
          <div class="row">
            <div class="col-12">
              <div class="horizontal-spacer"></div>
              <h5 class="mat-h5">Contacts</h5>
            </div>
          </div>
          -->
          <div class="horizontal-spacer"></div>
          <div class="row">
            <div class="col-6">
              <mat-form-field class="form-field full-width">
                <mat-label>Gestionnaire</mat-label>
                <mat-select formControlName="manager" required>
                  <mat-option *ngFor="let user of users" [value]="user">
                    {{user.lastname}} {{user.firstname}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="form-field full-width">
                <mat-label>Assistant</mat-label>
                <mat-select formControlName="assistant">
                  <mat-option>-- Aucun --</mat-option>
                  <mat-option *ngFor="let user of users" [value]="user">
                    {{user.lastname}} {{user.firstname}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button mat-button class="col-6" color="primary" type="submit" [disabled]="!propertyFormGroup.valid">Mettre à jour</button>
              <button mat-button class="col-6" color="warn" type="button" (click)="deleteProperty()">supprimer la fiche</button>
            </div>
          </div>
        </form>
      </mat-dialog-content>
    </mat-tab>

    <mat-tab label="Contacts">
      <div class="row" style="margin: 0">
        <div class="col-12">
          <div class="horizontal-spacer"></div>
          <button mat-raised-button color="primary" class="full-width" (click)="newContact()">Créer un contact</button>
          <div class="horizontal-spacer"></div>
          <mat-divider></mat-divider>
          <div class="horizontal-spacer"></div>
          <div class="contacts-list">
            <mat-dialog-content style="margin: 0">
              <div class="key-list-row full-width" [class.modified]="contact.name !== inputName.value || contact.email !== inputEmail.value || (contact.office !== inputOffice.value && (contact.office || inputOffice.value)) || (contact.phone !== inputPhone.value && (contact.phone || inputPhone.value))" *ngFor="let contact of data.property.syndicateContacts">
                <form [formGroup]="contactFormGroup" (ngSubmit)="onSubmitContactUpdate(contact.id)" class="full-width">
                  <div class="row ma-0 full-width">
                    <div class="col-3 d-flex justify-content-start ma-auto">
                      <mat-form-field class="form-field full-width">
                        <mat-label>Nom</mat-label>
                        <input matInput
                               type="text"
                               formControlName="nameContactGroupId{{contact.id}}"
                               [required]="true"
                               #inputName
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-3 d-flex justify-content-start ma-auto">
                      <mat-form-field class="form-field full-width">
                        <mat-label>Email</mat-label>
                        <input matInput
                               type="text"
                               formControlName="emailContactGroupId{{contact.id}}"
                               [required]="true"
                               #inputEmail
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-2 d-flex justify-content-start ma-auto">
                      <mat-form-field class="form-field full-width">
                        <mat-label>Fonction</mat-label>
                        <input matInput
                               type="text"
                               formControlName="officeContactGroupId{{contact.id}}"
                               #inputOffice
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-2 d-flex justify-content-start ma-auto">
                      <mat-form-field class="form-field full-width">
                        <mat-label>Téléphone</mat-label>
                        <input matInput
                               type="text"
                               formControlName="phoneContactGroupId{{contact.id}}"
                               #inputPhone
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-1 d-flex justify-content-end">
                      <button class="ma-auto" mat-icon-button type="submit" [disabled]="contact.name === inputName.value && contact.email === inputEmail.value && (contact.office === inputOffice.value || (!contact.office && !inputOffice.value)) && (contact.phone === inputPhone.value || (!contact.phone && !inputPhone.value))">
                        <mat-icon class="ma-auto" color="primary">save</mat-icon>
                      </button>
                    </div>
                    <div class="col-1 d-flex justify-content-end">
                      <button class="ma-auto" mat-icon-button type="button" (click)="onDeleteContact(contact.id)">
                        <mat-icon class="ma-auto" color="warn">delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </mat-dialog-content>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Locataires" *ngIf="data.property.type === 'management'">
      <div class="row" style="margin: 0">
        <div class="col-12">
          <div class="horizontal-spacer"></div>
          <button mat-raised-button color="primary" class="full-width" (click)="newTenant()">Créer un locataire</button>
          <div class="horizontal-spacer"></div>
          <mat-divider></mat-divider>
          <div class="horizontal-spacer"></div>
          <div class="contacts-list">
            <mat-dialog-content style="margin: 0">
              <div class="key-list-row full-width" [class.modified]="tenant.name !== inputName.value || tenant.email !== inputEmail.value || (tenant.phone !== inputPhone.value && (tenant.phone || inputPhone.value))" *ngFor="let tenant of data.property.tenants">
                <form [formGroup]="tenantFormGroup" (ngSubmit)="onSubmitTenantUpdate(tenant.id)" class="full-width">
                  <div class="row ma-0 full-width">
                    <div class="col-4 d-flex justify-content-start ma-auto">
                      <mat-form-field class="form-field full-width">
                        <mat-label>Nom</mat-label>
                        <input matInput
                               type="text"
                               formControlName="nameTenantGroupId{{tenant.id}}"
                               [required]="true"
                               #inputName
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-3 d-flex justify-content-start ma-auto">
                      <mat-form-field class="form-field full-width">
                        <mat-label>Email</mat-label>
                        <input matInput
                               type="text"
                               formControlName="emailTenantGroupId{{tenant.id}}"
                               [required]="true"
                               #inputEmail
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-3 d-flex justify-content-start ma-auto">
                      <mat-form-field class="form-field full-width">
                        <mat-label>Téléphone</mat-label>
                        <input matInput
                               type="text"
                               formControlName="phoneTenantGroupId{{tenant.id}}"
                               #inputPhone
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-1 d-flex justify-content-end">
                      <button class="ma-auto" mat-icon-button type="submit" [disabled]="tenant.name === inputName.value && tenant.email === inputEmail.value && (tenant.phone === inputPhone.value || (!tenant.phone && !inputPhone.value))">
                        <mat-icon class="ma-auto" color="primary">save</mat-icon>
                      </button>
                    </div>
                    <div class="col-1 d-flex justify-content-end">
                      <button class="ma-auto" mat-icon-button type="button" (click)="onDeleteTenant(tenant.id)">
                        <mat-icon class="ma-auto" color="warn">delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </mat-dialog-content>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Clés">
      <div class="row" style="margin: 0">
        <div class="col-12">

          <div class="horizontal-spacer"></div>

          <div class="full-width">
            <form [formGroup]="keyCreateFormGroup" (ngSubmit)="onSubmitKeyCreate()" class="full-width">
              <div class="row ma-0 full-width">
                <div class="col-2 d-flex justify-content-start ma-auto">
                  <mat-form-field class="form-field full-width">
                    <mat-label>Repère</mat-label>
                    <input matInput
                           type="text"
                           formControlName="tag"
                           [required]="true"
                    />
                  </mat-form-field>
                </div>
                <div class="col-3 d-flex justify-content-start ma-auto">
                  <mat-form-field class="form-field full-width">
                    <mat-label>Nom</mat-label>
                    <input matInput
                           type="text"
                           formControlName="name"
                           [required]="true"
                    />
                  </mat-form-field>
                </div>
                <div class="col-3 d-flex justify-content-start ma-auto">
                  <mat-form-field class="form-field full-width">
                    <mat-label>Description</mat-label>
                    <input matInput
                           type="text"
                           formControlName="description"
                           [required]="true"
                    />
                  </mat-form-field>
                </div>
                <div class="col-2 d-flex justify-content-center">
                  <mat-form-field class="form-field full-width">
                    <mat-label>Trousseau</mat-label>
                    <mat-select formControlName="keyring">
                      <mat-option>-- Aucun --</mat-option>
                      <mat-option *ngFor="let keyring of keyrings" [value]="keyring">
                        {{keyring.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-2 d-flex justify-content-end">
                  <button class="ma-auto" mat-raised-button type="submit" color="primary">
                    créer
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="horizontal-spacer"></div>
          <mat-divider></mat-divider>
          <div class="horizontal-spacer"></div>

          <div class="key-list">
            <mat-dialog-content style="margin: 0">
              <div class="key-list-row full-width" [class.modified]="key.tag !== inputTag.value || key.name !== inputName.value || key.description !== inputDescription.value || key.keyring?.id !== inputKeyring.value?.id" *ngFor="let key of keys">
                <form [formGroup]="keyFormGroup" (ngSubmit)="onSubmitKeyUpdate(key.id)" class="full-width">
                  <div class="row ma-0 full-width">
                    <div class="col-2 d-flex justify-content-start ma-auto">
                      <mat-form-field class="form-field full-width">
                        <mat-label>Repère</mat-label>
                        <input matInput
                               type="text"
                               formControlName="tagKeyGroupId{{key.id}}"
                               [required]="true"
                               #inputTag
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-3 d-flex justify-content-start ma-auto">
                      <mat-form-field class="form-field full-width">
                        <mat-label>Nom</mat-label>
                        <input matInput
                               type="text"
                               formControlName="nameKeyGroupId{{key.id}}"
                               [required]="true"
                               #inputName
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-3 d-flex justify-content-start ma-auto">
                      <mat-form-field class="form-field full-width">
                        <mat-label>Description</mat-label>
                        <input matInput
                               type="text"
                               formControlName="descriptionKeyGroupId{{key.id}}"
                               [required]="true"
                               #inputDescription
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-2 d-flex justify-content-center">
                      <mat-form-field class="form-field full-width">
                        <mat-label>Trousseau</mat-label>
                        <mat-select formControlName="keyringKeyGroupId{{key.id}}" #inputKeyring>
                          <mat-option>-- Aucun --</mat-option>
                          <mat-option *ngFor="let keyring of keyrings" [value]="keyring">
                            {{keyring.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-1 d-flex justify-content-end">
                      <button class="ma-auto" mat-icon-button type="submit" [disabled]="key.tag === inputTag.value && key.name === inputName.value && key.description === inputDescription.value && key.keyring?.id === inputKeyring.value?.id">
                        <mat-icon class="ma-auto" color="primary">save</mat-icon>
                      </button>
                    </div>
                    <div class="col-1 d-flex justify-content-end">
                      <button class="ma-auto" mat-icon-button type="button" (click)="onDeleteKey(key.id)">
                        <mat-icon class="ma-auto" color="warn">delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </mat-dialog-content>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Trousseaux">
      <div class="row" style="margin: 0">
        <div class="col-12">

          <div class="horizontal-spacer"></div>

          <div class="full-width">
            <form [formGroup]="keyringCreateFormGroup" (ngSubmit)="onSubmitKeyringCreate()" class="full-width">
              <div class="row ma-0 full-width">
                <div class="col-4 d-flex justify-content-start ma-auto">
                  <mat-form-field class="form-field full-width">
                    <mat-label>Nom</mat-label>
                    <input matInput
                           type="text"
                           formControlName="name"
                           [required]="true"
                    />
                  </mat-form-field>
                </div>
                <div class="col-6 d-flex justify-content-center">
                  <mat-form-field class="form-field full-width">
                    <mat-label>Clés</mat-label>
                    <mat-select formControlName="keys" multiple>
                      <mat-option *ngFor="let key of keys" [value]="key">
                        {{key.name}} ({{key.tag}})
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-2 d-flex justify-content-end">
                  <button class="ma-auto" mat-raised-button type="submit" color="primary">
                    créer
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="horizontal-spacer"></div>
          <mat-divider></mat-divider>
          <div class="horizontal-spacer"></div>

          <div class="key-list">
            <mat-dialog-content style="margin: 0">
              <div class="key-list-row full-width" [class.modified]="keyring.name !== inputName.value || !isKeylistEqual(keyring.keys, inputKeys.value)" *ngFor="let keyring of keyrings">
                <form [formGroup]="keyringFormGroup" (ngSubmit)="onSubmitKeyringUpdate(keyring.id)" class="full-width">
                  <div class="row ma-0 full-width">
                    <div class="col-4 d-flex justify-content-start ma-auto">
                      <mat-form-field class="form-field full-width">
                        <mat-label>Nom</mat-label>
                        <input matInput
                               type="text"
                               formControlName="nameKeyringGroupId{{keyring.id}}"
                               [required]="true"
                               #inputName
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-6 d-flex justify-content-center">
                      <mat-form-field class="form-field full-width">
                        <mat-label>Clés</mat-label>
                        <mat-select formControlName="keysKeyringGroupId{{keyring.id}}" #inputKeys multiple>
                          <mat-option *ngFor="let key of keys" [value]="key">
                            {{key.name}} ({{key.tag}})
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-1 d-flex justify-content-end">
                      <button class="ma-auto" mat-icon-button type="submit" [disabled]="keyring.name === inputName.value && isKeylistEqual(keyring.keys, inputKeys.value)">
                        <mat-icon class="ma-auto" color="primary">save</mat-icon>
                      </button>
                    </div>
                    <div class="col-1 d-flex justify-content-end">
                      <button class="ma-auto" mat-icon-button type="button" (click)="onDeleteKeyring(keyring.id)">
                        <mat-icon class="ma-auto" color="warn">delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </mat-dialog-content>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
