import {Pipe, PipeTransform} from '@angular/core';
import {ApiService} from '../../core/services/api/api.service';

@Pipe({
  name: 'authImage'
})
export class AuthImagePipe implements PipeTransform {

  constructor(
    private apiService: ApiService
  ) {}

  async transform(id: string): Promise<string> {
    try {
      const numberId = parseInt(id, 10);
      if (Number.isNaN(numberId)) {
        return 'assets/fallback.png';
      }
      const imageBlob = await this.apiService.downloadImage(numberId).toPromise();
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(imageBlob);
      });
    } catch {
      return 'assets/fallback.png';
    }
  }
}
