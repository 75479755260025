import { Component, OnInit } from '@angular/core';
import {GenericProperty} from '../../../../shared/models/api/properties/generic-property';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../../core/services/api/api.service';
import {AlertService} from '../../../../core/services/alert/alert.service';
import {GenericKeyring} from '../../../../shared/models/api/keyrings/generic-keyring';
import {GenericKey} from '../../../../shared/models/api/keys/generic-key';
import {GenericHistoryEntry} from '../../../../shared/models/api/history/generic-history-entry';
import {MatDialog} from '@angular/material/dialog';
import {DisplayHistoryDialogComponent} from '../display-history-dialog/display-history-dialog.component';
import {DisplayHistoryDialogData} from '../../../../shared/models/properties/display-history-dialog-data';
import {UpdateKeyStatesDialogData} from '../../../../shared/models/properties/update-key-states-dialog-data';
import {UpdateKeyStatesDialogComponent} from '../update-key-states-dialog/update-key-states-dialog.component';
import {ValidationDialogComponent} from '../../../../shared/components/validation-dialog/validation-dialog.component';
import {ValidationDialogData} from '../../../../shared/models/validation-dialog-data';
import {UpdatePropertyDialogComponent} from '../update-property-dialog/update-property-dialog.component';
import {UpdatePropertyDialogData} from '../../../../shared/models/properties/update-property-dialog-data';
import {ContactsDisplayDialogData} from '../../../../shared/models/contacts/contacts-display-dialog-data';
import {ContactsDisplayDialogComponent} from '../../../../shared/components/contacts-display-dialog/contacts-display-dialog.component';
import {TenantsDisplayDialogData} from '../../../../shared/models/tenants/tenants-display-dialog-data';
import {TenantsDisplayDialogComponent} from '../../../../shared/components/tenants-display-dialog/tenants-display-dialog.component';
import {jsPDF} from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-property-infos',
  templateUrl: './property-infos.component.html',
  styleUrls: ['./property-infos.component.scss']
})
export class PropertyInfosComponent implements OnInit {
  id: number;
  property: GenericProperty;
  keyrings: GenericKeyring[] = [];
  keys: GenericKey[] = [];
  canManageProperty = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
    public historyDisplayDialog: MatDialog,
    public contactsDisplayDialog: MatDialog,
    public deletePropertyValidationDialog: MatDialog,
    public updatePropertyDialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.id = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.refreshProperty();
  }

  refreshProperty(): void {
    this.apiService.getUserMe().subscribe(
      (me) => {
        this.apiService.getProperty(this.id).subscribe(
          (property) => {
            this.property = property;
            this.keyrings = property.keyrings || [];
            this.keys = property.keys?.filter((key) => {
              return !key.keyring;
            }) || [];
            this.canManageProperty = (property.manager.id === me.id ||
              property.assistantManager?.id === me.id || me.type === 'admin' || me.type === 'agency_director');
          },
          () => {
            this.router.navigate(['/properties']);
            this.alertService.error(`La propriété n'a pas été trouvée ou vous n'avez pas l'autorisation d'y accéder`);
          }
        );
      },
      () => {
        this.router.navigate(['/properties']);
        this.alertService.error(`Une erreur est survenue lors de la récupération de l'utilisateur, veuillez réessayer`);
      }
    );
  }

  displayHistory(): void {
    const data: DisplayHistoryDialogData = {
      property: this.property
    };
    this.historyDisplayDialog.open(DisplayHistoryDialogComponent, {
      width: '90%',
      maxWidth: '90%',
      data
    });
  }

  deleteProperty(): void {
    const data: ValidationDialogData = {
      title: 'Supprimer la fiche ?',
      text: 'Êtes vous sûr de vouloir supprimer la fiche ? (toutes les clés, tous les trousseaux et l\'historique de la fiche seront aussi supprimés)',
      onValidate: () => {
        this.apiService.deleteProperty(this.id).subscribe(
          () => {
            this.alertService.success('La fiche a bien été supprimée', true);
            this.router.navigate(['/properties']);
          }, () => {
            this.alertService.error('Une erreur est survenue lors de la suppression de la fiche.');
          }
        );
      },
      onDiscard: () => {}
    };
    this.deletePropertyValidationDialog.open(ValidationDialogComponent, {
      data
    });
  }

  changeKeysState(): void {
    const data: UpdateKeyStatesDialogData = {
      property: this.property,
      keys: this.keys,
      keyrings: this.keyrings
    };
    const dialogRef = this.historyDisplayDialog.open(UpdateKeyStatesDialogComponent, {
      width: '90%',
      maxWidth: '90%',
      data
    });
    dialogRef.afterClosed().subscribe(() => {
      this.refreshProperty();
    });
  }

  updateProperty(): void {
    const data: UpdatePropertyDialogData = {
      property: this.property
    };
    const dialogRef = this.updatePropertyDialog.open(UpdatePropertyDialogComponent, {
      width: '90%',
      maxWidth: '90%',
      data
    });
    dialogRef.afterClosed().subscribe((property) => {
      if (property !== null) {
        this.refreshProperty();
      }
    });
  }

  capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  computeState(type: 'key' | 'keyring', id: number): GenericHistoryEntry {
    const relatedEntries = this.property.history.filter((entry) => {
      return entry.type === type && entry.relatedId === id;
    });
    relatedEntries.sort((a, b) => {
      return new Date(b.time).getTime() - new Date(a.time).getTime();
    });
    if (relatedEntries && relatedEntries.length) {
      return relatedEntries[0];
    }
    return {
      id: 0,
      type,
      relatedId: id,
      state: 'returned',
      contact: null,
      reminder: null,
      origin: null,
      property: this.property,
      time: new Date().toISOString(),
    };
  }

  displayContacts(): void {
    const data: ContactsDisplayDialogData = {
      contacts: this.property.syndicateContacts
    };
    const dialogRef = this.contactsDisplayDialog.open(ContactsDisplayDialogComponent, {
      width: '90%',
      maxWidth: '90%',
      data
    });
  }

  displayTenants(): void {
    const data: TenantsDisplayDialogData = {
      tenants: this.property.tenants
    };
    const dialogRef = this.contactsDisplayDialog.open(TenantsDisplayDialogComponent, {
      width: '90%',
      maxWidth: '90%',
      data
    });
  }

  displayPDF(): void {
    const propertyName = this.property.type === 'trustee' ? this.property.name : this.property.ownerName;
    const propertyAddress = `${this.property.address.streetNumber || ''} ${this.property.address.street || ''}, ${this.property.address.zipcode || ''} ${this.property.address.city || ''}`;
    let currentXOffset = 10;
    let currentYOffset = 20;

    const doc = new jsPDF();
    doc.setProperties({
      title: `${propertyName}`
    });
    doc.setFontSize(18);
    doc.text(propertyName, currentXOffset, currentYOffset);
    currentYOffset += 10;
    doc.setFontSize(14);
    doc.text(`Adresse: ${propertyAddress}`, currentXOffset, currentYOffset);
    currentYOffset += 20;

    if (this.keyrings && this.keyrings.length) {
      doc.setFontSize(14);
      doc.text('Trousseaux:', currentXOffset, currentYOffset);
      currentYOffset += 10;
      currentXOffset += 5;
      for (const keyring of this.keyrings) {
        doc.setFontSize(12);
        doc.text(`${keyring.name}`, currentXOffset, currentYOffset);
        currentYOffset += 5;
        const generatedKeys = [];
        for (const key of keyring.keys) {
          generatedKeys.push([key.tag, key.name, key.description]);
        }
        autoTable(doc, {
          head: [['Repère', 'Désignation', 'Description']],
          body: generatedKeys,
          startY: currentYOffset,
          theme: 'plain'
        });
        // @ts-ignore
        currentYOffset = doc.lastAutoTable.finalY + 14;
      }
      currentXOffset -= 5;
    }
    if (this.keys && this.keys.length) {
      doc.setFontSize(14);
      doc.text('Clés supplémentaires:', currentXOffset, currentYOffset);
      currentYOffset += 5;
      const generatedKeys = [];
      for (const key of this.keys) {
        generatedKeys.push([key.tag, key.name, key.description]);
      }
      autoTable(doc, {
        head: [['Repère', 'Désignation', 'Description']],
        body: generatedKeys,
        startY: currentYOffset,
        theme: 'plain'
      });
    }

    window.open(doc.output('bloburl').toString());
  }
}
