<div class="d-flex justify-content-between">
  <h1 class="mat-h1" mat-dialog-title>Locataires</h1>
  <button mat-icon-button type="button" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
</div>
<mat-dialog-content>
  <table mat-table [dataSource]="dataSource" class="full-width">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Nom </th>
      <td mat-cell *matCellDef="let row">{{row.name}}</td>
    </ng-container>
    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let row">{{row.email}}</td>
    </ng-container>
    <!-- Phone Column -->
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef> Téléphone </th>
      <td mat-cell *matCellDef="let row">{{row.phone}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-dialog-content>
