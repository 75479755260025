import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../../../core/services/authentication/authentication.service';
import {GenericUser} from '../../../../shared/models/api/users/generic-user';
import {ApiService} from '../../../../core/services/api/api.service';
import {AlertService} from '../../../../core/services/alert/alert.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  user?: GenericUser = undefined;

  constructor(
    private alertService: AlertService,
    private apiService: ApiService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    if (this.isAuth()) {
      this.refreshUser();
    }
  }

  refreshUser(): void {
    this.user = undefined;
    this.apiService.getUserMe().subscribe(
      (me) => {
        this.user = me;
      }, () => {
        this.alertService.error('Une erreur est survenue lors de la récupération de l\'utilisateur actuel');
      }
    );
  }

  isUserAdmin(): boolean {
    return this.user && this.user.type !== 'manager';
  }

  isAuth(): boolean {
    return this.authenticationService.isAuth();
  }
}
