import { Component, OnInit } from '@angular/core';
import {GenericAgency} from '../../../../shared/models/api/agencies/generic-agency';
import {ApiService} from '../../../../core/services/api/api.service';
import {AlertService} from '../../../../core/services/alert/alert.service';
import {GetPropertiesResponse} from '../../../../shared/models/api/properties/get-properties-response';

@Component({
  selector: 'app-agency-infos',
  templateUrl: './agency-infos.component.html',
  styleUrls: ['./agency-infos.component.scss']
})
export class AgencyInfosComponent implements OnInit {
  agency?: GenericAgency | null = undefined;
  nbFiles = '...';

  constructor(
    private apiService: ApiService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.refreshAgency();
  }

  refreshAgency(): void {
    this.apiService.getUserMe().subscribe(
      (me) => {
        if (!me.agency) {
          this.agency = null;
          return;
        }
        this.apiService.getAgency(me.agency.id).subscribe(
          (agency) => {
            this.agency = agency;
          }, () => {
            this.alertService.error('Une erreur est survenue lors de la récupération de l\'agence actuelle');
          }
        );
        this.apiService.getProperties().subscribe(
          (properties: GetPropertiesResponse) => {
            const myProperties = properties.filter((property) => {
              return property.manager.id === me.id || property.assistantManager?.id === me.id;
            });
            this.nbFiles = `${myProperties.length} / ${properties.length}`;
          }, () => {
            this.alertService.error('Une erreur est survenue lors de la récupération du nombre de fiches');
          }
        );
      }, () => {
        this.alertService.error('Une erreur est survenue lors de la récupération de l\'utilisateur actuel');
      }
    );
  }

}
