import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GenericKeyring} from '../../../../shared/models/api/keyrings/generic-keyring';
import {GenericHistoryEntry} from '../../../../shared/models/api/history/generic-history-entry';
import {ValidationDialogData} from '../../../../shared/models/validation-dialog-data';
import {ValidationDialogComponent} from '../../../../shared/components/validation-dialog/validation-dialog.component';
import {ApiService} from '../../../../core/services/api/api.service';
import {AlertService} from '../../../../core/services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {PostHistoryPayload} from '../../../../shared/models/api/history/post-history-payload';
import {
  UpdateStateAwayDialogComponent,
  UpdateStateAwayDialogData
} from '../update-state-away-dialog/update-state-away-dialog.component';
import {GenericAgency} from '../../../../shared/models/api/agencies/generic-agency';

@Component({
  selector: 'app-keyring-infos',
  templateUrl: './keyring-infos.component.html',
  styleUrls: ['./keyring-infos.component.scss']
})
export class KeyringInfosComponent implements OnInit {
  @Input() keyring: GenericKeyring;
  @Input() state: GenericHistoryEntry;
  @Input() agency: GenericAgency;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onKeyringStateUpdated = new EventEmitter<GenericHistoryEntry>();

  isOpen = false;

  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    public validationDialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  getHumanReadableDate(reminder: string): string {
    const date = new Date(reminder);
    return date.toLocaleDateString('fr-FR');
  }

  onOpen(): void {
    this.isOpen = true;
  }

  onClose(): void {
    this.isOpen = false;
  }

  onReturnedButtonClick(): void {
    const data: ValidationDialogData = {
      title: `Changer le trousseau ${this.keyring.name}`,
      text: `Êtes vous sûr de vouloir indiquer que le trousseau ${this.keyring.name} est rentré ?`,
      onValidate: () => {
        const payload: PostHistoryPayload = {
          type: 'keyring',
          relatedId: this.keyring.id,
          state: 'returned',
          propertyId: this.keyring.property.id
        };
        this.apiService.postHistory(payload).subscribe(
          (entry) => {
            this.onKeyringStateUpdated.emit(entry);
            this.alertService.success('Le changement a bien été effectué');
          }, () => {
            this.alertService.error('Une erreur est survenue lors du changement d\'état, veuillez réessayer');
          }
        );
      },
      onDiscard: () => {}
    };
    this.validationDialog.open(ValidationDialogComponent, {
      data
    });
  }

  onAwayButtonClick(): void {
    const data: UpdateStateAwayDialogData = {
      type: 'keyring',
      propertyId: this.keyring.property.id,
      relatedId: this.keyring.id,
      name: this.keyring.name,
      agencyId: this.agency.id
    };
    const dialogRef = this.validationDialog.open(UpdateStateAwayDialogComponent, {
      data
    });
    dialogRef.afterClosed().subscribe((entry) => {
      if (entry) {
        this.onKeyringStateUpdated.emit(entry);
      }
    });
  }

  onLostButtonClick(): void {
    const data: ValidationDialogData = {
      title: `Changer le trousseau ${this.keyring.name}`,
      text: `Êtes vous sûr de vouloir indiquer que le trousseau ${this.keyring.name} est perdu ?`,
      onValidate: () => {
        const payload: PostHistoryPayload = {
          type: 'keyring',
          relatedId: this.keyring.id,
          state: 'lost',
          propertyId: this.keyring.property.id
        };
        this.apiService.postHistory(payload).subscribe(
          (entry) => {
            this.onKeyringStateUpdated.emit(entry);
            this.alertService.success('Le changement a bien été effectué');
          }, () => {
            this.alertService.error('Une erreur est survenue lors du changement d\'état, veuillez réessayer');
          }
        );
      },
      onDiscard: () => {}
    };
    this.validationDialog.open(ValidationDialogComponent, {
      data
    });
  }
}
