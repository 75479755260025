import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {AuthenticationService} from '../services/authentication/authentication.service';
import {ApiService} from '../services/api/api.service';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {RefreshResponse} from '../../shared/models/api/auth/refresh-response';

const API_URL = environment.API_URL;

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthenticationService,
    private apiService: ApiService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.startsWith(API_URL)) {
      return next.handle(request);
    }

    const isAuthRequest = request.url.startsWith(`${API_URL}/auth/`);

    return next.handle(isAuthRequest ? request : this.addAuthenticationToken(request)).pipe(catchError((err) => {
      if (isAuthRequest) {
        this.authService.signOut();
        return throwError(err);
      }
      if (err.status !== 401) {
        return throwError(err);
      }
      if (this.refreshTokenInProgress) {
        return this.refreshTokenSubject
          .pipe(
            filter(result => result !== null),
            take(1),
            switchMap(() => next.handle(this.addAuthenticationToken(request)))
          );
      } else {
        this.refreshTokenInProgress = true;
        this.refreshTokenSubject.next(null);
        return this.apiService.authRefresh({token: this.getRefreshToken()})
          .pipe(
            catchError((error) => {
              this.refreshTokenInProgress = false;
              this.authService.signOut();
              return throwError(error);
            }),
            switchMap((token: RefreshResponse) => {
              this.authService.setAccessToken(token.access_token, this.getRefreshToken());
              this.refreshTokenInProgress = false;
              this.refreshTokenSubject.next(token.access_token);
              return next.handle(this.addAuthenticationToken(request));
            })
          );
      }
    }));
  }

  getRefreshToken(): string {
    return this.authService.getRefreshToken();
  }

  addAuthenticationToken(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const accessToken = this.authService.getAccessToken();
    if (accessToken) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    }
    return request;
  }
}
