import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TenantsDisplayDialogData} from '../../models/tenants/tenants-display-dialog-data';

interface TableRow {
  name: string;
  email: string;
  phone: string;
}

@Component({
  selector: 'app-tenants-display-dialog',
  templateUrl: './tenants-display-dialog.component.html',
  styleUrls: ['./tenants-display-dialog.component.scss']
})
export class TenantsDisplayDialogComponent implements OnInit {
  displayedColumns: string[] = ['name', 'email', 'phone'];
  dataSource: MatTableDataSource<TableRow>;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<TenantsDisplayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TenantsDisplayDialogData
  ) { }

  ngOnInit(): void {
    this.refreshDataSource();
  }

  refreshDataSource(): void {
    this.dataSource = new MatTableDataSource(this.data.tenants.map((tenant) => {
      return {
        name: tenant.name,
        email: tenant.email,
        phone: tenant.phone
      };
    }));
    this.dataSource.sort = this.sort;
  }
}
