<div class="container pa-15">
  <div class="row">
    <div class="col-12">
      <h1 class="mat-h1">Espace administrateur</h1>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="horizontal-spacer"></div>
  <div class="row">
    <div class="col-12">
      <app-users-list #usersListComponent></app-users-list>
    </div>
  </div>
  <div *ngIf="isAdmin()">
    <div class="horizontal-spacer"></div>
    <div class="row">
      <div class="col-12">
        <app-agencies-list (onAgencyUpdate)="usersListComponent.refreshUsers()"></app-agencies-list>
      </div>
    </div>
  </div>
</div>
