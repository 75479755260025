import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PropertiesRoutingModule } from './properties-routing.module';
import { PropertiesComponent } from './pages/properties/properties.component';
import {MatDividerModule} from '@angular/material/divider';
import { PropertiesListComponent } from './components/properties-list/properties-list.component';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {SharedModule} from '../../shared/shared.module';
import { CreatePropertyComponent } from './components/create-property/create-property.component';
import { PropertyComponent } from './pages/property/property.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PropertyInfosComponent } from './components/property-infos/property-infos.component';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { KeyringInfosComponent } from './components/keyring-infos/keyring-infos.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { KeyInfosComponent } from './components/key-infos/key-infos.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { DisplayHistoryDialogComponent } from './components/display-history-dialog/display-history-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { UpdateKeyStatesDialogComponent } from './components/update-key-states-dialog/update-key-states-dialog.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import { UpdatePropertyDialogComponent } from './components/update-property-dialog/update-property-dialog.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatListModule} from '@angular/material/list';
import { UpdateStateAwayDialogComponent } from './components/update-state-away-dialog/update-state-away-dialog.component';
import {CreatePropertyPageComponent} from './pages/create-property-page/create-property-page.component';


@NgModule({
  declarations: [
    PropertiesComponent,
    PropertiesListComponent,
    CreatePropertyComponent,
    PropertyComponent,
    PropertyInfosComponent,
    KeyringInfosComponent,
    KeyInfosComponent,
    DisplayHistoryDialogComponent,
    UpdateKeyStatesDialogComponent,
    UpdatePropertyDialogComponent,
    UpdateStateAwayDialogComponent,
    CreatePropertyPageComponent
  ],
  imports: [
    MatNativeDateModule,
    CommonModule,
    PropertiesRoutingModule,
    MatDividerModule,
    MatCardModule,
    MatTableModule,
    BrowserAnimationsModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    SharedModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatCheckboxModule,
    FormsModule,
    MatDialogModule,
    MatStepperModule,
    MatSelectModule,
    MatDatepickerModule,
    MaterialFileInputModule,
    MatTabsModule,
    MatListModule
  ]
})
export class PropertiesModule { }
