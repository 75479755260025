import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Alert } from '../../../shared/models/alert';
import { Router, NavigationStart } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    private subject: Subject<Alert>;
    private keepAfterRouteChange: boolean;

    constructor(private router: Router) {
        this.subject = new Subject<Alert>();
        this.keepAfterRouteChange = false;

        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    this.keepAfterRouteChange = false;
                } else {
                    this.clear();
                }
            }
        });
    }

    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    success(message: string, keepAfterRouteChange = false): void {
        this.alert('success', message, keepAfterRouteChange);
    }

    error(message: string, keepAfterRouteChange = false): void {
        this.alert('danger', message, keepAfterRouteChange);
    }

    info(message: string, keepAfterRouteChange = false): void {
        this.alert('info', message, keepAfterRouteChange);
    }

    warn(message: string, keepAfterRouteChange = false): void {
        this.alert('warning', message, keepAfterRouteChange);
    }

    alert(type: string, message: string, keepAfterRouteChange: boolean): void {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next({ type, message } as Alert);
    }

    clear(): void {
        this.subject.next();
    }
}
