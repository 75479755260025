import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../../core/services/api/api.service';
import {AlertService} from '../../../../core/services/alert/alert.service';
import {GenericUser} from '../../../../shared/models/api/users/generic-user';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {MatDialog} from '@angular/material/dialog';
import {CreateUserDialogComponent} from '../create-user-dialog/create-user-dialog.component';
import {MatSelectChange} from '@angular/material/select';

export function CustomPaginator(label: string): MatPaginatorIntl {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = label;

  return customPaginatorIntl;
}

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('Utilisateur par page: ')}
  ]
})
export class UsersListComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    public createUserDialog: MatDialog
  ) { }
  displayedColumns: string[] = ['firstname', 'lastname', 'email', 'phone', 'type', 'isActive'];
  user?: GenericUser;
  users: GenericUser[] = [];
  dataSource: MatTableDataSource<GenericUser>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  onlyActive = true;

  static filterPredicate(data: GenericUser, filterStr: string): boolean {
    if (!filterStr) {
      return true;
    }
    const filters = filterStr.split(';');
    const aggregatedString = `${data.agency?.name || ''}${data.firstname}${data.lastname}${data.email}${data.phone}${UsersListComponent.getDisplayType(data.type, data.managerType)}`.trim().toLowerCase();
    for (const filter of filters) {
      if (!aggregatedString.includes(filter)) {
        return false;
      }
    }
    return true;
  }

  static getDisplayType(type: 'admin' | 'manager' | 'agency_director', managerType?: 'manager' | 'assistant' | 'reception' | null): string {
    switch (type) {
      case 'agency_director': return 'Directeur d\'agence';
      case 'admin': return 'Administrateur';
      case 'manager':
        switch (managerType) {
          case 'manager': return 'Gestionnaire';
          case 'assistant': return 'Assistant';
          case 'reception': return 'Accueil';
          default: return 'Gestionnaire';
        }
      default: return 'Chargement...';
    }
  }

  ngOnInit(): void {
    this.refreshUser();
    this.refreshUsers();
  }

  refreshUser(): void {
    this.user = undefined;
    this.apiService.getUserMe().subscribe(
      (me) => {
        this.user = me;
        if (this.user.type === 'admin') {
          setTimeout(() => {
            this.displayedColumns = ['agency', 'firstname', 'lastname', 'email', 'phone', 'type', 'isActive'];
            this.refreshDataSource();
          });
        }
      }, () => {
        this.alertService.error('Une erreur est survenue lors de la récupération de l\'utilisateur actuel');
      }
    );
  }

  refreshDataSource(): void {
    const dataUsers = this.users?.filter((user) => {
      if (this.user && this.user.type === 'admin') {
        if (this.onlyActive) {
          return user.isActive && user.type !== 'manager';
        }
        return user.type !== 'manager';
      }
      if (this.onlyActive) {
        return user.isActive;
      }
      return true;
    });
    this.dataSource = new MatTableDataSource(dataUsers);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = UsersListComponent.filterPredicate;
  }

  refreshUsers(): void {
    this.users = [];
    this.apiService.getUsers().subscribe(
      (users) => {
        this.users = users;
        this.refreshDataSource();
      }, () => {
        this.alertService.error('Une erreur est survenue lors de la récupération des utilisateurs');
      }
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onCheckboxUpdated(event: Event): void {
    this.refreshDataSource();
  }

  updateUserActive(event: MatSlideToggleChange, user: GenericUser): void {
    this.apiService.patchUser(user.id, {
      isActive: event.checked
    }).subscribe(() => {
      this.refreshUsers();
      this.alertService.success('Utilisateur ' + (event.checked ? 'activé' : 'désactivé'));
    }, () => {
      this.refreshUsers();
      this.alertService.error('Une erreur est survenue lors de la mise à jour de l\'utilisateur');
    });
  }

  getDisplayType(type: 'admin' | 'manager' | 'agency_director', managerType?: 'manager' | 'assistant' | 'reception' | null): string {
    return UsersListComponent.getDisplayType(type, managerType);
  }

  createUser(): void {
    if (!this.user) {
      return;
    }
    const data = {
      type: this.user.type === 'admin' ? 'admin' : 'manager',
    };
    const dialogRef = this.createUserDialog.open(CreateUserDialogComponent, {
      width: '85%',
      data
    });
    dialogRef.afterClosed().subscribe(() => {
      this.refreshUsers();
    });
  }

  updateUserManagerType(event: MatSelectChange, user: GenericUser): void {
    this.apiService.patchUser(user.id, {
      managerType: event.value
    }).subscribe((patchedUser) => {
      this.refreshUsers();
      this.alertService.success(`Le type de l'utilisateur ${patchedUser.firstname} ${patchedUser.lastname} est maintenant ${UsersListComponent.getDisplayType(patchedUser.type, patchedUser.managerType)}`);
    }, () => {
      this.refreshUsers();
      this.alertService.error('Une erreur est survenue lors de la mise à jour de l\'utilisateur (il est impossible de placer à l\'accueil un utilisateur qui a des propriétés)');
    });
  }

  updateUserFirstName(row: any, value: string): void {
    const user = row as GenericUser;
    if (!value) {
      this.refreshUsers();
      return;
    }
    if (value !== user.firstname) {
      row.updateRunning = true;
      this.apiService.patchUser(user.id, {
        firstname: value
      }).subscribe((patchedUser) => {
        this.refreshUsers();
        this.alertService.success(`Le prénom de l'utilisateur est maintenant ${value}`);
        row.updateRunning = false;
      }, () => {
        this.refreshUsers();
        this.alertService.error('Une erreur est survenue lors de la mise à jour du prénom l\'utilisateur');
        row.updateRunning = false;
      });
    }
  }

  updateUserLastName(row: any, value: string): void {
    const user = row as GenericUser;
    if (!value) {
      this.refreshUsers();
      return;
    }
    if (value !== user.lastname) {
      row.updateRunning = true;
      this.apiService.patchUser(user.id, {
        lastname: value
      }).subscribe((patchedUser) => {
        this.refreshUsers();
        this.alertService.success(`Le nom de l'utilisateur est maintenant ${value}`);
        row.updateRunning = false;
      }, () => {
        this.refreshUsers();
        this.alertService.error('Une erreur est survenue lors de la mise à jour du nom l\'utilisateur');
        row.updateRunning = false;
      });
    }
  }

  updateUserEmail(row: any, value: string): void {
    const user = row as GenericUser;
    if (value !== user.email) {
      row.updateRunning = true;
      this.apiService.patchUser(user.id, {
        email: value
      }).subscribe((patchedUser) => {
        this.refreshUsers();
        this.alertService.success(`L'email de l'utilisateur est maintenant ${value}`);
        row.updateRunning = false;
      }, (err) => {
        this.refreshUsers();
        if (err.status === 400) {
          this.alertService.error('L\'adresse email n\'est pas valide');
        } else if (err.status === 500) {
          this.alertService.error('L\'adresse email est déjà utilisée');
        } else {
          this.alertService.error('Une erreur est survenue lors de la mise à jour de l\'email de l\'utilisateur');
        }
        row.updateRunning = false;
      });
    }
  }

  updateUserPhone(row: any, value: string): void {
    const user = row as GenericUser;
    if (value !== user.phone) {
      row.updateRunning = true;
      this.apiService.patchUser(user.id, {
        phone: value
      }).subscribe((patchedUser) => {
        this.refreshUsers();
        this.alertService.success(`Le téléphone de l'utilisateur est maintenant ${value}`);
        row.updateRunning = false;
      }, (err) => {
        this.refreshUsers();
        if (err.status === 400) {
          this.alertService.error('Le téléphone renseigné n\'est pas valide, avez vous pensé au numéro international? (+33 pour la france)');
        } else {
          this.alertService.error('Une erreur est survenue lors de la mise à jour du téléphone de l\'utilisateur');
        }
        row.updateRunning = false;
      });
    }
  }
}
