<div class="container pa-15">
  <div class="row">
    <div class="col-12">
      <h1 class="mat-h1">Accueil</h1>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="horizontal-spacer"></div>
  <div *ngIf="isAuth()">
    <div class="row">
      <div class="col-12">
        <h3 class="mat-h3" *ngIf="user">Bienvenue {{user.firstname}} {{user.lastname}}</h3>
        <mat-spinner *ngIf="!user"></mat-spinner>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <a mat-stroked-button href="/profile" class="login-button full-width">Mon profil</a>
      </div>
    </div>
    <div class="horizontal-spacer"></div>
    <div class="row">
      <div class="col-12">
        <a mat-stroked-button href="/properties" class="login-button full-width">Mes fiches</a>
      </div>
    </div>
    <div class="horizontal-spacer"></div>
    <div class="row" *ngIf="isUserAdmin()">
      <div class="col-12">
        <a mat-stroked-button href="/admin" class="login-button full-width">Espace administrateur</a>
      </div>
    </div>
  </div>
  <div *ngIf="!isAuth()">
    <div class="row">
      <div class="col-12">
        <h3 class="mat-h3">Bienvenue, veuillez vous connecter</h3>
        <a mat-stroked-button href="/login" class="login-button full-width">Se connecter</a>
      </div>
    </div>
    <div class="row" style="position: fixed; bottom: 0; width: 90%">
      <div class="col-12 d-flex justify-content-center">
        <h3 class="mat-h3">Nous contacter: <a href="mailto:contact@suividecles.com">contact@suividecles.com</a></h3>
      </div>
    </div>
  </div>
</div>
