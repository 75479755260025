<mat-card>
  <mat-card-title>Utilisateurs</mat-card-title>
  <mat-card-content>
    <mat-form-field class="col-12 col-lg-8">
      <mat-label class="d-flex align-items-center"><mat-icon>search</mat-icon>Recherche</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="mot clé (séparé par ';' pour chercher plusieurs mots clés)" #input>
    </mat-form-field>
    <mat-checkbox class="pa-x-15" [(ngModel)]="onlyActive" (ngModelChange)="onCheckboxUpdated($event)" color="primary">Seulement actifs</mat-checkbox>
    <table mat-table [dataSource]="dataSource" class="full-width" matSort>
      <!-- Agency Column -->
      <ng-container matColumnDef="agency">
        <th mat-header-cell *matHeaderCellDef> Agence </th>
        <td mat-cell *matCellDef="let row">{{row.agency?.name || ''}}</td>
      </ng-container>
      <!-- Firstname Column -->
      <ng-container matColumnDef="firstname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom </th>
        <td mat-cell *matCellDef="let row">
          <mat-form-field class="form-field full-width" style="padding-right: 16px">
            <input matInput
                   type="text"
                   [required]="true"
                   value="{{row.firstname}}"
                   #firstname
                   (focusout)="updateUserFirstName(row, firstname.value)"
                   [disabled]="row.updateRunning !== undefined ? row.updateRunning : false"
            />
          </mat-form-field>
        </td>
      </ng-container>
      <!-- Firstname Column -->
      <ng-container matColumnDef="lastname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
        <td mat-cell *matCellDef="let row">
          <mat-form-field class="form-field full-width" style="padding-right: 16px">
            <input matInput
                   type="text"
                   [required]="true"
                   value="{{row.lastname}}"
                   #lastname
                   (focusout)="updateUserLastName(row, lastname.value)"
                   [disabled]="row.updateRunning !== undefined ? row.updateRunning : false"
            />
          </mat-form-field>
        </td>
      </ng-container>
      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let row">
          <mat-form-field class="form-field full-width" style="padding-right: 16px">
            <input matInput
                   type="text"
                   [required]="true"
                   value="{{row.email}}"
                   #email
                   (focusout)="updateUserEmail(row, email.value)"
                   [disabled]="row.updateRunning !== undefined ? row.updateRunning : false"
            />
          </mat-form-field>
        </td>
      </ng-container>
      <!-- Phone Column -->
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Téléphone </th>
        <td mat-cell *matCellDef="let row">
          <mat-form-field class="form-field full-width" style="padding-right: 16px">
            <input matInput
                   type="text"
                   [required]="true"
                   value="{{row.phone}}"
                   #phone
                   (focusout)="updateUserPhone(row, phone.value)"
                   [disabled]="row.updateRunning !== undefined ? row.updateRunning : false"
            />
          </mat-form-field>
        </td>
      </ng-container>
      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fonction </th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.type !== 'manager'">{{getDisplayType(row.type, row.managerType)}}</div>
          <div *ngIf="row.type === 'manager'" style="padding-right: 24px">
            <mat-select [value]="row.managerType || 'manager'" (selectionChange)="updateUserManagerType($event, row)" [disabled]="row.updateRunning !== undefined ? row.updateRunning : false">
              <mat-option value="manager">Gestionnaire</mat-option>
              <mat-option value="assistant">Assistant</mat-option>
              <mat-option value="reception">Accueil</mat-option>
            </mat-select>
          </div>
        </td>
      </ng-container>
      <!-- isActive Column -->
      <ng-container matColumnDef="isActive">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actif </th>
        <td mat-cell *matCellDef="let row">
          <mat-slide-toggle [disabled]="user?.id === row.id" [checked]="row.isActive" color="primary" (change)="updateUserActive($event, row)"></mat-slide-toggle>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Aucune donnée ne correspond au filtre {{input.value}}</td>
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </mat-card-content>
  <mat-card-actions class="d-flex justify-content-end">
    <button mat-button type="button" color="primary" (click)="createUser()">{{user?.type === 'admin' ? 'nouvel administrateur' : 'nouvel utilisateur'}}</button>
  </mat-card-actions>
</mat-card>
