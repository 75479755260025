import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../../core/services/api/api.service';
import {GenericUser} from '../../../../shared/models/api/users/generic-user';
import {AlertService} from '../../../../core/services/alert/alert.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  user?: GenericUser = undefined;

  constructor(
    private alertService: AlertService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.refreshUser();
  }

  refreshUser(): void {
    this.user = undefined;
    this.apiService.getUserMe().subscribe(
      (me) => {
        this.user = me;
      }, () => {
        this.alertService.error('Une erreur est survenue lors de la récupération de l\'utilisateur actuel');
      }
    );
  }

  isAdmin(): boolean {
    return this.user && this.user.type === 'admin';
  }

  isAgencyDirector(): boolean {
    return this.user && this.user.type === 'agency_director';
  }
}
