import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {ForgetPasswordComponent} from './pages/forget-password/forget-password.component';
import {FirstLoginComponent} from './pages/first-login/first-login.component';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import {NotAuthGuard} from '../../core/guards/not-auth.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NotAuthGuard]
  },
  {
    path: 'forgetPassword',
    component: ForgetPasswordComponent,
    canActivate: [NotAuthGuard]
  },
  {
    path: 'firstLogin',
    component: FirstLoginComponent
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
