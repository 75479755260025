<mat-card *ngIf="agency !== null">
  <mat-card-title>Mon agence</mat-card-title>
  <mat-card-content>
    <div *ngIf="agency">
      <mat-divider></mat-divider>
      <div class="horizontal-spacer"></div>
      <div class="row property-header">
        <div class="col-2"><app-auth-image [id]="agency.agencyImage?.id.toString()"></app-auth-image></div>
        <div class="col-8 d-flex flex-column">
          <h2 class="mat-h2">{{agency.name}}</h2>
          <h3 class="mat-h3">{{agency.description}}</h3>
        </div>
        <div class="col-2 d-flex flex-column">
          <h2 class="mat-h2 ma-auto">Nombre de fiches</h2>
          <h3 class="mat-h3 ma-auto">{{nbFiles}}</h3>
        </div>
      </div>
    </div>
    <div *ngIf="!agency" class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
  </mat-card-content>
</mat-card>
