<mat-card>
  <mat-card-title>Trouver une fiche</mat-card-title>
  <mat-card-content>
    <mat-form-field class="col-12 col-lg-8">
      <mat-label class="d-flex align-items-center"><mat-icon>search</mat-icon>Recherche</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="mot clé (séparé par ';' pour chercher plusieurs mots clés)" #input>
    </mat-form-field>
    <mat-checkbox *ngIf="user && user.managerType !== 'reception'" class="pa-x-15" [(ngModel)]="onlyMyFiles" (ngModelChange)="onCheckboxUpdated($event)" color="primary">Afficher seulement mes fiches</mat-checkbox>
    <table mat-table [dataSource]="dataSource" class="full-width" matSort>
      <!-- Manager Column -->
      <ng-container matColumnDef="manager">
        <th mat-header-cell *matHeaderCellDef> Gestionnaire </th>
        <td mat-cell *matCellDef="let row">
          <mat-select [value]="row.managerId" (selectionChange)="updateManagerForProperty(row.id, $event)">
            <mat-option *ngFor="let manager of managers" [value]="manager.id">
              {{manager.firstname}} {{manager.lastname}}
            </mat-option>
          </mat-select>
        </td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom de la résidence ou gérance </th>
        <td mat-cell *matCellDef="let row"><a href="/properties/{{row.id}}">{{row.type === 'trustee' ? row.name : row.ownerName}}</a></td>
      </ng-container>

      <!-- Tenant Name Column -->
      <ng-container matColumnDef="tenant">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom du locataire </th>
        <td mat-cell *matCellDef="let row">{{row.type === 'trustee' ? '' : row.name}}</td>
      </ng-container>

      <!-- Internal Code Column -->
      <ng-container matColumnDef="internalCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
        <td mat-cell *matCellDef="let row"><a href="/properties/{{row.id}}">{{row.internalCode}}</a></td>
      </ng-container>

      <!-- Address Column -->
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Adresse </th>
        <td mat-cell *matCellDef="let row">{{row.address}}</td>
      </ng-container>

      <!-- State Column -->
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> État </th>
        <td mat-cell *matCellDef="let row">
          <mat-icon *ngIf="row.state !== 'unknown' && row.state !== 'no_key'" [class]="row.state+'-state'">radio_button_checked</mat-icon>
          <mat-icon *ngIf="row.state === 'unknown' || row.state === 'no_key'" >radio_button_unchecked</mat-icon>
          <mat-icon *ngIf="row.hasLostKey">report</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Aucune donnée ne correspond au filtre {{input.value}}</td>
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </mat-card-content>
  <mat-card-actions>
    <button *ngIf="shouldDisplayPropertyCreate()" class="col-12" mat-button (click)="router.navigate(['/properties/create'])" color="primary">créer une fiche</button>
  </mat-card-actions>
</mat-card>
