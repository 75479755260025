import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ApiService} from '../../../../core/services/api/api.service';
import {AlertService} from '../../../../core/services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {GenericAgency} from '../../../../shared/models/api/agencies/generic-agency';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ValidationDialogData} from '../../../../shared/models/validation-dialog-data';
import {ValidationDialogComponent} from '../../../../shared/components/validation-dialog/validation-dialog.component';
import {CreateAgencyDialogComponent} from '../create-agency-dialog/create-agency-dialog.component';
import {EditAgencyDialogComponent} from '../edit-agency-dialog/edit-agency-dialog.component';
import {CustomPaginator} from '../users-list/users-list.component';
import {GenericProperty} from '../../../../shared/models/api/properties/generic-property';

export interface TableAgency {
  id: number;
  name: string;
  description: string;
  nbProperties: number;
  director: string;
}

@Component({
  selector: 'app-agencies-list',
  templateUrl: './agencies-list.component.html',
  styleUrls: ['./agencies-list.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('Agence par page: ')}
  ]
})
export class AgenciesListComponent implements OnInit {

  displayedColumns: string[] = ['name', 'description', 'director', 'nbProperties', 'edit'];
  agencies: GenericAgency[] = [];
  properties: GenericProperty[] = [];
  datasource: MatTableDataSource<TableAgency>;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onAgencyUpdate = new EventEmitter<void>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  static filterPredicate(data: TableAgency, filterStr: string): boolean {
    if (!filterStr) {
      return true;
    }
    const filters = filterStr.split(';');
    const aggregatedString = `${data.id}${data.name}${data.description}${data.director}}`.trim().toLowerCase();
    for (const filter of filters) {
      if (!aggregatedString.includes(filter)) {
        return false;
      }
    }
    return true;
  }

  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    public createAgencyDialog: MatDialog,
    public updateAgencyDialog: MatDialog,
    public deleteAgencyDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.refreshAgencies();
  }

  refreshAgencies(): void {
    this.apiService.getAgencies().subscribe(
      (agencies) => {
        this.agencies = agencies;
        this.apiService.getProperties().subscribe(
          (properties) => {
            this.properties = properties;
            this.refreshDatasource();
          }
        );
      }, () => {
        this.alertService.error(`Une erreur est survenue lors de la récupération des agences`);
      }
    );
  }

  refreshDatasource(): void {
    const data: TableAgency[] = this.agencies.map((agency) => {
      const agencyProperties = this.properties.filter((property) => property.agency.id === agency.id);
      return {
        id: agency.id,
        name: agency.name,
        description: agency.description,
        nbProperties: agencyProperties.length,
        director: `${agency.agencyDirector.firstname} ${agency.agencyDirector.lastname} (${agency.agencyDirector.email})`
      };
    });
    this.datasource = new MatTableDataSource<TableAgency>(data);
    this.datasource.paginator = this.paginator;
    this.datasource.sort = this.sort;
    this.datasource.filterPredicate = AgenciesListComponent.filterPredicate;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();

    if (this.datasource.paginator) {
      this.datasource.paginator.firstPage();
    }
  }

  editAgency(id: number): void {
    const dialogRef = this.updateAgencyDialog.open(EditAgencyDialogComponent, {
      width: '85%',
      data: {agency: this.agencies.find((agency) => agency.id === id)}
    });
    dialogRef.afterClosed().subscribe(() => {
      this.refreshAgencies();
    });
  }

  deleteAgency(id: number): void {
    const data: ValidationDialogData = {
      title: 'Supprimer l\'agence ?',
      text: 'Êtes vous sûr de vouloir supprimer l\'agence ? (toutes les fiches, utilisateurs, clés, trousseaux liés à l\'agence seront aussi supprimés, cette action est irréversible)',
      onValidate: () => {
        this.apiService.deleteAgency(id).subscribe(
          () => {
            this.alertService.success('L\'agence a bien été supprimée', true);
            this.onAgencyUpdate.emit();
            this.refreshAgencies();
          }, () => {
            this.alertService.error('Une erreur est survenue lors de la suppression de l\'agence.');
          }
        );
      },
      onDiscard: () => {}
    };
    this.deleteAgencyDialog.open(ValidationDialogComponent, {
      data
    });
  }

  createAgency(): void {
    const dialogRef = this.createAgencyDialog.open(CreateAgencyDialogComponent, {
      width: '85%'
    });
    dialogRef.afterClosed().subscribe(() => {
      this.refreshAgencies();
      this.onAgencyUpdate.emit();
    });
  }
}
