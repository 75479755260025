<form [formGroup]="profileInfos" (ngSubmit)="profileInfos.valid && onSubmitProfileInfos()">
  <mat-card>
    <mat-card-title>Mes informations</mat-card-title>
    <mat-card-content>
      <div class="row">
        <div class="col-12">
          <mat-form-field class="form-field">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Mon adresse email..."
                   type="text"
                   id="email"
                   formControlName="email"
                   [required]="true"
            />
            <mat-hint>Votre adresse email</mat-hint>
            <mat-error *ngIf="profileInfos.controls.email.errors && profileInfos.controls.email.errors.required">
              L'adresse email est requise
            </mat-error>
            <mat-error *ngIf="profileInfos.controls.email.errors && profileInfos.controls.email.errors.pattern">
              L'adresse email indiqué n'est pas valide
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="horizontal-spacer"></div>
      <div class="row">
        <div class="col-4">
          <mat-form-field class="form-field">
            <mat-label>Prénom</mat-label>
            <input matInput placeholder="prénom"
                   type="text"
                   id="firstname"
                   formControlName="firstname"
                   [required]="true"
            />
            <mat-hint>Votre prénom</mat-hint>
            <mat-error *ngIf="profileInfos.controls.firstname.errors && profileInfos.controls.firstname.errors.required">
              Le prénom est requis
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field class="form-field">
            <mat-label>Nom</mat-label>
            <input matInput placeholder="nom"
                   type="text"
                   id="lastname"
                   formControlName="lastname"
                   [required]="true"
            />
            <mat-hint>Votre nom</mat-hint>
            <mat-error *ngIf="profileInfos.controls.lastname.errors && profileInfos.controls.lastname.errors.required">
              Le nom est requis
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field class="form-field">
            <mat-label>Téléphone</mat-label>
            <input matInput placeholder="+33 123456789"
                   type="text"
                   id="phone"
                   formControlName="phone"
                   [required]="false"
            />
            <mat-hint>Votre numéro de téléphone</mat-hint>
            <mat-error *ngIf="profileInfos.controls.phone.errors && profileInfos.controls.phone.errors.phone">
              Le numéro de téléphone n'est pas valide (avez vous bien indiqué le numéro international ?)
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions class="d-flex justify-content-end">
      <button mat-button type="submit" [disabled]="!profileInfos.valid" color="primary">mettre à jour</button>
    </mat-card-actions>
  </mat-card>
</form>
