<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="d-flex justify-content-between">
    <h1 class="mat-h1" mat-dialog-title>Éditer une agence</h1>
    <button mat-icon-button type="button" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
  </div>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-4">
        <mat-form-field class="form-field full-width">
          <mat-label>Image</mat-label>
          <ngx-mat-file-input #removableInput id="image" formControlName="image" placeholder="Image de l'agence" [required]="false" [accept]="'.png,.jpg,.jpeg'" (change)="onFileChange($event.target.files)"></ngx-mat-file-input>
          <button mat-icon-button matSuffix *ngIf="!removableInput.empty" (click)="removableInput.clear($event)">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-hint>La nouvelle image de l'agence (laisser vide pour ne pas la changer)</mat-hint>
          <mat-icon matSuffix>folder</mat-icon>
        </mat-form-field>
      </div>
      <div class="col-8">
        <mat-form-field class="form-field full-width">
          <mat-label>Nom de l'agence</mat-label>
          <input matInput placeholder="nom de l'agence"
                 type="text"
                 id="name"
                 formControlName="name"
                 [required]="false"
          />
          <mat-hint>Le nouveau nom de l'agence</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="horizontal-spacer"></div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="form-field full-width">
          <mat-label>Description de l'agence</mat-label>
          <input matInput placeholder="description de l'agence"
                 type="text"
                 id="description"
                 formControlName="description"
                 [required]="false"
          />
          <mat-hint>La nouvelle description de l'agence</mat-hint>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="!formGroup.valid" type="submit" class="full-width">Éditer</button>
  </div>
</form>
