<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="d-flex justify-content-between">
    <h1 class="mat-h1" mat-dialog-title>Créer un {{data.type === 'admin' ? 'administrateur' : 'gestionnaire'}}</h1>
    <button mat-icon-button type="button" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
  </div>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="form-field full-width">
          <mat-label>Prénom</mat-label>
          <input matInput placeholder="prénom"
                 type="text"
                 id="firstname"
                 formControlName="firstname"
                 [required]="true"
          />
          <mat-hint>Le prénom de l'utilisateur</mat-hint>
          <mat-error *ngIf="formGroup.controls.firstname.errors && formGroup.controls.firstname.errors.required">
            Le prénom est requis
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="form-field full-width">
          <mat-label>Nom</mat-label>
          <input matInput placeholder="nom"
                 type="text"
                 id="lastname"
                 formControlName="lastname"
                 [required]="true"
          />
          <mat-hint>Le nom de l'utilisateur</mat-hint>
          <mat-error *ngIf="formGroup.controls.lastname.errors && formGroup.controls.lastname.errors.required">
            Le nom est requis
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="horizontal-spacer"></div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="form-field full-width">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Mon adresse email..."
                 type="text"
                 id="email"
                 formControlName="email"
                 [required]="true"
          />
          <mat-hint>L'adresse email de l'utilisateur</mat-hint>
          <mat-error *ngIf="formGroup.controls.email.errors && formGroup.controls.email.errors.required">
            L'adresse email est requise
          </mat-error>
          <mat-error *ngIf="formGroup.controls.email.errors && formGroup.controls.email.errors.pattern">
            L'adresse email indiqué n'est pas valide
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="form-field full-width">
          <mat-label>Téléphone</mat-label>
          <input matInput placeholder="+33 123456789"
                 type="text"
                 id="phone"
                 formControlName="phone"
                 [required]="false"
          />
          <mat-hint>Le numéro de téléphone de l'utilisateur (optionnel)</mat-hint>
          <mat-error *ngIf="formGroup.controls.phone.errors && formGroup.controls.phone.errors.phone">
            Le numéro de téléphone n'est pas valide (avez vous bien indiqué le numéro international ?)
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="horizontal-spacer"></div>
    <div class="row" *ngIf="data.type !== 'admin'">
      <div class="col-12">
        <mat-form-field class="form-field full-width">
          <mat-label>Type</mat-label>
          <mat-select formControlName="managerType" required>
            <mat-option value="manager">Gestionnaire</mat-option>
            <mat-option value="assistant">Assistant</mat-option>
            <mat-option value="reception">Accueil</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="!formGroup.valid" type="submit" class="full-width">Créer</button>
  </div>
</form>
