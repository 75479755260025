import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from '../services/authentication/authentication.service';
import {ApiService} from '../services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class CanAccessAdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private apiService: ApiService
  ) {
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (this.authenticationService.isAuth()) {
      const me = await this.apiService.getUserMe().toPromise();
      if (me.type !== 'manager') {
        return true;
      }
      this.router.navigate(['/']);
      return false;
    }
    this.router.navigate(['/login']);
    return false;
  }

}
