import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ApiService} from '../../../../core/services/api/api.service';
import {AlertService} from '../../../../core/services/alert/alert.service';
import {PostAgencyPayload} from '../../../../shared/models/api/agencies/post-agency-payload';
import {MatDialogRef} from '@angular/material/dialog';
import {parsePhoneNumberFromString} from 'libphonenumber-js';

@Component({
  selector: 'app-create-agency-dialog',
  templateUrl: './create-agency-dialog.component.html',
  styleUrls: ['./create-agency-dialog.component.scss']
})
export class CreateAgencyDialogComponent implements OnInit {
  formGroup: FormGroup;
  fileToUpload: File = null;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<CreateAgencyDialogComponent>,
  ) {
    this.formGroup = fb.group({
      image: ['', Validators.required],
      name: ['', Validators.required],
      description: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      phone: ['', this.validatePhone()],
    });
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.formGroup.updateValueAndValidity();
    if (!this.formGroup.valid) {
      return;
    }
    const payload: PostAgencyPayload = {
      name: this.formGroup.get('name').value,
      description: this.formGroup.get('description').value,
      email: this.formGroup.get('email').value,
      firstname: this.formGroup.get('firstname').value,
      lastname: this.formGroup.get('lastname').value,
      phone: this.formGroup.get('phone').value || null
    };
    this.apiService.postAgency(payload).subscribe(
      (agency) => {
        this.apiService.uploadImage(this.fileToUpload).subscribe(
          (image) => {
            this.apiService.patchAgency(agency.id, {
              agencyImageId: image.id
            }).subscribe(
              (updatedAgency) => {
                this.alertService.success(`L'agence a bien été créée`);
                this.dialogRef.close(updatedAgency);
              }, () => {
                this.alertService.error(`Une erreur est survenue lors de l'association de l'image, veuillez l'ajouter plus tard en modifiant l'agence`);
                this.dialogRef.close(agency);
              }
            );
          }, () => {
            this.alertService.error(`Une erreur est survenue lors de la création de l'image, veuillez l'ajouter plus tard en modifiant l'agence`);
            this.dialogRef.close(agency);
          }
        );
      }, () => {
        this.alertService.error(`Une erreur est survenue lors de la création de l'agence`);
      }
    );
  }

  validatePhone(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      if (!input) {
        return null;
      }
      const phoneNumber = parsePhoneNumberFromString(input);
      return (!phoneNumber || !phoneNumber.isValid()) ? {phone: {phone: false}} : null;
    };
  }

  onFileChange(files: FileList): void {
    this.fileToUpload = files.item(0);
  }
}
