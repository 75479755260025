<mat-card>
  <mat-card-title>Créer une fiche</mat-card-title>
  <div class="row">
    <div class="col-6">
      <button mat-raised-button disableRipple class="full-width" [color]="trusteeSelected ? 'primary' : undefined" (click)="trusteeSelected = true; managementSelected = false;">Syndic</button>
    </div>
    <div class="col-6">
      <button mat-raised-button disableRipple class="full-width" [color]="managementSelected ? 'primary' : undefined" (click)="trusteeSelected = false; managementSelected = true;">Gérance</button>
    </div>
  </div>
  <div *ngIf="trusteeSelected">
    <form [formGroup]="trusteeFormGroup" (ngSubmit)="onSubmitTrusteeForm()">
      <mat-card-content>
        <div class="row">
          <div class="col-4">
            <mat-form-field class="form-field full-width">
              <mat-label>Image</mat-label>
              <ngx-mat-file-input #removableInput formControlName="image" placeholder="Image du bien" [required]="true" [accept]="'.png,.jpg,.jpeg'" (change)="onFileChange($event.target.files)"></ngx-mat-file-input>
              <button mat-icon-button matSuffix *ngIf="!removableInput.empty" (click)="removableInput.clear($event)">
                <mat-icon>clear</mat-icon>
              </button>
              <mat-hint>Une image du bien</mat-hint>
              <mat-icon matSuffix>folder</mat-icon>
              <mat-error *ngIf="trusteeFormGroup.controls.image.errors && trusteeFormGroup.controls.image.errors.required">
                L'image de la propriété est requise
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="form-field full-width">
              <mat-label>Nom</mat-label>
              <input matInput placeholder="Nom de la résidence"
                     type="text"
                     formControlName="name"
                     [required]="true"
              />
              <mat-hint>Le nom de la résidence</mat-hint>
              <mat-error *ngIf="trusteeFormGroup.controls.name.errors && trusteeFormGroup.controls.name.errors.required">
                Le nom de la propriété est requis
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="form-field full-width">
              <mat-label>Code interne</mat-label>
              <input matInput placeholder="Un code interne"
                     type="text"
                     formControlName="internalCode"
                     [required]="true"
              />
              <mat-hint>Le code du bien</mat-hint>
              <mat-error *ngIf="trusteeFormGroup.controls.internalCode.errors && trusteeFormGroup.controls.internalCode.errors.required">
                Le code interne est requis
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <mat-form-field class="form-field full-width">
              <mat-label>Numéro</mat-label>
              <input matInput placeholder="17 bis"
                     type="text"
                     formControlName="streetNumber"
              />
            </mat-form-field>
          </div>
          <div class="col-8">
            <mat-form-field class="form-field full-width">
              <mat-label>Rue</mat-label>
              <input matInput placeholder="rue de l'exemple"
                     type="text"
                     formControlName="street"
                     [required]="true"
              />
              <mat-error *ngIf="trusteeFormGroup.controls.street.errors && trusteeFormGroup.controls.street.errors.required">
                La rue est requise
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-3">
            <mat-form-field class="form-field full-width">
              <mat-label>Code postal</mat-label>
              <input matInput placeholder="37000"
                     type="text"
                     formControlName="zipcode"
                     [required]="true"
              />
              <mat-error *ngIf="trusteeFormGroup.controls.zipcode.errors && trusteeFormGroup.controls.zipcode.errors.required">
                Le code postal est requis
              </mat-error>
              <mat-error *ngIf="trusteeFormGroup.controls.zipcode.errors && trusteeFormGroup.controls.zipcode.errors.pattern">
                Le code postal indiqué n'est pas valide
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="form-field full-width">
              <mat-label>Ville</mat-label>
              <input matInput placeholder="Tours"
                     type="text"
                     formControlName="city"
                     [required]="true"
              />
              <mat-error *ngIf="trusteeFormGroup.controls.city.errors && trusteeFormGroup.controls.city.errors.required">
                La ville est requise
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="form-field full-width">
              <mat-label>Bâtiment</mat-label>
              <input matInput
                     type="text"
                     formControlName="building"
              />
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="form-field full-width">
              <mat-label>Digicode</mat-label>
              <input matInput
                     type="text"
                     formControlName="digitalCode"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <mat-form-field class="form-field full-width">
              <mat-label>Assistant</mat-label>
              <mat-select formControlName="assistant">
                <mat-option>-- Aucun --</mat-option>
                <mat-option *ngFor="let user of users" [value]="user">
                  {{user.lastname}} {{user.firstname}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12" style="text-align: center">
            <span>Vous pourrez ajouter des contacts après la création de la fiche</span>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions class="d-flex justify-content-end">
        <button mat-button type="submit" [disabled]="!trusteeFormGroup.valid" color="primary">créer</button>
      </mat-card-actions>
    </form>
  </div> <!-- Create trustee property -->
  <div *ngIf="managementSelected">
    <form [formGroup]="managementFormGroup" (ngSubmit)="onSubmitManagementForm()">
      <mat-card-content>
        <div class="row">
          <div class="col-6">
            <mat-form-field class="form-field full-width">
              <mat-label>Nom de la gérance</mat-label>
              <input matInput placeholder="GÉRANCE NOM PRÉNOM ou SCI, INDIV..."
                     type="text"
                     formControlName="ownerName"
                     required
              />
              <mat-hint>Le nom du propriétaire du bien</mat-hint>
              <mat-error *ngIf="managementFormGroup.controls.ownerName.errors && managementFormGroup.controls.ownerName.errors.required">
                Le nom du propriétaire est requis
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="form-field full-width">
              <mat-label>Code interne</mat-label>
              <input matInput placeholder="Un code interne"
                     type="text"
                     formControlName="internalCode"
                     [required]="true"
              />
              <mat-hint>Le code du bien</mat-hint>
              <mat-error *ngIf="managementFormGroup.controls.internalCode.errors && managementFormGroup.controls.internalCode.errors.required">
                Le code interne est requis
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <mat-form-field class="form-field full-width">
              <mat-label>Email propriétaire</mat-label>
              <input matInput placeholder="mail@exemple.fr"
                     type="text"
                     formControlName="ownerEmail"
              />
              <mat-hint>L'email du propriétaire du bien</mat-hint>
              <mat-error *ngIf="managementFormGroup.controls.ownerEmail.errors && managementFormGroup.controls.ownerEmail.errors.pattern">
                L'adresse email indiqué n'est pas valide
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="form-field full-width">
              <mat-label>Téléphone propriétaire</mat-label>
              <input matInput placeholder="+33 123456789"
                     type="text"
                     formControlName="ownerPhone"
              />
              <mat-hint>Le téléphone du propriétaire du bien (avec numéro international)</mat-hint>
              <mat-error *ngIf="managementFormGroup.controls.ownerPhone.errors && managementFormGroup.controls.ownerPhone.errors.phone">
                Le numéro de téléphone n'est pas valide (avez vous bien indiqué le numéro international ?)
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <mat-form-field class="form-field full-width">
              <mat-label>Numéro</mat-label>
              <input matInput placeholder="17 bis"
                     type="text"
                     formControlName="streetNumber"
              />
            </mat-form-field>
          </div>
          <div class="col-8">
            <mat-form-field class="form-field full-width">
              <mat-label>Rue</mat-label>
              <input matInput placeholder="rue de l'exemple"
                     type="text"
                     formControlName="street"
                     [required]="true"
              />
              <mat-error *ngIf="managementFormGroup.controls.street.errors && managementFormGroup.controls.street.errors.required">
                La rue est requise
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-4">
            <mat-form-field class="form-field full-width">
              <mat-label>Code postal</mat-label>
              <input matInput placeholder="37000"
                     type="text"
                     formControlName="zipcode"
                     [required]="true"
              />
              <mat-error *ngIf="managementFormGroup.controls.zipcode.errors && managementFormGroup.controls.zipcode.errors.required">
                Le code postal est requis
              </mat-error>
              <mat-error *ngIf="managementFormGroup.controls.zipcode.errors && managementFormGroup.controls.zipcode.errors.pattern">
                Le code postal indiqué n'est pas valide
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="form-field full-width">
              <mat-label>Ville</mat-label>
              <input matInput placeholder="Tours"
                     type="text"
                     formControlName="city"
                     [required]="true"
              />
              <mat-error *ngIf="managementFormGroup.controls.city.errors && managementFormGroup.controls.city.errors.required">
                La ville est requise
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="form-field full-width">
              <mat-label>Digicode</mat-label>
              <input matInput
                     type="text"
                     formControlName="digitalCode"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <mat-form-field class="form-field full-width">
              <mat-label>Lot N°</mat-label>
              <input matInput
                     type="text"
                     formControlName="batchNumber"
              />
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="form-field full-width">
              <mat-label>Étage</mat-label>
              <input matInput
                     type="text"
                     formControlName="store"
              />
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="form-field full-width">
              <mat-label>Porte</mat-label>
              <input matInput
                     type="text"
                     formControlName="door"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <mat-form-field class="form-field full-width">
              <mat-label>Assistant</mat-label>
              <mat-select formControlName="assistant">
                <mat-option>-- Aucun --</mat-option>
                <mat-option *ngFor="let user of users" [value]="user">
                  {{user.lastname}} {{user.firstname}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12" style="text-align: center">
            <span>Vous pourrez ajouter des contacts et locataires après la création de la fiche</span>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions class="d-flex justify-content-end">
        <button mat-button type="submit" [disabled]="!managementFormGroup.valid" color="primary">créer</button>
      </mat-card-actions>
    </form>
  </div> <!-- Create management property -->
</mat-card>
