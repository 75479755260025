import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../../core/services/api/api.service';
import {AlertService} from '../../../../core/services/alert/alert.service';
import {GenericProperty} from '../../../../shared/models/api/properties/generic-property';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {GenericUser} from '../../../../shared/models/api/users/generic-user';
import {Router} from '@angular/router';
import {CustomPaginator} from '../../../admin/components/users-list/users-list.component';
import {MatSelectChange} from '@angular/material/select';
import {GenericKey} from '../../../../shared/models/api/keys/generic-key';
import {GenericKeyring} from '../../../../shared/models/api/keyrings/generic-keyring';
import {GenericTenant} from '../../../../shared/models/api/tenants/generic-tenant';

export interface AddressRowValues {
  id: number;
  type: 'trustee' | 'management';
  name: string;
  ownerName: string;
  internalCode: string;
  address: string;
  managerName: string;
  managerId: number;
  state: 'returned' | 'away' | 'lost' | 'no_key' | 'unknown';
  hasLostKey: boolean;
  keys: GenericKey[];
  keyrings: GenericKeyring[];
  tenants: GenericTenant[];
}

@Component({
  selector: 'app-properties-list',
  templateUrl: './properties-list.component.html',
  styleUrls: ['./properties-list.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('Fiche par page: ')}
  ]
})
export class PropertiesListComponent implements OnInit {

  constructor(
    public router: Router,
    private apiService: ApiService,
    private alertService: AlertService
  ) {
    this.refreshDataSource();
  }
  displayedColumns: string[] = ['name', 'internalCode', 'address', 'state'];
  properties: GenericProperty[] = [];
  myProperties: GenericProperty[] = [];
  propertiesState: Map<number, {state: 'returned' | 'away' | 'lost' | 'no_key', hasLostKey: boolean}> =
    new Map<number, {state: 'returned' | 'away' | 'lost' | 'no_key', hasLostKey: boolean}>();
  dataSource: MatTableDataSource<AddressRowValues>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  onlyMyFiles = true;

  user?: GenericUser;
  managers: GenericUser[];

  static filterPredicate(data: AddressRowValues, filterStr: string): boolean {
    if (!filterStr) {
      return true;
    }
    const filters = filterStr.split(';');
    let aggregatedString = `${data.managerName}|${data.name}|${data.ownerName}|${data.internalCode}|${data.address}`.trim().toLowerCase();
    for (const key of data.keys) {
      aggregatedString += `|${key.name}|${key.tag}`.trim().toLowerCase();
    }
    for (const keyrings of data.keyrings) {
      aggregatedString += `|${keyrings.name}`.trim().toLowerCase();
    }
    for (const tenant of data.tenants) {
      aggregatedString += `|${tenant.name}`.trim().toLowerCase();
    }
    if (data.hasLostKey) {
      aggregatedString += '|perdu';
    }
    switch (data.state) {
      case 'returned': aggregatedString += '|rentre|rentré'; break;
      case 'away': aggregatedString += '|sorti'; break;
      case 'lost': aggregatedString += '|perdu'; break;
      default: break;
    }
    for (const filter of filters) {
      if (!aggregatedString.includes(filter)) {
        return false;
      }
    }
    return true;
  }

  ngOnInit(): void {
    this.refreshProperties();
    this.refreshUser();
    this.refreshManagers();
  }

  refreshProperties(): void {
    this.apiService.getUserMe().subscribe(
      (me) => {
        this.apiService.getProperties().subscribe(
          (properties) => {
            this.properties = properties;
            this.myProperties = properties.filter((property) => {
              return property.manager.id === me.id || property.assistantManager?.id === me.id;
            });
            this.computePropertyStates();
            this.refreshDataSource();
          },
          (error) => {
            this.alertService.error('Error lors de la récupération des fiches, veuillez réessayer');
          }
        );
      }, () => {
        this.alertService.error('Error lors de la récupération de l\'utilisateur actuel, veuillez réessayer');
      }
    );
  }

  computePropertyStates(): void {
    for (const property of this.properties) {
      this.propertiesState.set(property.id, this.computeState(property));
    }
  }

  computeState(property: GenericProperty): {state: 'returned' | 'away' | 'lost' | 'no_key', hasLostKey: boolean} {
    let nbReturned = 0;
    let nbAway = 0;
    let nbLost = 0;
    for (const key of property.keys) {
      if (key.keyring) {
        continue;
      }
      const relatedEntries = property.history.filter((entry) => {
        return entry.type === 'key' && entry.relatedId === key.id;
      });
      relatedEntries.sort((a, b) => {
        return new Date(b.time).getTime() - new Date(a.time).getTime();
      });
      if (relatedEntries && relatedEntries.length) {
        switch (relatedEntries[0].state) {
          case 'away': nbAway++; break;
          case 'lost': nbLost++; break;
          case 'returned': nbReturned++; break;
        }
      } else {
        nbReturned++;
      }
    }
    for (const keyring of property.keyrings) {
      const relatedEntries = property.history.filter((entry) => {
        return entry.type === 'keyring' && entry.relatedId === keyring.id;
      });
      relatedEntries.sort((a, b) => {
        return new Date(b.time).getTime() - new Date(a.time).getTime();
      });
      if (relatedEntries && relatedEntries.length) {
        switch (relatedEntries[0].state) {
          case 'away': nbAway++; break;
          case 'lost': nbLost++; break;
          case 'returned': nbReturned++; break;
        }
      } else {
        nbReturned++;
      }
    }
    if (nbAway === 0 && nbReturned === 0 && nbLost === 0) {
      return {
        state: 'no_key',
        hasLostKey: false
      };
    }
    return {
      state: nbAway > 0 ? 'away' : (nbReturned > 0 ? 'returned' : 'lost'),
      hasLostKey: nbLost > 0
    };
  }

  refreshDataSource(): void {
    this.dataSource = new MatTableDataSource((this.onlyMyFiles ? this.myProperties : this.properties).map((property: GenericProperty) => {
      const propState = this.propertiesState.has(property.id) ? this.propertiesState.get(property.id) : null;
      return {
        id: property.id,
        type: property.type,
        name: property.name || '',
        ownerName: property.ownerName || '',
        internalCode: property.internalCode,
        address: `${property.address.streetNumber ? property.address.streetNumber + ' ' : ''}${property.address.street}, ${property.address.zipcode} ${property.address.city}`,
        managerName: `${property.manager.firstname} ${property.manager.lastname}`,
        managerId: property.manager.id,
        state: propState ? propState.state : 'unknown',
        hasLostKey: propState ? propState.hasLostKey : false,
        keys: property.keys,
        keyrings: property.keyrings,
        tenants: property.tenants
      };
    }));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = PropertiesListComponent.filterPredicate;
  }

  refreshUser(): void {
    this.apiService.getUserMe().subscribe(
      (me) => {
        this.user = me;
        this.onlyMyFiles = me.managerType !== 'reception';
        if (this.user.type === 'agency_director') {
          setTimeout(() => {
            this.displayedColumns = ['manager', 'name', 'internalCode', 'address', 'state'];
            this.refreshDataSource();
          });
        }
      }, () => {
        this.alertService.error(`Une erreur est survenue lors de la récupération de l'utilisateur actuel`);
      }
    );
  }

  refreshManagers(): void {
    this.managers = [];
    this.apiService.getUsers().subscribe(
      (users) => {
        this.managers = users.filter((user: GenericUser) => {
          return (user.type === 'manager' && user.managerType !== 'reception') || user.type === 'agency_director';
        }).sort((a, b) => {
          if (`${a.firstname} ${a.lastname}` < `${b.firstname} ${b.lastname}`) { return -1; }
          if (`${a.firstname} ${a.lastname}` > `${b.firstname} ${b.lastname}`) { return 1; }
          return 0;
        });
        this.refreshDataSource();
      }, () => {
        this.alertService.error('Une erreur est survenue lors de la récupération des gestionnaires');
      }
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onCheckboxUpdated(event: Event): void {
    this.refreshDataSource();
  }

  shouldDisplayPropertyCreate(): boolean {
    return this.user && this.user.type !== 'admin' && this.user.managerType !== 'reception';
  }

  updateManagerForProperty(propertyId: number, event: MatSelectChange): void {
    const managerId = event.value;
    this.apiService.patchProperty(propertyId, {
      managerId
    }).subscribe(() => {
      this.refreshProperties();
      this.alertService.success(`Le gestionnaire a bien été changé.`);
    }, () => {
      this.alertService.error(`Une erreur est survenue lors du changement de gestionnaire`);
    });
  }
}
