<mat-card>
  <mat-card-title class="d-flex justify-content-between" *ngIf="property">
    <div *ngIf="property.type == 'trustee'">{{ property.name }}</div>
    <div *ngIf="property.type == 'management'">{{ property.ownerName }}</div>
    <div>{{ property.internalCode }}</div>
  </mat-card-title>
  <mat-card-title class="d-flex justify-content-between" *ngIf="!property">
    <div>Chargement...</div>
    <div>Chargement...</div>
  </mat-card-title>
  <mat-card-content>
    <div *ngIf="property">
      <mat-divider></mat-divider>
      <div class="row property-header">
        <div class="col-3 d-none d-md-block" *ngIf="property.type == 'trustee'"><app-auth-image [id]="property.image?.id"></app-auth-image></div>
        <div class="col-3 d-none d-md-block" *ngIf="property.type == 'management'">
          <h3 class="mat-h3">Gérance:</h3>
          <div>
            <div>Nom de la gérance: {{property.ownerName}}</div>
            <div *ngIf="property.ownerEmail">Email: {{property.ownerEmail}}</div>
            <div *ngIf="property.ownerPhone">Téléphone: {{property.ownerPhone}}</div>
            <div *ngIf="property.tenants && property.tenants.length">Locataires: <button mat-button style="font-weight: initial" color="primary" (click)="displayTenants()">afficher</button></div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 d-flex flex-column">
          <h3 class="mat-h3">Adresse:</h3>
          <div>
            <div>{{ property.address?.streetNumber}} {{property.address?.street}}</div>
            <div>{{ property.address?.zipcode}}, {{property.address?.city}}</div>
            <div *ngIf="property.building && property.type == 'trustee'">Bâtiment: {{property.building}}</div>
            <div *ngIf="property.digitalCode">Digicode: {{property.digitalCode}}</div>
            <div *ngIf="property.type == 'management' && (property.store || property.door || property.batchNumber)">
              {{property.batchNumber ? 'Lot: ' + property.batchNumber : ''}}{{property.batchNumber && property.store ? ', ' : ''}}{{property.store ? 'Étage: ' + property.store : ''}}{{(property.batchNumber || property.store) && property.door ? ', ' : ''}}{{property.door ? 'Porte: ' + property.door : ''}}
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 d-flex flex-column">
          <h3 class="mat-h3">Contacts:</h3>
          <div>
            <div>Gestionnaire: {{capitalize(property.manager.firstname)}} {{property.manager.lastname.toUpperCase()}}</div>
            <div *ngIf="property.assistantManager">Assistant(e): {{capitalize(property.assistantManager.firstname)}} {{property.assistantManager.lastname.toUpperCase()}}</div>
            <div *ngIf="property.syndicateContacts.length">Autre: <button mat-button style="font-weight: initial" color="primary" (click)="displayContacts()">afficher</button></div>
          </div>
        </div>
        <div class="col-2 d-none d-md-block"><app-auth-image [id]="property.agency?.agencyImage?.id"></app-auth-image></div>
      </div>
      <mat-divider></mat-divider>
      <div class="property-keyrings full-width" *ngIf="keyrings.length">
        <mat-accordion class="full-width">
          <app-keyring-infos *ngFor="let keyring of keyrings" class="keyring-infos" [keyring]="keyring" [agency]="property.agency" [state]="computeState('keyring', keyring.id)" (onKeyringStateUpdated)="refreshProperty()"></app-keyring-infos>
        </mat-accordion>
      </div>
      <div class="property-additional-keys full-width" *ngIf="keys.length">
        <h4 class="mat-h4 full-width">Clés supplémentaires</h4>
        <mat-accordion class="full-width">
          <app-key-infos *ngFor="let key of keys" [key]="key" [agency]="property.agency" [state]="computeState('key', key.id)" (onKeyStateUpdated)="refreshProperty()"></app-key-infos>
        </mat-accordion>
      </div>
    </div>
    <div *ngIf="!property" class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
  </mat-card-content>
  <mat-card-actions class="card-actions">
    <button class="col-12 col-lg-2" mat-button (click)="displayPDF()">imprimer PDF</button>
    <mat-divider class="d-none d-lg-block" [vertical]="true"></mat-divider>
    <button class="col-12 col-lg-2" mat-button (click)="displayHistory()">historique</button>
    <mat-divider class="d-none d-lg-block" [vertical]="true"></mat-divider>
    <!-- Button removed as key states are now changed by clicking on dots in key/keyring infos
    <button class="col-12 col-lg-2" mat-button (click)="changeKeysState()" color="primary">changer l'état des clés</button>
    <mat-divider class="d-none d-lg-block" *ngIf="canManageProperty" [vertical]="true"></mat-divider>
    -->
    <button *ngIf="canManageProperty" class="col-12 col-lg-2" mat-button (click)="updateProperty()" color="primary">modifier la fiche</button>
    <!-- Button removed as properties are now deleted in the update property modal
    <mat-divider class="d-none d-lg-block" *ngIf="canManageProperty" [vertical]="true"></mat-divider>
    <button *ngIf="canManageProperty" class="col-12 col-lg-2" mat-button (click)="deleteProperty()" color="warn">supprimer la fiche</button>
    -->
  </mat-card-actions>
</mat-card>
