<div>
  <div>
    <div class="container form-container">
      <h2 class="mat-h2 title">Changement de mot de passe</h2>
      <form [formGroup]="passwordForm" (ngSubmit)="passwordForm.valid && onSubmit()">
        <div class="col-12">
          <div class="form-group">
            <mat-form-field class="form-field">
              <mat-label>Mot de passe</mat-label>
              <input matInput placeholder="Mon mot de passe"
                     [type]="hide ? 'password' : 'text'"
                     id="password"
                     formControlName="password"
                     [ngClass]="{
                       'is-invalid': passwordForm.controls.password.errors
                     }"
              />
              <mat-hint>Entrez ici votre nouveau mot de passe</mat-hint>
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" type="button" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="passwordForm.controls.password.errors && passwordForm.controls.password.errors.minlength">
                Le mot de passe doit faire au moins 8 caractères
              </mat-error>
              <mat-error *ngIf="passwordForm.controls.password.errors && passwordForm.controls.password.errors.required">
                Le mot de passe est requis
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <mat-form-field class="form-field">
              <mat-label>Mot de passe (validation)</mat-label>
              <input matInput placeholder="Mon mot de passe"
                     [type]="hide ? 'password' : 'text'"
                     id="passwordValidation"
                     formControlName="passwordValidation"
                     [ngClass]="{
                       'is-invalid': passwordForm.controls.passwordValidation.errors
                     }"
              />
              <mat-hint>Entrez de nouveau ici votre nouveau mot de passe</mat-hint>
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" type="button" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="passwordForm.controls.passwordValidation.errors && passwordForm.controls.passwordValidation.errors.passwordMatch">
                Les mots de passe ne sont pas identiques
              </mat-error>
              <mat-error *ngIf="passwordForm.controls.passwordValidation.errors && passwordForm.controls.passwordValidation.errors.required">
                Le mot de passe est requis
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-12">
          <div class="button-container">
            <button mat-raised-button type="submit" [disabled]="!passwordForm.valid" color="primary">VALIDER</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
