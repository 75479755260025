import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PostHistoryPayload} from '../../../../shared/models/api/history/post-history-payload';
import {ApiService} from '../../../../core/services/api/api.service';
import {AlertService} from '../../../../core/services/alert/alert.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GenericContact} from '../../../../shared/models/api/contacts/generic-contact';
import {CreateContactDialogData} from '../../../../shared/models/contacts/create-contact-dialog-data';
import {CreateContactDialogComponent} from '../../../../shared/components/create-contact-dialog/create-contact-dialog.component';

export interface UpdateStateAwayDialogData {
  name: string;
  type: 'key' | 'keyring';
  relatedId: number;
  propertyId: number;
  agencyId: number;
}

@Component({
  selector: 'app-update-state-away-dialog',
  templateUrl: './update-state-away-dialog.component.html',
  styleUrls: ['./update-state-away-dialog.component.scss']
})
export class UpdateStateAwayDialogComponent implements OnInit {
  minDate = new Date();
  formGroup: FormGroup;
  contacts: GenericContact[] = [];

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private apiService: ApiService,
    public dialogRef: MatDialogRef<UpdateStateAwayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpdateStateAwayDialogData,
    public createContactDialog: MatDialog
  ) {
    this.formGroup = fb.group({
      contact: ['', Validators.required],
      reminder: ['']
    });
  }

  ngOnInit(): void {
    this.refreshContacts();
  }

  refreshContacts(): void {
    this.apiService.getContacts().subscribe(
      (contacts) => {
        this.contacts = contacts.filter((contact) => {
          return contact.type === 'service_provider' && contact.agency.id === this.data.agencyId;
        }).sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
      }, () => {
        this.alertService.warn('Une erreur est survenue lors de la récupération des contacts');
      }
    );
  }

  newContact(): void {
    const data: CreateContactDialogData = {
      type: 'service_provider'
    };
    const dialogRef = this.createContactDialog.open(CreateContactDialogComponent, {
      width: '80%',
      data
    });
    dialogRef.afterClosed().subscribe(() => {
      this.refreshContacts();
    });
  }

  onDiscard(): void {
    this.dialogRef.close(null);
  }

  onValidate(): void {
    const contactId = this.formGroup.get('contact').value.id;
    const reminderValue = this.formGroup.get('reminder').value;
    const reminder = reminderValue ? new Date(reminderValue.toISOString()) : undefined;
    const payload: PostHistoryPayload = {
      type: this.data.type,
      relatedId: this.data.relatedId,
      state: 'away',
      propertyId: this.data.propertyId,
      contactId,
      reminder,
    };
    this.apiService.postHistory(payload).subscribe(
      (entry) => {
        this.alertService.success('Le changement a bien été effectué');
        this.dialogRef.close(entry);
      }, () => {
        this.alertService.error('Une erreur est survenue lors du changement d\'état, veuillez réessayer');
      }
    );
  }
}
