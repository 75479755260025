import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ApiService} from '../../../../core/services/api/api.service';
import {AlertService} from '../../../../core/services/alert/alert.service';

@Component({
  selector: 'app-profile-update-password',
  templateUrl: './profile-update-password.component.html',
  styleUrls: ['./profile-update-password.component.scss']
})
export class ProfileUpdatePasswordComponent implements OnInit {
  updatePasswordForm: FormGroup;
  hide = true;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private alertService: AlertService,
  ) {
    this.updatePasswordForm = fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      passwordValidation: ['', [Validators.required, this.passwordMatch('password')]],
    });
    this.updatePasswordForm.controls.password.valueChanges.subscribe(() => {
      setTimeout(() => {
        this.updatePasswordForm.controls.passwordValidation.updateValueAndValidity();
      });
    });
  }

  ngOnInit(): void {
  }

  onSubmitUpdatePassword(): void {
    this.apiService.getUserMe().subscribe((user) => {
      this.apiService.patchUser(user.id, {password: this.updatePasswordForm.get('password').value}).subscribe(
        () => {
          this.alertService.success('Mot de passe mis à jours');
        },
        () => {
          this.alertService.error('Le mot de passe n\'est pas valide');
        }
      );
    }, () => {
      this.alertService.error('Une erreur est survenue lors de la récupération de l\'utilisateur actuel. Veuillez réessayer');
    });
  }

  passwordMatch(fieldName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      const passwordMatch = control.root.value[fieldName] !== input;
      return passwordMatch ? {passwordMatch: {passwordMatch}} : null;
    };
  }
}
