<mat-card>
  <mat-card-title>Agences</mat-card-title>
  <mat-card-content>
    <mat-form-field class="col-12">
      <mat-label class="d-flex align-items-center"><mat-icon>search</mat-icon>Recherche</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="mot clé (séparé par ';' pour chercher plusieurs mots clés)" #input>
    </mat-form-field>
    <table mat-table [dataSource]="datasource" class="full-width" matSort>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
      </ng-container>
      <!-- description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
        <td mat-cell *matCellDef="let row">{{row.description}}</td>
      </ng-container>
      <!-- director Column -->
      <ng-container matColumnDef="director">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Directeur </th>
        <td mat-cell *matCellDef="let row">{{row.director}}</td>
      </ng-container>
      <!-- nbProperties Column -->
      <ng-container matColumnDef="nbProperties">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre de fiches </th>
        <td mat-cell *matCellDef="let row">{{row.nbProperties}}</td>
      </ng-container>
      <!-- edit Column -->
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef> Modifier </th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button type="button" (click)="editAgency(row.id)"><mat-icon color="primary">edit</mat-icon></button>
          <button mat-icon-button type="button" (click)="deleteAgency(row.id)"><mat-icon color="warn">delete</mat-icon></button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Aucune donnée ne correspond au filtre {{input.value}}</td>
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </mat-card-content>
  <mat-card-actions class="d-flex justify-content-end">
    <button mat-button type="button" color="primary" (click)="createAgency()">nouvelle agence</button>
  </mat-card-actions>
</mat-card>
