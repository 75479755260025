import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UpdatePropertyDialogData} from '../../../../shared/models/properties/update-property-dialog-data';
import {AlertService} from '../../../../core/services/alert/alert.service';
import {ApiService} from '../../../../core/services/api/api.service';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {CreateContactDialogData} from '../../../../shared/models/contacts/create-contact-dialog-data';
import {CreateContactDialogComponent} from '../../../../shared/components/create-contact-dialog/create-contact-dialog.component';
import {GenericContact} from '../../../../shared/models/api/contacts/generic-contact';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {GenericUser} from '../../../../shared/models/api/users/generic-user';
import {GenericKey} from '../../../../shared/models/api/keys/generic-key';
import {GenericKeyring} from '../../../../shared/models/api/keyrings/generic-keyring';
import {ValidationDialogData} from '../../../../shared/models/validation-dialog-data';
import {ValidationDialogComponent} from '../../../../shared/components/validation-dialog/validation-dialog.component';
import {PostKeyPayload} from '../../../../shared/models/api/keys/post-key-payload';
import {PostKeyringPayload} from '../../../../shared/models/api/keyrings/post-keyring-payload';
import {Router} from '@angular/router';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import {CreateTenantDialogData} from '../../../../shared/models/tenants/create-tenant-dialog-data';
import {CreateTenantDialogComponent} from '../../../../shared/components/create-tenant-dialog/create-tenant-dialog.component';
import {GenericTenant} from '../../../../shared/models/api/tenants/generic-tenant';

@Component({
  selector: 'app-update-property-dialog',
  templateUrl: './update-property-dialog.component.html',
  styleUrls: ['./update-property-dialog.component.scss']
})
export class UpdatePropertyDialogComponent implements OnInit {
  contacts: GenericContact[] = [];
  users: GenericUser[] = [];
  tenants: GenericTenant[] = [];

  keys: GenericKey[] = [];
  keyrings: GenericKeyring[] = [];

  fileToUpload: File = null;

  propertyFormGroup: FormGroup;
  keyFormGroup: FormGroup;
  keyCreateFormGroup: FormGroup;
  keyringCreateFormGroup: FormGroup;
  keyringFormGroup: FormGroup;
  contactFormGroup: FormGroup;
  tenantFormGroup: FormGroup;

  constructor(
    private cdref: ChangeDetectorRef,
    private alertService: AlertService,
    private apiService: ApiService,
    private fb: FormBuilder,
    private router: Router,
    public dialogRef: MatDialogRef<UpdatePropertyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpdatePropertyDialogData,
    public createContactDialog: MatDialog,
    public createTenantDialog: MatDialog,
    public deleteValidationDialog: MatDialog,
    public deletePropertyValidationDialog: MatDialog,
  ) {
    this.propertyFormGroup = fb.group({
      image: [''],
      name: this.data.property.type === 'trustee' ? [this.data.property.name, Validators.required] : [this.data.property.name],
      internalCode: [this.data.property.internalCode || '', Validators.required],
      building: [this.data.property.building || ''],
      digitalCode: [this.data.property.digitalCode || ''],
      streetNumber: [this.data.property.address.streetNumber],
      street: [this.data.property.address.street, Validators.required],
      zipcode: [this.data.property.address.zipcode, Validators.compose([Validators.required, Validators.pattern('^[0-9]+$')])],
      city: [this.data.property.address.city, Validators.required],
      manager: ['', Validators.required],
      assistant: [''],
      ownerName: this.data.property.type === 'management' ? [this.data.property.ownerName, Validators.required] : [this.data.property.ownerName],
      ownerEmail: [this.data.property.ownerEmail, this.validateOptionalEmail()],
      ownerPhone: [this.data.property.ownerPhone, this.validatePhone()],
      batchNumber: [this.data.property.batchNumber],
      store: [this.data.property.store],
      door: [this.data.property.door]
    });
    this.keyFormGroup = fb.group({});
    this.keyCreateFormGroup = fb.group({
      tag: ['', Validators.required],
      name: ['', Validators.required],
      description: ['', Validators.required],
      keyring: ['']
    });
    this.keyringFormGroup = fb.group({});
    this.keyringCreateFormGroup = fb.group({
      name: ['', Validators.required],
      keys: ['']
    });
    this.contactFormGroup = fb.group({});
    this.tenantFormGroup = fb.group({});
  }

  ngOnInit(): void {
    this.refreshUsers().subscribe(
      () => {
        const currentManager = this.users.find((user) => {
          return user.id === this.data.property.manager.id;
        });
        const currentAssistant = this.users.find((user) => {
          return user.id === this.data.property.assistantManager?.id;
        });
        this.propertyFormGroup.get('manager').setValue(currentManager);
        this.propertyFormGroup.get('assistant').setValue(currentAssistant);
      }
    );
    this.refreshContacts().subscribe();
    this.refreshTenants().subscribe();
    this.refreshKeysAndKeyrings();
    this.refreshFormGroups();
  }

  async onPropertyFormGroupSubmit(): Promise<void> {
    this.propertyFormGroup.updateValueAndValidity();
    if (!this.propertyFormGroup.valid) {
      return;
    }
    let propertyImage;
    let oldImageId;
    if (this.fileToUpload) {
      try {
        propertyImage = await this.apiService.uploadImage(this.fileToUpload).toPromise();
      } catch {
        this.alertService.error(`Une erreur est survenue lors de l'envoi de l'image`);
        this.dialogRef.close();
        return;
      }
      if (this.data.property.image) {
        oldImageId = this.data.property.image.id;
      }
    }
    try {
      const property = await this.apiService.patchProperty(this.data.property.id, {
        name: this.propertyFormGroup.get('name').value ? this.propertyFormGroup.get('name').value : null,
        building: this.propertyFormGroup.get('building').value ? this.propertyFormGroup.get('building').value : null,
        digitalCode: this.propertyFormGroup.get('digitalCode').value ? this.propertyFormGroup.get('digitalCode').value : null,
        internalCode: this.propertyFormGroup.get('internalCode').value ? this.propertyFormGroup.get('internalCode').value : null,
        streetNumber: this.propertyFormGroup.get('streetNumber').value ? this.propertyFormGroup.get('streetNumber').value : null,
        street: this.propertyFormGroup.get('street').value,
        zipcode: parseInt(this.propertyFormGroup.get('zipcode').value, 10),
        city: this.propertyFormGroup.get('city').value,
        managerId: this.propertyFormGroup.get('manager').value.id,
        assistantManagerId: this.propertyFormGroup.get('assistant').value ? this.propertyFormGroup.get('assistant').value.id : null,
        propertyImageId: propertyImage ? propertyImage.id : undefined,
        ownerName: this.propertyFormGroup.get('ownerName').value,
        ownerEmail: this.propertyFormGroup.get('ownerEmail').value ? this.propertyFormGroup.get('ownerEmail').value : null,
        ownerPhone: this.propertyFormGroup.get('ownerPhone').value ? this.propertyFormGroup.get('ownerPhone').value : null,
        batchNumber: this.propertyFormGroup.get('batchNumber').value ? this.propertyFormGroup.get('batchNumber').value : null,
        store: this.propertyFormGroup.get('store').value ? this.propertyFormGroup.get('store').value : null,
        door: this.propertyFormGroup.get('door').value ? this.propertyFormGroup.get('door').value : null,
      }).toPromise();
      this.alertService.success('La fiche a bien été mise à jour');
      if (oldImageId) {
        try {
          await this.apiService.deleteImage(oldImageId).toPromise();
        } catch {
          this.alertService.error(`Une erreur est survenue lors de la suppression de l'ancienne image`);
        }
      }
      this.dialogRef.close(property);
    } catch {
      this.alertService.error('Une erreur est survenue lors de la mise à jour des contacts');
      this.dialogRef.close();
    }
  }

  refreshUsers(): Observable<any> {
    return this.apiService.getUsers()
      .pipe(map((users) => {
        this.users = users.filter((user) => {
          return user.managerType !== 'reception' && user.agency?.id === this.data.property.agency.id;
        }).sort((a, b) => {
          if (a.lastname < b.lastname) { return -1; }
          if (a.lastname > b.lastname) { return 1; }
          return 0;
        });
      }))
      .pipe(catchError((err) => {
        this.alertService.warn('Une erreur est survenue lors de la récupération des utilisateurs');
        return throwError(err);
      }));
  }

  refreshContacts(): Observable<any> {
    return this.apiService.getContacts()
      .pipe(map((contacts) => {
          this.contacts = contacts.filter((contact) => {
            return contact.type === 'syndicate_contact' && contact.agency.id === this.data.property.agency.id;
          }).sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
        }))
      .pipe(catchError((err) => {
        this.alertService.warn('Une erreur est survenue lors de la récupération des contacts');
        return throwError(err);
      }));
  }

  refreshKeysAndKeyrings(): void {
    this.apiService.getKeys(this.data.property.id).subscribe(
      (keys) => {
        this.apiService.getKeyrings(this.data.property.id).subscribe(
          (keyrings) => {
            const keyGroups = {};
            for (const key of keys) {
              keyGroups[`keyringKeyGroupId${key.id}`] = [''];
              keyGroups[`tagKeyGroupId${key.id}`] = [key.tag, Validators.required];
              keyGroups[`nameKeyGroupId${key.id}`] = [key.name, Validators.required];
              keyGroups[`descriptionKeyGroupId${key.id}`] = [key.description, Validators.required];
            }
            const keyringsGroups = {};
            for (const keyring of keyrings) {
              keyringsGroups[`nameKeyringGroupId${keyring.id}`] = [keyring.name, Validators.required];
              keyringsGroups[`keysKeyringGroupId${keyring.id}`] = [''];
            }
            this.keyFormGroup = this.fb.group(keyGroups);
            this.keyringFormGroup = this.fb.group(keyringsGroups);
            this.keys = keys;
            this.keyrings = keyrings;
            for (const key of keys) {
              this.keyFormGroup.get(`keyringKeyGroupId${key.id}`).setValue(this.keyrings.find((keyring) => {
                return keyring.id === key.keyring?.id;
              }));
            }
            for (const keyring of keyrings) {
              this.keyringFormGroup.get(`keysKeyringGroupId${keyring.id}`).setValue(this.keys.filter((key) => {
                return key.keyring?.id === keyring.id;
              }));
            }
            this.cdref.detectChanges();
          }, () => {
            this.alertService.error('Une erreur est survenue lors de la récupération des trousseaux');
          }
        );
      }, () => {
        this.alertService.error('Une erreur est survenue lors de la récupération des clés');
      }
    );
  }

  newContact(): void {
    const data: CreateContactDialogData = {
      type: 'syndicate_contact',
      propertyId: this.data.property.id
    };
    const dialogRef = this.createContactDialog.open(CreateContactDialogComponent, {
      width: '80%',
      data
    });
    dialogRef.afterClosed().subscribe(() => {
      this.refreshContacts();
      this.refreshProperty();
    });
  }

  onSubmitKeyUpdate(id: number): void {
    const tagGroupControl = this.keyFormGroup.get(`tagKeyGroupId${id}`);
    const nameGroupControl = this.keyFormGroup.get(`nameKeyGroupId${id}`);
    const descriptionGroupControl = this.keyFormGroup.get(`descriptionKeyGroupId${id}`);
    const keyringGroupControl = this.keyFormGroup.get(`keyringKeyGroupId${id}`);
    tagGroupControl.updateValueAndValidity();
    nameGroupControl.updateValueAndValidity();
    descriptionGroupControl.updateValueAndValidity();
    keyringGroupControl.updateValueAndValidity();
    if (!tagGroupControl.valid || !nameGroupControl.valid || !descriptionGroupControl.valid || !keyringGroupControl.valid) {
      return;
    }
    this.apiService.patchKey(id, {
      name: nameGroupControl.value,
      description: descriptionGroupControl.value,
      tag: tagGroupControl.value,
      keyringId: keyringGroupControl.value ? keyringGroupControl.value.id : null,
    }).subscribe(
      () => {
        this.refreshKeysAndKeyrings();
      },
      () => {
        this.alertService.error('Une erreur est survenue lors de la sauvegarde de la clé');
      }
    );
  }

  onDeleteKey(id: number): void {
    const relatedKey = this.keys.find((key) => {
      return key.id === id;
    });
    const data: ValidationDialogData = {
      title: 'Supprimer la clé ?',
      text: `Êtes vous sûr de vouloir supprimer la clé ${relatedKey.name} ? (l'historique de la clé sera aussi supprimé)`,
      onValidate: () => {
        this.apiService.deleteKey(id).subscribe(
          () => {
            this.alertService.success(`La clé ${relatedKey.name} a bien été supprimée`, true);
            this.refreshKeysAndKeyrings();
          }, () => {
            this.alertService.error(`Une erreur est survenue lors de la suppression de la clé ${relatedKey.name}.`);
          }
        );
      },
      onDiscard: () => {}
    };
    this.deleteValidationDialog.open(ValidationDialogComponent, {
      data
    });
  }

  onSubmitKeyCreate(): void {
    this.keyCreateFormGroup.updateValueAndValidity();
    if (!this.keyCreateFormGroup.valid) {
      return;
    }
    const payload: PostKeyPayload = {
      tag: this.keyCreateFormGroup.get('tag').value,
      name: this.keyCreateFormGroup.get('name').value,
      description: this.keyCreateFormGroup.get('description').value,
      propertyId: this.data.property.id,
      keyringId: this.keyCreateFormGroup.get('keyring').value?.id
    };
    this.apiService.postKey(payload).subscribe(
      () => {
        this.alertService.success(`La clé a bien été crée`, true);
        this.keyCreateFormGroup.reset();
        this.refreshKeysAndKeyrings();
      }, () => {
        this.alertService.error(`Une erreur est survenue lors de la création de la clé.`);
      }
    );
  }

  onSubmitKeyringCreate(): void {
    this.keyringCreateFormGroup.updateValueAndValidity();
    if (!this.keyringCreateFormGroup.valid) {
      return;
    }
    const payload: PostKeyringPayload = {
      name: this.keyringCreateFormGroup.get('name').value,
      keyIds: this.keyringCreateFormGroup.get('keys').value.map((key) => key.id),
      propertyId: this.data.property.id
    };
    this.apiService.postKeyring(payload).subscribe(
      () => {
        this.alertService.success(`Le trousseau a bien été crée`, true);
        this.keyringCreateFormGroup.reset();
        this.refreshKeysAndKeyrings();
      }, () => {
        this.alertService.error(`Une erreur est survenue lors de la création du trousseau.`);
      }
    );
  }

  onSubmitKeyringUpdate(id: number): void {
    const nameGroupControl = this.keyringFormGroup.get(`nameKeyringGroupId${id}`);
    const keysGroupControl = this.keyringFormGroup.get(`keysKeyringGroupId${id}`);
    nameGroupControl.updateValueAndValidity();
    keysGroupControl.updateValueAndValidity();
    if (!nameGroupControl.valid || !keysGroupControl.valid) {
      return;
    }
    this.apiService.patchKeyring(id, {
      name: nameGroupControl.value,
      keyIds: keysGroupControl.value.map((key) => key.id),
    }).subscribe(
      () => {
        this.refreshKeysAndKeyrings();
      },
      () => {
        this.alertService.error('Une erreur est survenue lors de la sauvegarde du trousseau');
      }
    );
  }

  onDeleteKeyring(id: number): void {
    const relatedKeyring = this.keyrings.find((keyring) => {
      return keyring.id === id;
    });
    const data: ValidationDialogData = {
      title: 'Supprimer le trousseau ?',
      text: `Êtes vous sûr de vouloir supprimer le trousseau ${relatedKeyring.name} ? (l'historique du trousseau sera aussi supprimé, les clés seront déliées)`,
      onValidate: () => {
        this.apiService.deleteKeyring(id).subscribe(
          () => {
            this.alertService.success(`Le trousseau ${relatedKeyring.name} a bien été supprimée`, true);
            this.refreshKeysAndKeyrings();
          }, () => {
            this.alertService.error(`Une erreur est survenue lors de la suppression du trousseau ${relatedKeyring.name}.`);
          }
        );
      },
      onDiscard: () => {}
    };
    this.deleteValidationDialog.open(ValidationDialogComponent, {
      data
    });
  }

  isKeylistEqual(keys: GenericKey[], value: GenericKey[]): boolean {
    if (keys.length !== value.length) {
      return false;
    }
    for (const keyringKey of keys) {
      const valueKey = value.find((key) => {
        return key.id === keyringKey.id;
      });
      if (!valueKey) {
        return false;
      }
    }
    return true;
  }

  onFileChange(files: FileList): void {
    this.fileToUpload = files.item(0);
  }

  refreshProperty(): void {
    this.apiService.getProperty(this.data.property.id).subscribe(
      (property) => {
        this.data.property = property;
        this.refreshFormGroups();
      }, () => {
        this.alertService.error(`Une erreur est survenue lors de l'actualisation de la fiche`);
      }
    );
  }

  deleteProperty(): void {
    const data: ValidationDialogData = {
      title: 'Supprimer la fiche ?',
      text: 'Êtes vous sûr de vouloir supprimer la fiche ? (toutes les clés, tous les trousseaux et l\'historique de la fiche seront aussi supprimés)',
      onValidate: () => {
        this.apiService.deleteProperty(this.data.property.id).subscribe(
          () => {
            this.dialogRef.close(null);
            this.alertService.success('La fiche a bien été supprimée', true);
            this.router.navigate(['/properties']);
          }, () => {
            this.alertService.error('Une erreur est survenue lors de la suppression de la fiche.');
          }
        );
      },
      onDiscard: () => {}
    };
    this.deletePropertyValidationDialog.open(ValidationDialogComponent, {
      data
    });
  }

  validateOptionalEmail(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      if (!input) {
        return null;
      }
      return (Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'))(control);
    };
  }

  validatePhone(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      if (!input) {
        return null;
      }
      const phoneNumber = parsePhoneNumberFromString(input);
      return (!phoneNumber || !phoneNumber.isValid()) ? {phone: {phone: false}} : null;
    };
  }

  onSubmitContactUpdate(id: number): void {
    const contactNameGroupControl = this.contactFormGroup.get(`nameContactGroupId${id}`);
    const contactEmailGroupControl = this.contactFormGroup.get(`emailContactGroupId${id}`);
    const contactOfficeGroupControl = this.contactFormGroup.get(`officeContactGroupId${id}`);
    const contactPhoneGroupControl = this.contactFormGroup.get(`phoneContactGroupId${id}`);
    contactNameGroupControl.updateValueAndValidity();
    contactEmailGroupControl.updateValueAndValidity();
    contactOfficeGroupControl.updateValueAndValidity();
    contactPhoneGroupControl.updateValueAndValidity();
    if (!contactNameGroupControl.valid) {
      this.alertService.warn(`Le nom est requis`);
      return;
    }
    if (!contactEmailGroupControl.valid) {
      this.alertService.warn(`L'email n'est pas valide`);
      return;
    }
    if (!contactOfficeGroupControl.valid) {
      this.alertService.warn(`La fonction n'est pas valide`);
      return;
    }
    if (!contactPhoneGroupControl.valid) {
      this.alertService.warn(`Le téléphone n'est pas valide (avez vous pensé au numéro international ?)`);
      return;
    }
    this.apiService.patchContact(id, {
      name: contactNameGroupControl.value,
      email: contactEmailGroupControl.value,
      office: contactOfficeGroupControl.value ? contactOfficeGroupControl.value : null,
      phone: contactPhoneGroupControl.value ? contactPhoneGroupControl.value : null
    }).subscribe(
      () => {
        this.refreshProperty();
      },
      () => {
        this.alertService.error('Une erreur est survenue lors de la sauvegarde du contact');
      }
    );
  }

  onDeleteContact(id: number): void {
    const relatedContact = this.data.property.syndicateContacts.find((contact) => {
      return contact.id === id;
    });
    const data: ValidationDialogData = {
      title: 'Supprimer le contact ?',
      text: `Êtes vous sûr de vouloir supprimer le contact ${relatedContact.name} ?`,
      onValidate: () => {
        this.apiService.deleteContact(id).subscribe(
          () => {
            this.alertService.success(`Le contact ${relatedContact.name} a bien été supprimée`, true);
            this.refreshProperty();
          }, () => {
            this.alertService.error(`Une erreur est survenue lors de la suppression du contact ${relatedContact.name}.`);
          }
        );
      },
      onDiscard: () => {}
    };
    this.deleteValidationDialog.open(ValidationDialogComponent, {
      data
    });
  }

  newTenant(): void {
    const data: CreateTenantDialogData = {
      propertyId: this.data.property.id
    };
    const dialogRef = this.createTenantDialog.open(CreateTenantDialogComponent, {
      width: '80%',
      data
    });
    dialogRef.afterClosed().subscribe(() => {
      this.refreshTenants();
      this.refreshProperty();
    });
  }

  refreshTenants(): Observable<any> {
    return this.apiService.getTenants(this.data.property.id)
      .pipe(map((tenants) => {
        this.tenants = tenants.sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
      }))
      .pipe(catchError((err) => {
        this.alertService.warn('Une erreur est survenue lors de la récupération des locataires');
        return throwError(err);
      }));
  }

  onSubmitTenantUpdate(id: number): void {
    const tenantNameGroupControl = this.tenantFormGroup.get(`nameTenantGroupId${id}`);
    const tenantEmailGroupControl = this.tenantFormGroup.get(`emailTenantGroupId${id}`);
    const tenantPhoneGroupControl = this.tenantFormGroup.get(`phoneTenantGroupId${id}`);
    tenantNameGroupControl.updateValueAndValidity();
    tenantEmailGroupControl.updateValueAndValidity();
    tenantPhoneGroupControl.updateValueAndValidity();
    if (!tenantNameGroupControl.valid) {
      this.alertService.warn(`Le nom est requis`);
      return;
    }
    if (!tenantEmailGroupControl.valid) {
      this.alertService.warn(`L'email n'est pas valide`);
      return;
    }
    if (!tenantPhoneGroupControl.valid) {
      this.alertService.warn(`Le téléphone n'est pas valide (avez vous pensé au numéro international ?)`);
      return;
    }
    this.apiService.patchTenant(id, {
      name: tenantNameGroupControl.value,
      email: tenantEmailGroupControl.value,
      phone: tenantPhoneGroupControl.value ? tenantPhoneGroupControl.value : null
    }).subscribe(
      () => {
        this.refreshProperty();
      },
      () => {
        this.alertService.error('Une erreur est survenue lors de la sauvegarde du locataire');
      }
    );
  }

  onDeleteTenant(id: number): void {
    const relatedTenant = this.data.property.tenants.find((tenant) => {
      return tenant.id === id;
    });
    const data: ValidationDialogData = {
      title: 'Supprimer le locataire ?',
      text: `Êtes vous sûr de vouloir supprimer le locataire ${relatedTenant.name} ?`,
      onValidate: () => {
        this.apiService.deleteTenant(id).subscribe(
          () => {
            this.alertService.success(`Le locataire ${relatedTenant.name} a bien été supprimée`, true);
            this.refreshProperty();
          }, () => {
            this.alertService.error(`Une erreur est survenue lors de la suppression du locataire ${relatedTenant.name}.`);
          }
        );
      },
      onDiscard: () => {}
    };
    this.deleteValidationDialog.open(ValidationDialogComponent, {
      data
    });
  }

  private refreshFormGroups(): void {
    this.refreshContactFormGroup();
    this.refreshTenantFormGroup();
    this.cdref.detectChanges();
  }

  private refreshContactFormGroup(): void {
    const contactGroups = {};
    for (const contact of this.data.property.syndicateContacts) {
      contactGroups[`nameContactGroupId${contact.id}`] = [contact.name, Validators.required];
      contactGroups[`emailContactGroupId${contact.id}`] = [contact.email, Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])];
      contactGroups[`officeContactGroupId${contact.id}`] = [contact.office];
      contactGroups[`phoneContactGroupId${contact.id}`] = [contact.phone, this.validatePhone()];
    }
    this.contactFormGroup = this.fb.group(contactGroups);
  }

  private refreshTenantFormGroup(): void {
    const tenantGroups = {};
    for (const tenant of this.data.property.tenants) {
      tenantGroups[`nameTenantGroupId${tenant.id}`] = [tenant.name, Validators.required];
      tenantGroups[`emailTenantGroupId${tenant.id}`] = [tenant.email, Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])];
      tenantGroups[`phoneTenantGroupId${tenant.id}`] = [tenant.phone, this.validatePhone()];
    }
    this.tenantFormGroup = this.fb.group(tenantGroups);
  }
}
