import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PropertiesComponent} from './pages/properties/properties.component';
import {AuthGuard} from '../../core/guards/auth.guard';
import {PropertyComponent} from './pages/property/property.component';
import {CreatePropertyPageComponent} from './pages/create-property-page/create-property-page.component';

const routes: Routes = [
  {
    path: 'properties',
    component: PropertiesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'properties/create',
    component: CreatePropertyPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'properties/:id',
    component: PropertyComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PropertiesRoutingModule { }
