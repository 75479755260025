<div class="container pa-15">
  <div class="row">
    <div class="col-12">
      <h1 class="mat-h1">Mes fiches</h1>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="horizontal-spacer"></div>
  <div class="row">
    <div class="col-12">
      <app-properties-list></app-properties-list>
    </div>
  </div>
</div>
