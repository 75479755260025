import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ApiService} from '../../../../core/services/api/api.service';
import {AlertService} from '../../../../core/services/alert/alert.service';
import {GenericUser} from '../../../../shared/models/api/users/generic-user';
import {PatchUserPayload} from '../../../../shared/models/api/users/patch-user-payload';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

@Component({
  selector: 'app-profile-infos',
  templateUrl: './profile-infos.component.html',
  styleUrls: ['./profile-infos.component.scss']
})
export class ProfileInfosComponent implements OnInit {
  profileInfos: FormGroup;
  user: GenericUser;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private alertService: AlertService,
  ) {
    this.profileInfos = fb.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      phone: ['', this.validatePhone()],
    });
  }

  ngOnInit(): void {
    this.apiService.getUserMe().subscribe((user) => {
      this.user = user;
      this.profileInfos.setValue({
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        phone: user.phone
      });
    });
  }

  validatePhone(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      if (!input) {
        return null;
      }
      const phoneNumber = parsePhoneNumberFromString(input);
      return (!phoneNumber || !phoneNumber.isValid()) ? {phone: {phone: false}} : null;
    };
  }

  onSubmitProfileInfos(): void {
    const phoneField = this.profileInfos.get('phone').value;
    const phone = phoneField && phoneField.length ? phoneField : null;
    const payload: PatchUserPayload = {
      email: this.profileInfos.get('email').value,
      firstname: this.profileInfos.get('firstname').value,
      lastname: this.profileInfos.get('lastname').value,
      phone
    };

    this.apiService.patchUser(this.user.id, payload).subscribe(
      (patchedUser) => {
        this.alertService.success('Le profil à bien été mis à jour');
      }, (error) => {
        this.alertService.error(`Erreur lors de la mise à jour du profil.`);
      }
    );
  }
}
