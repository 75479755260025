<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="d-flex justify-content-between">
    <h1 class="mat-h1" mat-dialog-title>Créer une nouvelle agence</h1>
    <button mat-icon-button type="button" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
  </div>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-4">
        <mat-form-field class="form-field full-width">
          <mat-label>Image</mat-label>
          <ngx-mat-file-input #removableInput id="image" formControlName="image" placeholder="Image de l'agence" [required]="true" [accept]="'.png,.jpg,.jpeg'" (change)="onFileChange($event.target.files)"></ngx-mat-file-input>
          <button mat-icon-button matSuffix *ngIf="!removableInput.empty" (click)="removableInput.clear($event)">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-hint>Une image de l'agence</mat-hint>
          <mat-icon matSuffix>folder</mat-icon>
          <mat-error *ngIf="formGroup.controls.image.errors && formGroup.controls.image.errors.required">
            L'image de l'agence est requise
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-8">
        <mat-form-field class="form-field full-width">
          <mat-label>Nom de l'agence</mat-label>
          <input matInput placeholder="nom de l'agence"
                 type="text"
                 id="name"
                 formControlName="name"
                 [required]="true"
          />
          <mat-error *ngIf="formGroup.controls.name.errors && formGroup.controls.name.errors.required">
            Le nom est requis
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="horizontal-spacer"></div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="form-field full-width">
          <mat-label>Description de l'agence</mat-label>
          <input matInput placeholder="description de l'agence"
                 type="text"
                 id="description"
                 formControlName="description"
                 [required]="true"
          />
          <mat-error *ngIf="formGroup.controls.description.errors && formGroup.controls.description.errors.required">
            La description est requise
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="horizontal-spacer"></div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="form-field full-width">
          <mat-label>Prénom</mat-label>
          <input matInput placeholder="prénom"
                 type="text"
                 id="firstname"
                 formControlName="firstname"
                 [required]="true"
          />
          <mat-hint>Prénom du directeur d'agence</mat-hint>
          <mat-error *ngIf="formGroup.controls.firstname.errors && formGroup.controls.firstname.errors.required">
            Le prénom est requis
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="form-field full-width">
          <mat-label>Nom de famille</mat-label>
          <input matInput placeholder="nom"
                 type="text"
                 id="lastname"
                 formControlName="lastname"
                 [required]="true"
          />
          <mat-hint>Nom de famille du directeur d'agence</mat-hint>
          <mat-error *ngIf="formGroup.controls.lastname.errors && formGroup.controls.lastname.errors.required">
            Le nom est requis
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="horizontal-spacer"></div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="form-field full-width">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Mon adresse email..."
                 type="text"
                 id="email"
                 formControlName="email"
                 [required]="true"
          />
          <mat-hint>Adresse email du directeur d'agence</mat-hint>
          <mat-error *ngIf="formGroup.controls.email.errors && formGroup.controls.email.errors.required">
            L'adresse email est requise
          </mat-error>
          <mat-error *ngIf="formGroup.controls.email.errors && formGroup.controls.email.errors.pattern">
            L'adresse email indiqué n'est pas valide
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="form-field full-width">
          <mat-label>Téléphone</mat-label>
          <input matInput placeholder="+33 123456789"
                 type="text"
                 id="phone"
                 formControlName="phone"
                 [required]="false"
          />
          <mat-hint>Numéro de téléphone du directeur d'agence</mat-hint>
          <mat-error *ngIf="formGroup.controls.phone.errors && formGroup.controls.phone.errors.phone">
            Le numéro de téléphone n'est pas valide (avez vous bien indiqué le numéro international ?)
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="!formGroup.valid" type="submit" class="full-width">Créer</button>
  </div>
</form>
