import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContactsDisplayDialogData} from '../../models/contacts/contacts-display-dialog-data';

interface TableRow {
  name: string;
  email: string;
  office: string;
  phone: string;
}

@Component({
  selector: 'app-contacts-display-dialog',
  templateUrl: './contacts-display-dialog.component.html',
  styleUrls: ['./contacts-display-dialog.component.scss']
})
export class ContactsDisplayDialogComponent implements OnInit {
  displayedColumns: string[] = ['name', 'email', 'office', 'phone'];
  dataSource: MatTableDataSource<TableRow>;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<ContactsDisplayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ContactsDisplayDialogData
  ) { }

  ngOnInit(): void {
    this.refreshDataSource();
  }

  refreshDataSource(): void {
    this.dataSource = new MatTableDataSource(this.data.contacts.map((contact) => {
      return {
        name: contact.name,
        email: contact.email,
        office: contact.office,
        phone: contact.phone
      };
    }));
    this.dataSource.sort = this.sort;
  }
}
